/* eslint-disable no-constant-condition */
import { IMixpanelEventTrack, ITrackerInfo } from '../../core/interfaces/ITracker';
import mixpanel from 'mixpanel-browser';
import { T_WA_GS_SIGN_IN, T_WA_GS_SIGN_UP } from '../../core/const/tracker';

/**
 * app level tracker class for mixpanel
 */
class Tracker {
  private pTrackerInfo: ITrackerInfo = {
    userId: '',
    userName: '',
    userEmail: '',
    organizationId: '',
    organizationName: '',
    planId: '',
    planName: '',
    appVersion: '',
    organizationSlug: '',
    platform: {
      os: '',
      version: '',
      isMobile: window.isMobile,
    },
    planStatus: '',
    cardStatus: '',
    userEmailVerified: false,
    userRole: '',
    phoneVerified: false,
  };

  private queuedMixpanelEvents: IMixpanelEventTrack[] = [];

  private recentlyTrackedEvents: Record<string, Date> = {};

  private checkedFirstSignIn = false;

  set trackerInfo(info: Partial<ITrackerInfo>) {
    this.pTrackerInfo = {
      ...this.pTrackerInfo,
      ...info,
    };

    const userEmailSet: boolean = this.pTrackerInfo.userEmail.length > 0;
    const userOrgSlugSet: boolean = this.pTrackerInfo.organizationSlug.length > 0;

    if (userEmailSet && userOrgSlugSet) {
      this.trackLogin();

      this.trackQueuedEvents();
    }
  }

  private phone = '';
  set contact(contact: any) {
    this.phone = `${contact.dialCode} ${contact.phoneNumber}`;
  }

  private trackQueuedEvents() {
    this.queuedMixpanelEvents.forEach(event => {
      this.track(event.name, event.properties ?? {});
    });

    this.queuedMixpanelEvents = [];
  }

  private queueUntrackedEvent(eventName: string, properties: Record<string, any> = {}) {
    const existingEvent = this.queuedMixpanelEvents.find(eventData => eventName === eventData.name);

    if (!existingEvent) {
      this.queuedMixpanelEvents.push({ name: eventName, properties });
    }
  }

  private checkAndTrackFirstSignIn() {
    if (!this.checkedFirstSignIn) {
      this.checkedFirstSignIn = true;
      /**
       * Checking if userflow is open, to see if this is the first time the user is signing in.
       */
      let isUserflowTriggered = false;

      const checkUserFlowInterval = setInterval(() => {
        isUserflowTriggered = document.getElementById('userflow-ui') === null ? false : true;

        if (isUserflowTriggered) clearInterval(checkUserFlowInterval);
      }, 50);

      setTimeout(() => {
        const firstTimeSignIn: boolean = isUserflowTriggered;
        const userEmailSet: boolean = this.pTrackerInfo.userEmail.length > 0;
        const userOrgSlugSet: boolean = this.pTrackerInfo.organizationSlug.length > 0;

        if (firstTimeSignIn) {
          if (userEmailSet && userOrgSlugSet) {
            mixpanel.track(T_WA_GS_SIGN_IN);
          } else {
            this.queueUntrackedEvent(T_WA_GS_SIGN_IN);
          }
          clearInterval(checkUserFlowInterval);
        } else {
          clearInterval(checkUserFlowInterval);
        }
      }, 5000);
    }
  }

  /**
   * Track an event in Mixpanel.
   *
   * @param eventName name of the event
   * @param additionalInfo additional properties to track with the event
   * @param debug if true, logs the event to the console instead of tracking it. Works only in development environment.
   */
  public track(eventName: string, additionalInfo: any = {}, debug = false) {
    if (process.env.NODE_ENV === 'development') {
      if (debug) {
        console.log(`Mixpanel Event: "${eventName}"\nAdditional Properties:`, additionalInfo);
      }
      return;
    }

    const userEmailSet: boolean = this.pTrackerInfo.userEmail.length > 0;
    const userOrgSlugSet: boolean = this.pTrackerInfo.organizationSlug.length > 0;

    const isSignInEvent: boolean = eventName === T_WA_GS_SIGN_IN;

    if (userEmailSet && userOrgSlugSet) {
      const thisEventRecentlyTrackedTime = this.recentlyTrackedEvents[eventName];

      if (thisEventRecentlyTrackedTime) {
        const now = new Date();

        if (isSignInEvent && now.getTime() - thisEventRecentlyTrackedTime.getTime() <= 5000) {
          return;
        } else if (now.getTime() - thisEventRecentlyTrackedTime.getTime() <= 1000) {
          return;
        }
      }

      this.trackLogin();

      mixpanel.track(eventName, {
        ...additionalInfo,
      });

      this.recentlyTrackedEvents[eventName] = new Date();
    } else {
      if (isSignInEvent) {
        this.trackLogin();
      }
      this.queueUntrackedEvent(eventName, {
        ...additionalInfo,
      });
    }
  }

  public people(additionalInfo: any = {}) {
    if (process.env.NODE_ENV === 'development') {
      return;
    }

    mixpanel.people.set({ ...additionalInfo });
  }

  public trackSignUp(additionalInfo: any = {}) {
    if (process.env.NODE_ENV === 'development') {
      return;
    }

    mixpanel.alias(additionalInfo.email);
    this.track(T_WA_GS_SIGN_UP);
    this.people({
      'Sign Up Time': new Date().toISOString(),
    });
  }

  public trackLogin() {
    if (process.env.NODE_ENV === 'development') {
      return;
    }

    mixpanel.identify(this.pTrackerInfo.userEmail);
    this.people({
      $name: this.pTrackerInfo.userName,
      $email: this.pTrackerInfo.userEmail,
      $phone: this.phone,
      'Last Sign In Time': new Date().toISOString(),
      'Email Verified': this.pTrackerInfo.userEmailVerified,
    });
    mixpanel.register({
      Email: this.pTrackerInfo.userEmail,
      Role: this.pTrackerInfo.userRole,
      'Org Name': this.pTrackerInfo.organizationName,
      'Org Slug': this.pTrackerInfo.organizationSlug,
      'Plan Name': this.pTrackerInfo.planName,
      'Plan Status': this.pTrackerInfo.planStatus,
      'Plan Id': this.pTrackerInfo.planId,
      'OS Version': this.pTrackerInfo.platform.version,
      Mobile: this.pTrackerInfo.platform.isMobile,
      'Phone Number Verified': this.pTrackerInfo.phoneVerified,
    });
    mixpanel.get_group('organisation', this.pTrackerInfo.organizationId).set({
      'Org Name': this.pTrackerInfo.organizationName,
      'Org Slug': this.pTrackerInfo.organizationSlug,
      'Plan Id': this.pTrackerInfo.planId,
      'Plan Name': this.pTrackerInfo.planName,
      'Plan Status': this.pTrackerInfo.planStatus,
      $name: this.pTrackerInfo.organizationName,
    });
    mixpanel.set_group('organisation', this.pTrackerInfo.organizationId);

    this.checkAndTrackFirstSignIn();
  }

  /**
   * To be called on logout.
   */
  public reset() {
    this.queuedMixpanelEvents = [];
    this.checkedFirstSignIn = false;
    if (process.env.NODE_ENV === 'development') {
      return;
    }
    mixpanel.reset();
  }

  public init(token: string, proxyUrl?: string) {
    if (process.env.NODE_ENV === 'development') {
      return;
    }
    if (proxyUrl?.length) {
      mixpanel.init(token, { api_host: proxyUrl });
    } else {
      mixpanel.init(token);
    }
  }
}

const appTracker = new Tracker();
export { appTracker as AppTracker };
