import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { THEME_COLORS } from 'library/theme/colors';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const NoSuggestedRunbooksModal = (props: IProps) => {
  return (
    <Modal isOpen={props.isOpen} onClose={() => props.onClose()} isCentered>
      <ModalOverlay />
      {/**eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore */}
      <ModalContent width="448px">
        <ModalHeader>
          <Text fontWeight={800} fontSize="16px" color={THEME_COLORS.secondary[950]}>
            It looks like we don't have a runbook to suggest
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontWeight={400} fontSize="14.5px" color={THEME_COLORS.secondary[700]}>
            Our suggestions improve as you attach more runbooks to incidents over time.
          </Text>
          <Text fontWeight={400} fontSize="14.5px" color={THEME_COLORS.secondary[700]}>
            The more you use and manage runbooks, the smarter our recommendations will become!
          </Text>
          <Box width="100%" h="16px"></Box>
          <Text fontWeight={400} fontSize="14.5px" color={THEME_COLORS.secondary[700]}>
            Would you like to attach a runbook instead?
          </Text>
        </ModalBody>
        <ModalFooter justifyContent="end">
          <Button
            variant="outline"
            color={THEME_COLORS.brand.blue}
            borderColor={THEME_COLORS.primary[500]}
            borderRadius="3px"
            mr={3}
            onClick={() => props.onClose()}
          >
            Cancel
          </Button>
          <Button
            bg={THEME_COLORS.brand.blue}
            onClick={() => props.onConfirm()}
            borderRadius="3px"
            height="32px"
          >
            Attach Runbook
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NoSuggestedRunbooksModal;
