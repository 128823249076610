import { API } from 'core';
import { UseQueryOptions } from 'react-query';
import { DEFAULT_REACT_QUERY_CONFIG } from 'views/main/organization/incident-list/common/constants';
import {
  GetIncidentAssignableEntitiesQuery,
  useGetIncidentAssignableEntitiesQuery,
} from 'views/main/organization/incident-list/graphql/query';
import { useGetAssignableAndSuggestedResponders } from 'views/main/organization/incidentDetails/renders/responders/hooks/useGetAssignableAndSuggestedResponders';
import { IGetAssignableAndSuggestedRespondersOptions } from 'views/main/organization/incidentDetails/renders/responders/types';

export type getIncidentAssignableEntitiesSearchQueryMode = 'default' | 'suggest-responders';
type getIncidentAssignableEntitiesSearchQueryOptions = IGetAssignableAndSuggestedRespondersOptions;

export type getIncidentAssignableEntitiesSearchQueryDataKey =
  | 'getIncidentAssignableEntities'
  | 'data';
export type getIncidentAssignableEntitiesSearchQueryArgs =
  | [
      {
        teamID: string;
      },
      UseQueryOptions<GetIncidentAssignableEntitiesQuery>,
    ]
  | [getIncidentAssignableEntitiesSearchQueryOptions];

type QueryConfig = {
  useSearchQuery:
    | typeof useGetIncidentAssignableEntitiesQuery
    | typeof useGetAssignableAndSuggestedResponders;
  dataKey: string;
  getQueryArgs: (requiredOptions?: getIncidentAssignableEntitiesSearchQueryOptions) => any[];
};

/**
 * Future query configs can be added here
 */
const queryConfigs: Record<getIncidentAssignableEntitiesSearchQueryMode, QueryConfig> = {
  default: {
    useSearchQuery: useGetIncidentAssignableEntitiesQuery,
    dataKey: 'getIncidentAssignableEntities',
    getQueryArgs: () => [
      { teamID: API.config.teamId },
      { ...DEFAULT_REACT_QUERY_CONFIG, cacheTime: 0 },
    ],
  },
  'suggest-responders': {
    useSearchQuery: useGetAssignableAndSuggestedResponders,
    dataKey: 'data',
    getQueryArgs: requiredOptions => {
      if (!requiredOptions) {
        console.error('Options are required for suggest-responders mode');
        return [];
      }
      return [requiredOptions];
    },
  },
};

/**
 * Retrieves the query configuration for the specified mode.
 * @param mode - The query mode ('default' or 'suggest-responders').
 * @param requiredOptions - Options required for specific modes.
 */
export const getIncidentAssignableEntitiesSearchQuery = (
  mode: getIncidentAssignableEntitiesSearchQueryMode = 'default',
  requiredOptions?: getIncidentAssignableEntitiesSearchQueryOptions,
) => {
  const config = queryConfigs[mode];
  return {
    useSearchQuery: config.useSearchQuery,
    useSearchQueryArgs: config.getQueryArgs(requiredOptions),
    dataKey: config.dataKey,
  };
};
