import { AppFrame, Grid, Para } from 'uie/components';
import { PaginationState } from '@tanstack/react-table';
import { useSecondaryFilters } from 'components/SecondaryFilter/context';
import { getTimeFromObjectId } from 'core/helpers';
import { Locale } from 'core/helpers/dateUtils';
import { IPostmortemList } from 'core/interfaces/IPostmortems';
import { formatRFC3339, sub } from 'date-fns';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { listPostmortemsForTimeRange } from './api/postmortems.api';
import { Failure } from './behaviors/Failure';
import { Session, useSession } from './behaviors/Session';
import { useListing } from './behaviors/useListing';
import { useSearchParam } from 'core/hooks/useSearchParam';
import Filters from './filters';
import { Message } from './Message';
import Table from './table/table';

type Tuple = [IPostmortemList[], number];

type PostmortemFilters = {
  alert_sources?: string[];
  services?: string[];
  postmortem_status?: string[];
  timeRange: { to: Date; from: Date };
};

async function fetchPostmortems(
  session: Session,
  pagination: PaginationState,
  filters: PostmortemFilters,
): Promise<Tuple> {
  const response = await listPostmortemsForTimeRange({
    fromDate: Locale.toISO(formatRFC3339(filters.timeRange.from)),
    toDate: Locale.toISO(formatRFC3339(filters.timeRange.to)),
    alertSources: filters.alert_sources!,
    services: filters.services!,
    status: filters.postmortem_status!,
    skip: pagination.pageIndex * pagination.pageSize,
    limit: pagination.pageSize,
    teamId: session.teamId,
  });
  const data = response[0];
  if (!data.total_count[0]) {
    return [[], 0];
  }
  return [data.result, data.total_count[0].count];
}

const PostmortemTable = ({
  query,
  pagination,
  setPagination,
  setIncidentId,
}: {
  query: UseQueryResult<Tuple, Failure>;
  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  setIncidentId: (incidentId: string) => void;
}) => {
  if (query.isLoading || query.isIdle) {
    return <Message message="Loading Postmortems..." />;
  } else if (query.isError) {
    return (
      <Message message="An error occured while loading Postmortems. Please refresh the page or try again later." />
    );
  } else {
    const [data, count] = query.data;

    if (count === 0) {
      return <Message message="No more Postmortems to load." />;
    } else {
      return (
        <Table
          data={data}
          totalCount={count}
          onPostmortemClick={setIncidentId}
          pagination={pagination}
          setPagination={setPagination}
        />
      );
    }
  }
};

export const PostmortemsListing: React.FC = () => {
  const { session } = useSession();
  const [_, setIncidentId] = useSearchParam('incident-id', 'replace');
  const [timeRange, setTimeRange] = useState({
    to: new Date(),
    from: sub(new Date(), { months: 3 }),
  });
  const secondaryFilters = useSecondaryFilters();

  const { query, pagination, setPagination, setFilters } = useListing({
    baseQueryKey: ['postmortems', session.teamId],
    queryFn: fetchPostmortems,
    countQueryFn: async () => 0,
    initialPageSize: 5,
    initialFilters: { ...secondaryFilters.filters, timeRange } as PostmortemFilters,
  });

  useEffect(() => {
    if (Object.keys(secondaryFilters.filters).length !== 0) {
      setFilters({ ...secondaryFilters.filters, timeRange });
    }
  }, [secondaryFilters.filters, timeRange]);

  return (
    <AppFrame>
      <div style={{ height: '100vh' }}>
        <Grid
          type="column"
          alignItems="flex-start"
          className="incidentList-incidentCount"
          style={{
            padding: '8px 0px 20px 18px',
          }}
        >
          <Filters
            minDate={getTimeFromObjectId(session.orgId)}
            fromDate={timeRange.from}
            toDate={timeRange.to}
            onUpdateTimeChange={(from, to) => setTimeRange({ from, to })}
          />
          {query.isSuccess && <Para fontSize={24}>{query.data[1]} Postmortems</Para>}
        </Grid>
        <Grid>
          <PostmortemTable
            query={query}
            pagination={pagination}
            setPagination={setPagination}
            setIncidentId={setIncidentId}
          />
        </Grid>
      </div>
    </AppFrame>
  );
};
