import { MouseEventHandler } from 'react';
import './Accordin.css';

import { ClipboardIcon, CollapsedArrowIcon, ExpandedArrowIcon } from 'icons';

interface IProps {
  title: string;
  collapse: boolean;
  withoutIcon?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

const RunbookAccordinBody = ({ title, collapse, withoutIcon = false, onClick }: IProps) => {
  return (
    <div
      className="container"
      style={withoutIcon ? { position: 'relative', bottom: '2px' } : {}}
      onClick={onClick ? onClick : undefined}
    >
      {withoutIcon ? '' : <ClipboardIcon className="mr-5" />}
      {title}
      {collapse ? <ExpandedArrowIcon className="ml-5" /> : <CollapsedArrowIcon className="ml-5" />}
    </div>
  );
};

export { RunbookAccordinBody };
