import { Box, Text, VStack } from '@chakra-ui/react';
import { Input } from 'library/atoms';
import { AssigneeType } from 'library/enums';
import { cloneDeep } from 'lodash';
import { forwardRef, memo, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { EMPTY_STRING } from 'views/main/organization/incident-list/common/constants';
import { IncidentAssignToType } from 'views/main/organization/incident-list/graphql/generated/types';
import { GetIncidentAssignableEntitiesQuery } from 'views/main/organization/incident-list/graphql/query';
import { IGetAssignableAndSuggestedRespondersApiResponse } from 'views/main/organization/incidentDetails/renders/responders/types';

import { Loader } from '../Loader';
import { suggestedTooltipText } from './constants';
import { Entity } from './Entity';
import { getIncidentAssignableEntitiesSearchQuery } from './getSearchQuery';
import {
  AssignableEntityType,
  AssignableEntityTypeWithSuggestion,
  IncidentAssignableSearchProps,
} from './types';

export const IncidentAssignableSearch = memo(
  forwardRef((props: IncidentAssignableSearchProps, ref) => {
    const { assignees, onSelectionUpdate } = props;
    const [selectedEntities, setSelectedEntities] = useState<
      Array<{
        name: string;
        type: IncidentAssignToType;
        id: string;
      }>
    >([]);
    const [searchTerm, setSearchTerm] = useState(EMPTY_STRING);
    const [filteredData, setFilteredData] = useState<
      | GetIncidentAssignableEntitiesQuery['getIncidentAssignableEntities']
      | IGetAssignableAndSuggestedRespondersApiResponse['data']
    >();
    const disallowedType = useMemo(() => props.disallowType ?? [], [props.disallowType]);

    const requiredOptions =
      props.queryMode === 'suggest-responders'
        ? {
            incidentId: props.incidentId || '',
          }
        : undefined;
    const { useSearchQuery, useSearchQueryArgs, ...searchQuery } =
      getIncidentAssignableEntitiesSearchQuery(props.queryMode, requiredOptions);

    const { isLoading, data } = useSearchQuery(...(useSearchQueryArgs as [any, any]));

    const entitiesData = data?.[searchQuery.dataKey as keyof typeof data] as unknown as
      | GetIncidentAssignableEntitiesQuery['getIncidentAssignableEntities']
      | IGetAssignableAndSuggestedRespondersApiResponse['data']
      | undefined;

    const resetFilterData = () => {
      setFilteredData(entitiesData);
    };

    useImperativeHandle(ref, () => ({
      clearSelection() {
        setSelectedEntities([]);
      },
    }));

    useEffect(() => {
      resetFilterData();
    }, [data]);

    const getEntity = (id: string) => {
      let type = IncidentAssignToType.User;
      let entity = (entitiesData?.users as AssignableEntityType[])?.find(user => user.ID === id);
      let entityName = entity?.name;
      let suggested: boolean | undefined =
        entity && 'suggested' in entity && (entity?.suggested as boolean);

      if (!entityName) {
        entity = (entitiesData?.squads as AssignableEntityType[])?.find(squad => squad.ID === id);
        entityName = entity?.name;
        type = IncidentAssignToType.Squad;
        suggested = entity && 'suggested' in entity && (entity?.suggested as boolean);
      }

      if (!entityName && entitiesData && 'escalationPolicies' in entitiesData) {
        entity = (entitiesData.escalationPolicies as AssignableEntityType[])?.find(
          ep => ep.ID === id,
        );
        entityName = entity?.name;
        type = IncidentAssignToType.Escalationpolicy;
        suggested = entity && 'suggested' in entity && (entity?.suggested as boolean);
      }

      return {
        name: entityName ?? '',
        type,
        id,
        suggested,
      };
    };

    const onSelectionChange = (e: any) => {
      const assignee = getEntity(e.target.value) as {
        name: string;
        type: IncidentAssignToType;
        id: string;
      };
      let assigneeList = [assignee];

      if (props.allowMulti) {
        assigneeList = selectedEntities.some(entity => entity.id === assignee.id)
          ? selectedEntities.filter(entity => entity.id !== assignee.id)
          : [...selectedEntities, assignee];
      }
      setSelectedEntities(assigneeList);
      onSelectionUpdate(assigneeList);
    };

    const filterEntity = (array: AssignableEntityType[], searchText: string) => {
      return array.filter(
        item =>
          !assignees.some(assignee => assignee.id === item.ID) &&
          item.name.toLowerCase().includes(searchText),
      );
    };

    const filterData = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(e.target.value);
      if (e.target.value && entitiesData) {
        const entities = cloneDeep(entitiesData);
        const searchText = e.target.value.trim().toLowerCase();

        entities.users = filterEntity(entities.users, searchText);
        entities.squads = filterEntity(entities.squads, searchText);
        if ('escalationPolicies' in entities) {
          entities.escalationPolicies = filterEntity(entities.escalationPolicies, searchText);
        }

        setFilteredData(entities);
      } else {
        resetFilterData();
      }
    };

    const EntityUnit = (
      type: AssigneeType,
      title: string,
      array: Array<AssignableEntityType | AssignableEntityTypeWithSuggestion> = [],
    ) => {
      return (
        <>
          {!!array.length && (
            <>
              <Box
                mt="0px !important"
                bgColor="secondary.150"
                width="100%"
                px={4}
                py={3}
                borderBottomWidth="1px"
                borderColor="secondary.200"
              >
                <Text variant="hint_800" color="secondary.700">
                  {title}
                </Text>
              </Box>
              {array.map(item => {
                const { ID: id, name, usernameForDisplay, ...rest } = item;
                return (
                  <>
                    {assignees.some(assignee => assignee.id === id && assignee.type === type) ? (
                      <></>
                    ) : (
                      <Entity
                        {...rest}
                        key={id}
                        isSelected={selectedEntities.some(entity => entity.id === id)}
                        name={name}
                        username={usernameForDisplay}
                        type={type}
                        id={id}
                        onChange={onSelectionChange}
                        allowMulti={props.allowMulti}
                        suggested={'suggested' in item && item.suggested}
                        suggestedTooltip={
                          props.queryMode ? suggestedTooltipText[props.queryMode] : undefined
                        }
                      />
                    )}
                  </>
                );
              })}
            </>
          )}
        </>
      );
    };

    return (
      <Box p={4}>
        {/** eslint-disable-next-line @typescript-eslint/ban-ts-comment
        @ts-ignore */}
        <VStack
          p={4}
          borderBottomWidth="1px"
          borderBottomColor="primary.500"
          alignItems="flex-start"
          spacing={2}
          bg="primary.100"
        >
          {!props.hideTitle && (
            <Text variant="formInput_800" color="secondary.700">
              Reassign To
            </Text>
          )}
          <Input
            isDisabled={isLoading}
            value={searchTerm}
            onChange={filterData}
            placeholder={
              props.searchPlaceholder || 'Type an escalation policy, squad or user to reassign'
            }
          ></Input>
        </VStack>
        {isLoading ? (
          <Loader />
        ) : (
          <VStack alignItems="flex-start" mt={3}>
            {!disallowedType.includes(AssigneeType.User) &&
              EntityUnit(AssigneeType.User, 'USERS', filteredData?.users)}
            {!disallowedType.includes(AssigneeType.Squad) &&
              EntityUnit(AssigneeType.Squad, 'SQUADS', filteredData?.squads)}
            {!disallowedType.includes(AssigneeType.Escalationpolicy) &&
              filteredData &&
              'escalationPolicies' in filteredData &&
              EntityUnit(
                AssigneeType.Escalationpolicy,
                'ESCALATION POLICY',
                filteredData?.escalationPolicies,
              )}
          </VStack>
        )}
      </Box>
    );
  }),
);
