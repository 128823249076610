import { getIncidentAssignableEntitiesSearchQueryMode } from './getSearchQuery';

export const USERNAME_MAX_LENGTH = 50;

export const SUGGESTED_ADDITIONAL_RESPONDERS_TOOLTIP_TEXT =
  'We suggest additional responders based on data from similar incidents from the past.';

export const suggestedTooltipText: Record<
  getIncidentAssignableEntitiesSearchQueryMode,
  string | undefined
> = {
  default: undefined,
  'suggest-responders': SUGGESTED_ADDITIONAL_RESPONDERS_TOOLTIP_TEXT,
};
