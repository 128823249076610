import { AppConfig } from 'shared/app.config';
import { IAPIConfig } from '../interfaces/IAPIConfig';

/**
 * default development Endpoints
 * this is the default configs used
 * @readonly
 */
export const defaultDevConfig = (host: 'localhost' | string = 'localhost'): IAPIConfig => ({
  api: {
    endPoint: `http://${host}:8080`,
    euEndPoint: `http://${host}:8080`,
    version: 'v2',
  },
  batman: {
    endPoint: `http://${host}:8081`,
    euEndPoint: `http://${host}:8081`,
    version: 'v3',
  },
  ingester: {
    endPoint: `http://${host}:8458`,
    euEndPoint: `http://${host}:8458`,
    version: 'v2',
  },
  internalApi: {
    endPoint: `http://${host}:8080`,
    euEndPoint: `http://${host}:8080`,
    version: 'v2',
  },
  sqerberos: {
    endPoint: `http://${host}:8082`,
    euEndPoint: `http://${host}:8082`,
  },
  frontend: `http://${host}`,
  dexApi: `http://${host}:7890/v1`,
  billingPortal: AppConfig.billing_url,
  squadcastSupport: 'support@squadcast.com',
  squadcastWebsite: 'https://www.squadcast.com',
  deusApi: `http://${host}:9006/v1`,
  deusApiSocket: `ws://${host}:9006/v1`,
  integration: {
    slack: {
      id: AppConfig.slack,
      redirects: `http://${host}`,
    },
    slackv2: {
      id: AppConfig.slackv2,
      redirects: `http://${host}`,
    },
    msteams: {
      id: AppConfig.ms_teams,
      redirects: `https://support.squadcast.com/docs/msteams#adding-ms-teams-channels`,
    },
  },
  tld: 'com',
});

/**
 * Default Config Endpoints
 * @see src/index.tsx for env configs
 * {@link https://squadcast.atlassian.net/wiki/spaces/ID/pages/102334465/FE+Secret+Keys Documentation for managing env configs}
 * @readonly
 */
export const defaultConfig: IAPIConfig = {
  api: {
    endPoint: AppConfig.platform_backend_url,
    euEndPoint: AppConfig.EU_platform_backend_url,
    version: 'v2',
  },
  batman: {
    endPoint: AppConfig.api_url,
    euEndPoint: AppConfig.EU_api_url,
    version: 'v3',
  },
  ingester: {
    endPoint: AppConfig.api_url,
    euEndPoint: AppConfig.EU_api_url,
    version: 'v2',
  },
  internalApi: {
    endPoint: AppConfig.internal_api_url,
    euEndPoint: AppConfig.EU_internal_api_url,
    version: 'v2',
  },
  sqerberos: {
    endPoint: AppConfig.sso_auth_url,
    euEndPoint: AppConfig.EU_sso_auth_url,
  },
  dexApi: 'https://dex.squadcast.com/v1',
  deusApi: 'https://deus.squadcast.com/v1',
  frontend: AppConfig.app_url,
  billingPortal: AppConfig.billing_url,
  squadcastSupport: 'support@squadcast.com',
  squadcastWebsite: 'https://www.squadcast.com',
  deusApiSocket: 'wss://deus.squadcast.com/v1',
  oneSignalKey: AppConfig.onesignal,
  integration: {
    slack: {
      id: AppConfig.slack,
      redirects: AppConfig.app_url,
    },
    slackv2: {
      id: AppConfig.slackv2,
      redirects: AppConfig.app_url,
    },
    msteams: {
      id: '',
      redirects: `https://support.squadcast.com/docs/msteams#adding-ms-teams-channels`,
    },
  },
  tld: AppConfig.app_url.split('.').pop() || 'com',
};
