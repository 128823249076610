import { DialogModalFrame, Grid, Para, SnackBar, SnackContext, Theme } from 'uie/components';
import axios from 'axios';
import UnsavedChangesGuard from 'components/unsavedChangesGuard';
import { JiraCloudServiceNew } from 'core/services/services.jiracloud';
import md5 from 'md5';
import queryString from 'query-string';
import * as React from 'react';
import { useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import ConfirmModal from '../../../../../components/confirm.modal';
import { API } from '../../../../../core/api';
import {
  TA_WA_UP_SNW_EXTENSION_REVOKED,
  T_WA_GS_CIRCLECI_INTEGRATED,
  T_WA_GS_CIRCLECI_REVOKED,
  T_WA_GS_EXTENSIONS_PAGE_VIEWED,
  T_WA_GS_GOOGLE_HANGOUTS_INTEGRATION_ATTEMPTED,
  T_WA_GS_JIRA_CLOUD_INTEGRATED,
  T_WA_GS_JIRA_CLOUD_REVOKED,
  T_WA_GS_JIRA_SERVER_INTEGRATED,
  T_WA_GS_JIRA_SERVER_REVOKED,
  T_WA_GS_MICROSOFT_TEAMS_INTEGRATION_ATTEMPTED,
  T_WA_GS_SLACK_INTEGRATED,
  T_WA_GS_SLACK_REVOKED,
} from '../../../../../core/const/tracker';
import { exception } from '../../../../../core/exception';
import { IAppExceptions } from '../../../../../core/interfaces/IAppExceptions';
import { IAppState } from '../../../../../core/interfaces/IAppState';
import { IComponentErrorState } from '../../../../../core/interfaces/IComponentState';
import {
  IExtensionMSTeams,
  IExtensionStatus,
  ISlackChannel,
  ISlackChannelResponse,
} from '../../../../../core/interfaces/IExtensions';
import {
  ExtensionService,
  JiraCloudService,
  JiraServerService,
  SSOService,
} from '../../../../../core/services';
import {
  CrownIcon,
  DotIcon,
  IncompleteRibbon,
  IntegratedRibbon,
  SlackHash,
  SSORibbon,
} from '../../../../../icons';
import { AppTracker } from '../../../../../shared/analytics/tracker';
import CircleciIntegrationModal from './circleCi.modal';
import './index.css';
import JiraCloudModal from './jira.modal/cloud';
import JiraServerModal from './jira.modal/server';
import MSTeams from './msteams';
import Slack from './slack';
import SlackV2 from './slack-v2';
import SSOModal from './sso.modal';
import ServiceNow from './servicenow';
import { ServiceNowSvc } from 'core/services';
import { isServiceNowIntegrated } from './servicenow/helpers';
import { Text, Tooltip } from '@chakra-ui/react';
import { THEME_COLORS } from 'library/theme/colors';
import { truncate } from 'core/helpers/stringUtils';

type IIntegrationType =
  | 'slack'
  | 'msteams'
  | 'hangouts'
  | 'jiracloud'
  | 'jiraserver'
  | 'sso'
  | 'circleci'
  | 'servicenow'
  | '';

interface IIntegrationsMap {
  type: IIntegrationType;
  name: string;
  title?: string;
  whetherIntegrated?: boolean;
  description?: string;
  isDisabled?: boolean;
  width: number;
  margin: number;
}
type Snack = { showMessage: (message: string) => void };
type IProps = Pick<IAppState, 'organization' | 'userInfo' | 'roles'> & RouteComponentProps & Snack;

interface IState {
  integrationTypes: IIntegrationsMap[];
  openModal: IIntegrationType | 'confirm_revoke';
  revokeText?: React.ReactNode | string;
  copied: boolean;
  showBoxShow: boolean;
  jiraCloudId: string;
  showCircleciIntegrationModal: string;
  errors: IComponentErrorState;
  slackStatus: IExtensionStatus;
  msteamsStatus: IExtensionStatus;
  serviceNowStatus: IExtensionStatus;
  extensionMSTeamsConfig: IExtensionMSTeams | null;
  slackChannel: ISlackChannel | null;
  showEmptyChannelError: boolean;
  selectedIntegration: IIntegrationsMap | null;
  isModalDirty: boolean;
  showUnsavedChangesModal: boolean;
  openJiraCloudSettings: boolean;
  hasJiraAccounts: boolean;
}
class Integrations extends React.Component<IProps, IState> {
  public JCService = new JiraCloudService();
  public JSService = new JiraServerService();
  public JsServiceNew = new JiraCloudServiceNew();
  private _extensionService = new ExtensionService();
  private _servicenowSvc = new ServiceNowSvc();
  private _SSOService = new SSOService();
  private isSlackV2Enabled = this.props.organization.currentOrg.o?.config?.['slackv2'];

  constructor(props: IProps) {
    super(props);
    this.state = {
      integrationTypes: [
        {
          type: 'slack',
          name: 'Slack',
          title: 'Route incident notifications to a Slack Channel',
          whetherIntegrated: false,
          width: 105,
          margin: 20,
        },
        {
          type: 'msteams',
          name: 'MS Teams',
          title: 'To activate bi-directional chatops via MS Teams',
          whetherIntegrated: false,
          width: 52,
          margin: 8,
        },
        {
          type: 'hangouts',
          name: 'Hangouts Chat',
          title: 'Route critical incidents to a Google Chat Spaces',
          description:
            'Install Squadcast bot in your Google Chat Spaces and integrate it with your Squadcast organization using the following integration key. All of your critical alerts will be posted in the room.',
          width: 185,
          margin: 20,
        },
        {
          type: 'jiracloud',
          name: 'Jira Cloud',
          title: 'Bidirectionally integrate Jira cloud and Squadcast',
          whetherIntegrated: false,
          width: 105,
          margin: 20,
        },
        {
          type: 'jiraserver',
          name: 'Jira DC',
          title: 'Bidirectionally integrate Jira DC and Squadcast',
          whetherIntegrated: false,
          width: 105,
          margin: 20,
        },
        {
          type: 'sso',
          name: 'SSO',
          title: 'Configure SSO using SAML 2.0',
          width: 116,
          margin: 20,
        },
        {
          type: 'circleci',
          name: 'CircleCI',
          title: 'Enable Squadcast Actions for CircleCI',
          whetherIntegrated: false,
          width: 180,
          margin: 20,
        },
        {
          type: 'servicenow',
          name: 'ServiceNow',
          title: 'Bidirectionally integrate ServiceNow and Squadcast',
          whetherIntegrated: false,
          isDisabled: false,
          width: 215,
          margin: 35,
        },
      ],
      copied: false,
      showBoxShow: false,
      showCircleciIntegrationModal: '',
      openModal: '',
      jiraCloudId: '',
      errors: {},
      slackStatus: 'not_integrated',
      msteamsStatus: 'not_integrated',
      serviceNowStatus: 'not_integrated',
      slackChannel: null,
      showEmptyChannelError: false,
      selectedIntegration: null,
      extensionMSTeamsConfig: null,
      isModalDirty: false,
      showUnsavedChangesModal: false,
      openJiraCloudSettings: false,
      hasJiraAccounts: false,
    };
    this.integrateWithCircleci = this.integrateWithCircleci.bind(this);
    this.integrate = this.integrate.bind(this);
    this.checkStatus = this.checkStatus.bind(this);
    this.selectSlackChannel = this.selectSlackChannel.bind(this);
  }

  public selectSlackChannel() {
    this.setState({
      openModal: 'slack',
    });
  }

  public revoke = async (shouldRevoke: boolean) => {
    if (!this.state.selectedIntegration) return;

    const type = this.state.selectedIntegration.type;
    this.setState({ selectedIntegration: null });

    this.hideOpenModals();

    if (!shouldRevoke) return;

    if (type === 'msteams') {
      const { extensionMSTeamsConfig } = this.state;
      if (!extensionMSTeamsConfig) return;
      await this._extensionService.deleteMSTeamsConfig();
      this.checkStatus();
    }

    if (type === 'jiracloud') {
      AppTracker.track(T_WA_GS_JIRA_CLOUD_REVOKED);
      this.JCService.revoke(this.state.jiraCloudId).then(() => {
        this.checkStatus();
      });
      return;
    }

    if (type === 'jiraserver') {
      AppTracker.track(T_WA_GS_JIRA_SERVER_REVOKED);
      this.JSService.revoke().then(() => {
        this.checkStatus();
      });
      return;
    }

    switch (type) {
      case 'slack':
        AppTracker.track(T_WA_GS_SLACK_REVOKED);
        break;
      case 'circleci':
        AppTracker.track(T_WA_GS_CIRCLECI_REVOKED);
        break;
      case 'servicenow':
        AppTracker.track(TA_WA_UP_SNW_EXTENSION_REVOKED);
        break;
      default:
        break;
    }

    if (type === 'slack' && this.isSlackV2Enabled) {
      await this._extensionService.revoke('slack_v2').then(response => {
        this.checkStatus();
      });
      return;
    }

    await this._extensionService
      .revoke(type)
      .then(response => {
        this.checkStatus();
      })
      .catch(_ => {
        this.checkStatus();
      });
  };

  public integrateMSTeams = async () => {
    const { extensionMSTeamsConfig } = this.state;
    if (!extensionMSTeamsConfig) {
      const redirectUrl =
        'https://support.squadcast.com/docs/msteams#follow-the-steps-below-to-integrate-squadcast-and-ms-teams';
      window.open(redirectUrl, '_blank');
      return;
    }

    this.checkStatus();
  };

  public integrate(type: IIntegrationType, event: any) {
    if (type === 'msteams') {
      this.integrateMSTeams();
    } else {
      this.setState({
        openModal: type,
      });
    }

    switch (type) {
      case 'slack':
        AppTracker.track(T_WA_GS_SLACK_INTEGRATED);
        break;
      case 'circleci':
        AppTracker.track(T_WA_GS_CIRCLECI_INTEGRATED);
        break;
      case 'jiracloud':
        AppTracker.track(T_WA_GS_JIRA_CLOUD_INTEGRATED);
        break;
      case 'jiraserver':
        AppTracker.track(T_WA_GS_JIRA_SERVER_INTEGRATED);
        break;
      case 'hangouts':
        AppTracker.track(T_WA_GS_GOOGLE_HANGOUTS_INTEGRATION_ATTEMPTED);
        break;
      case 'msteams':
        AppTracker.track(T_WA_GS_MICROSOFT_TEAMS_INTEGRATION_ATTEMPTED);
        break;
      default:
        break;
    }
  }

  public integrateWithCircleci() {
    this.setState({
      openModal: 'circleci',
    });
  }

  public componentDidUpdate(_: any, prevState: IState) {
    if (prevState.showCircleciIntegrationModal !== this.state.showCircleciIntegrationModal) {
      this.checkStatus();
    }
  }

  public featchJiraAccounts = async () => {
    const accounts = await this.JsServiceNew.getJiraAccounts();
    if (accounts.data.length > 0) {
      this.setState({
        hasJiraAccounts: true,
      });
    }
  };

  public componentDidMount() {
    this.checkStatus();
    const params = queryString.parse(this.props.location.search);

    if (params.msteams_success) {
      this.setState({ openModal: 'msteams' });
    }

    this.featchJiraAccounts();

    if (params.E_MSTEAMS_ERROR) {
      console.log('Error: ', params.E_MSTEAMS_ERROR);
      this.setState({
        openModal: 'msteams',
        errors: { E_MSTEAMS_ERROR: params.E_MSTEAMS_ERROR as string },
      });
    }

    if (params.E_SLACK_INTEGRATE) {
      exception.handle('E_SLACK_INTEGRATE', params.E_SLACK_INTEGRATE);
      this.setState({
        openModal: 'slack',
        errors: { E_SLACK_INTEGRATE: params.E_SLACK_INTEGRATE as string },
      });
      return;
    }

    if (params.slack_success) {
      this.setState({ openModal: 'slack', slackStatus: 'partially_integrated' });
    }

    AppTracker.track(T_WA_GS_EXTENSIONS_PAGE_VIEWED);
  }

  public async fetchAndCheckMSTeams() {
    try {
      const { data: msteamsExtensionConfig } = await this._extensionService.getMSTeamsConfig();
      this.checkMSTeamsStatus(msteamsExtensionConfig.data);
    } catch (error) {
      this.setState({
        integrationTypes: this.state.integrationTypes.map((integration: any) => {
          if (integration.type === 'msteams') {
            integration.whetherIntegrated = false;
          }
          return integration;
        }),
        extensionMSTeamsConfig: null,
      });
    }
  }

  public async checkStatus() {
    try {
      const {
        data: { result, extensions },
      } = await axios.get(
        API.config.endpoint + `/organizations/${API.config.organizationId}/extensions/all`,
      );

      this.fetchAndCheckMSTeams();
      this.checkSSO();
      if (result) {
        const currentIntegrationTypes = this.state.integrationTypes.slice(0);
        const updatedIntegrationTypes = currentIntegrationTypes.map((integration: any) => {
          if (integration.type !== 'jiracloud') {
            integration.whetherIntegrated = extensions.includes(integration.type);
          }

          return integration;
        });
        this.setState({ integrationTypes: updatedIntegrationTypes }, () => {
          this.checkJiraCloudStatus();
          this.checkJiraServerStatus();
          this.checkSlackStatus();
          this.checkServiceNowStatus();
        });
      }
    } catch (err: any) {
      exception.handle('E_GET_APP_INTEGRATIONS', err);
    }
  }

  public async checkSSO() {
    try {
      const { data } = await this._SSOService.getSSOConfig(API.config.organizationId);
      const { is_enabled, provider } = data.data;
      this.setState(({ integrationTypes }: { integrationTypes: IIntegrationsMap[] }) => {
        integrationTypes.forEach((i: IIntegrationsMap) => {
          if (i.type === 'sso') {
            i.whetherIntegrated = is_enabled;
            i.title = is_enabled
              ? `Enabled via ${provider.charAt(0).toUpperCase() + provider.slice(1)} SSO`
              : 'Configure SSO using SAML 2.0';
          }
        });
        return { integrationTypes };
      });
    } catch (err: any) {
      exception.handle('E_GET_APP_INTEGRATIONS', err);
      this.setState(({ integrationTypes }: any) => {
        integrationTypes.find((i: any) => i.type === 'sso').whetherIntegrated = false;
        return {
          integrationTypes,
        };
      });
    }
  }

  public async checkJiraServerStatus() {
    try {
      const { data } = await this.JSService.getJiraConfigs();
      const isIntegrated = data.host.length > 0;
      this.setState(({ integrationTypes }: any) => {
        integrationTypes.find((i: any) => i.type === 'jiraserver').whetherIntegrated = isIntegrated;

        this.JSService.jiraServerId = isIntegrated ? data.id : '';

        return {
          integrationTypes,
        };
      });
    } catch (err: any) {
      exception.handle('E_GET_APP_INTEGRATIONS', err);
      this.setState(({ integrationTypes }: any) => {
        integrationTypes.find((i: any) => i.type === 'jiracloud').whetherIntegrated = false;
        return {
          integrationTypes,
        };
      });
    }
  }

  public async checkJiraCloudStatus() {
    try {
      const { data } = await this.JCService.getJiraConfigs();
      this.setState(({ integrationTypes }: any) => {
        integrationTypes.find((i: any) => i.type === 'jiracloud').whetherIntegrated = true;
        return {
          integrationTypes,
          jiraCloudId: data.id,
        };
      });
    } catch (err: any) {
      this.setState(({ integrationTypes }: any) => {
        integrationTypes.find((i: any) => i.type === 'jiracloud').whetherIntegrated = false;
        return {
          integrationTypes,
          jiraCloudId: '',
        };
      });
    }
  }

  public checkSlackStatus = async () => {
    try {
      const {
        data: {
          data: { status },
        },
      } = !this.isSlackV2Enabled
        ? await this._extensionService.getSlackStatus()
        : await this._extensionService.getSlackV2Status();

      this.setState({ slackStatus: status });
      if (status === 'integrated' || status === 'partially_integrated') {
        this.setState({
          integrationTypes: this.state.integrationTypes.map((integration: any) => {
            if (integration.type === 'slack') {
              integration.whetherIntegrated = true;
            }
            return integration;
          }),
        });
        this.isSlackV2Enabled && this.getSlackChannel();
      } else {
        this.setState({
          integrationTypes: this.state.integrationTypes.map(integration => {
            if (integration.type === 'slack') {
              integration.whetherIntegrated = false;
            }
            return integration;
          }),
        });
        this.setState({ slackChannel: null });
      }
    } catch (err: any) {
      this.handleError('E_SLACK_GET_STATUS', err);
    }
  };

  public checkServiceNowStatus = async () => {
    try {
      const {
        data: {
          data: { status, is_enabled = false },
        },
      } = await this._servicenowSvc.getServiceNowStatus();

      this.setState({ serviceNowStatus: status });

      if (isServiceNowIntegrated(status)) {
        this.setState({
          integrationTypes: this.state.integrationTypes.map((integration: IIntegrationsMap) => {
            if (integration.type === 'servicenow') {
              integration.whetherIntegrated = true;
              integration.isDisabled = !is_enabled;
            }
            return integration;
          }),
        });
      } else {
        this.setState({
          integrationTypes: this.state.integrationTypes.map((integration: IIntegrationsMap) => {
            if (integration.type === 'servicenow') {
              integration.whetherIntegrated = false;
            }
            return integration;
          }),
        });
      }
    } catch (err: any) {
      this.handleError('E_SERVICENOW_GET_STATUS', err);
    }
  };

  public checkMSTeamsStatus = (msExtensionConfig: IExtensionMSTeams) => {
    if (msExtensionConfig.id && msExtensionConfig.is_active) {
      this.setState({
        integrationTypes: this.state.integrationTypes.map((integration: any) => {
          if (integration.type === 'msteams') {
            integration.whetherIntegrated = true;
          }
          return integration;
        }),
        extensionMSTeamsConfig: msExtensionConfig,
      });
    } else if (msExtensionConfig.id && !msExtensionConfig.is_active) {
      this.setState({
        integrationTypes: this.state.integrationTypes.map((integration: any) => {
          if (integration.type === 'msteams') {
            integration.whetherIntegrated = false;
          }
          return integration;
        }),
        extensionMSTeamsConfig: msExtensionConfig,
      });
    } else {
      this.setState({
        integrationTypes: this.state.integrationTypes.map((integration: any) => {
          if (integration.type === 'msteams') {
            integration.whetherIntegrated = false;
          }
          return integration;
        }),
        extensionMSTeamsConfig: msExtensionConfig,
      });
    }
  };

  public getSlackChannel = async () => {
    try {
      const {
        data: { data: channel },
      } = await this._extensionService.getOrganizationChannel_V2();
      this.setState({ slackChannel: channel });
    } catch (err: any) {
      this.handleError('E_SLACK_GET_ORG_CHANNEL', err);
    }
  };

  handleError = (errorType: IAppExceptions, err: any) => {
    const statusCode = err?.response?.status ?? null;
    if (!statusCode || statusCode < 500) return;
    exception.handle(errorType, err);
  };

  public hideOpenModals = () => {
    this.props.history.push({ search: '' });
    this.setState(
      {
        openModal: '',
        isModalDirty: false,
        showUnsavedChangesModal: false,
      },
      () => {
        this.checkStatus();
      },
    );
  };
  public showIntegrationMessage = (message: string) => {
    this.props.showMessage(message);
  };

  public getIntegrationRibbon = (integration: IIntegrationsMap) => {
    if (
      integration.name.toLowerCase() !== 'slack' &&
      integration.name.toLowerCase() !== 'servicenow'
    ) {
      if (integration.name.toLowerCase() === 'sso') {
        return integration.whetherIntegrated ? <SSORibbon className="integrated-ribbon" /> : null;
      }
      return integration.whetherIntegrated ? (
        <IntegratedRibbon className="integrated-ribbon" />
      ) : null;
    }

    // for slack only
    const status =
      integration.name.toLowerCase() === 'slack'
        ? this.state.slackStatus
        : this.state.serviceNowStatus;
    switch (status) {
      case 'integrated':
        return <IntegratedRibbon className="integrated-ribbon" />;
      case 'partially_integrated':
        return <IncompleteRibbon className="integrated-ribbon" />;
      case 'not_integrated':
        return null;
    }
  };

  checkSlackAndHide = () => {
    this.checkDirtyAndCloseModal();
    if (this.state.slackStatus === 'partially_integrated') {
      this.setShowEmptyChannelError();
      return;
    }
  };

  setShowEmptyChannelError = () => {
    if (!this.state.showEmptyChannelError) this.setState({ showEmptyChannelError: true });
  };

  resetShowEmptyChannelError = () => {
    if (this.state.showEmptyChannelError) this.setState({ showEmptyChannelError: false });
  };

  openRevokeConfirm = (itype: IIntegrationsMap, revokeText?: React.ReactNode | string) => () => {
    this.setState({ selectedIntegration: itype, openModal: 'confirm_revoke', revokeText });
  };

  //

  setUnsavedChanges = (showUnsavedChangesModal: boolean) =>
    this.setState({ showUnsavedChangesModal });

  //
  closeUnsavedChangesModal = () => this.setState({ showUnsavedChangesModal: false });

  onDiscardChanges = () => {
    this.setState({
      isModalDirty: false,
      showUnsavedChangesModal: false,
    });
    this.hideOpenModals();
  };

  //

  checkAndSetDirty = () => {
    if (!this.state.isModalDirty) this.setState({ isModalDirty: true });
  };

  //

  checkDirtyAndCloseModal = () => {
    if (this.state.isModalDirty) {
      this.setUnsavedChanges(true);
    } else {
      this.hideOpenModals();
    }
  };

  //after save set modal not dirty and remove the  guard
  removeModalDirty = () => {
    this.setState({ isModalDirty: false, showUnsavedChangesModal: false });
  };

  public render() {
    const { theme } = Theme;
    const plan = this.props.organization.plan.p;
    const integrationAccess = {
      ssoInPlan: !!(plan?.rules['sso']?.quantity ?? false),
      jiraCloudInPlan: !!(plan?.rules['jira-cloud']?.quantity ?? false),
      jiraServerInPlan: !!(plan?.rules['jira-server']?.quantity ?? false),
      serviceNowInPlan: !!(plan?.rules['servicenow']?.quantity ?? false),
      circleCiInPlan: !!(plan?.rules['circleci']?.quantity ?? false),
    };
    const activeIntegrationTypes = this.state.integrationTypes;
    const integratedExtensions = activeIntegrationTypes.filter(
      integrationType => integrationType.whetherIntegrated,
    );

    const isIntegrationNotAvailable = (integrationType: IIntegrationsMap) => {
      return (
        (integrationType.type === 'sso' && !integrationAccess.ssoInPlan) ||
        (integrationType.type === 'jiracloud' && !integrationAccess.jiraCloudInPlan) ||
        (integrationType.type === 'jiraserver' && !integrationAccess.jiraServerInPlan) ||
        (integrationType.type === 'servicenow' && !integrationAccess.serviceNowInPlan) ||
        (integrationType.type === 'circleci' && !integrationAccess.circleCiInPlan)
      );
    };

    const openModal = this.state.openModal;

    return (
      <div
        className="main-container"
        ref="main-container"
        onScroll={() => {
          const offSet = (this.refs['main-container'] as HTMLElement).scrollTop;
          if (offSet) {
            this.setState({
              showBoxShow: true,
            });
          } else {
            this.setState({
              showBoxShow: false,
            });
          }
        }}
      >
        <div
          className=""
          style={this.state.showBoxShow ? { boxShadow: '0 10px 30px rgba(13, 33, 73, 0.08)' } : {}}
        >
          <h1 className="item-box-main-heading" style={{ display: 'inline-block' }}>
            Extensions
          </h1>
          {integratedExtensions.length > 0 && (
            <Para fontSize={20} style={{ marginLeft: 10, display: 'inline-block' }}>
              ({integratedExtensions.length})
            </Para>
          )}
        </div>
        <Para style={{ marginBottom: '10px', fontSize: '12px', color: '#808080' }}>
          Extensions help you integrate Squadcast with other tools so that you can take actions from
          within our platform.
        </Para>
        <div className="data-display-container" style={{ marginTop: 20 }}>
          <div className="integration-box-container">
            {activeIntegrationTypes.map((integrationType: IIntegrationsMap, index: number) => {
              return (
                <div className="integration-type" style={{ height: 267 }} key={index}>
                  {isIntegrationNotAvailable(integrationType) ? (
                    <div
                      style={{
                        float: 'right',
                        position: 'relative',
                        top: '-40px',
                        paddingRight: '4px',
                      }}
                    >
                      <span>
                        <CrownIcon />
                      </span>
                    </div>
                  ) : integrationType?.isDisabled ? (
                    <Text
                      color={THEME_COLORS.secondary['700']}
                      fontSize="12.5px"
                      display="flex"
                      alignItems="center"
                      gap="3px"
                      style={{
                        position: 'absolute',
                        top: '9px',
                        right: '9px',
                      }}
                    >
                      <DotIcon /> Disabled
                    </Text>
                  ) : (
                    ''
                  )}
                  <div
                    className="text-center"
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                  >
                    {this.getIntegrationRibbon(integrationType)}
                    <img
                      src={'/icons/integrations/' + integrationType.type + '.png'}
                      style={{
                        marginBottom: integrationType.margin,
                        width: integrationType.width,
                      }}
                      alt="props"
                    />
                    <p
                      style={{
                        margin: 0,
                        marginBottom:
                          integrationType.type === 'slack' && this.state.slackChannel
                            ? 0
                            : integrationType.type === 'msteams'
                            ? 25
                            : 30,
                      }}
                      className="item-box-tagline"
                    >
                      {integrationType.title}
                    </p>

                    {integrationType.type === 'slack' && this.state.slackChannel && (
                      <Grid alignItems="center" justifyContent="center" style={{ marginBottom: 8 }}>
                        <Tooltip label={`#${this.state.slackChannel.channel.name}`}>
                          <Para>{truncate(`#${this.state.slackChannel.channel.name}`, 30)}</Para>
                        </Tooltip>
                      </Grid>
                    )}
                  </div>
                  <div>
                    {integrationType.whetherIntegrated ? (
                      <div className="text-center">
                        {integrationType.type === 'slack' && (
                          <button
                            className="main-button-inverse"
                            style={{ marginRight: 20 }}
                            onClick={() => this.setState({ openModal: 'slack' })}
                          >
                            {this.state.slackStatus === 'partially_integrated'
                              ? 'Configure'
                              : 'Reconfigure'}
                          </button>
                        )}
                        {integrationType.type === 'servicenow' && (
                          <button
                            className="main-button-inverse"
                            style={{ marginRight: 20 }}
                            onClick={() => this.setState({ openModal: 'servicenow' })}
                          >
                            {this.state.serviceNowStatus === 'integrated' ||
                            this.state.serviceNowStatus === 'partially_integrated'
                              ? 'Reconfigure'
                              : 'Configure'}
                          </button>
                        )}
                        {integrationType.type === 'msteams' && (
                          <button
                            className="main-button-inverse"
                            style={{
                              marginRight: 20,
                            }}
                            onClick={() => this.setState({ openModal: 'msteams' })}
                          >
                            Reconfigure
                          </button>
                        )}
                        {integrationType.type === 'jiracloud' && (
                          <Link to="extensions/jira-cloud">
                            <button className="main-button-inverse" style={{ marginRight: 20 }}>
                              Reconfigure
                            </button>
                          </Link>
                        )}
                        {integrationType.type === 'jiraserver' && (
                          <button
                            className="main-button-inverse"
                            style={{ marginRight: 20 }}
                            onClick={() => this.setState({ openModal: 'jiraserver' })}
                          >
                            Reconfigure
                          </button>
                        )}
                        {integrationType.type === 'circleci' && (
                          <button
                            className="main-button-inverse"
                            style={{ marginRight: 20 }}
                            onClick={this.integrateWithCircleci.bind(null)}
                          >
                            View Token
                          </button>
                        )}
                        {integrationType.type === 'sso' && (
                          <button
                            className="main-button-inverse"
                            style={{ marginRight: 20 }}
                            onClick={this.integrate.bind(null, integrationType.type)}
                          >
                            Reconfigure
                          </button>
                        )}
                        {integrationType.type !== 'sso' && integrationType.type !== 'jiracloud' && (
                          <button
                            className="main-button"
                            style={{
                              backgroundColor: '#f04546',
                              border: 'none',
                            }}
                            onClick={() => {
                              if (integrationType.type === 'msteams') {
                                return this.openRevokeConfirm(
                                  integrationType,
                                  'Are you sure you want to revoke the integration for MS Teams, this will remove all the configuration mapping and you need to re-connect?',
                                )();
                              }
                              if (integrationType.type === 'servicenow') {
                                return this.openRevokeConfirm(
                                  integrationType,
                                  <>
                                    <p>
                                      Are you sure you want to revoke the integration for
                                      ServiceNow, this will delete all configured data and cannot be
                                      undone.
                                    </p>
                                    <br />
                                    <p>Note : You can also disable the function temporarily</p>
                                  </>,
                                )();
                              }
                              return this.openRevokeConfirm(integrationType)();
                            }}
                          >
                            Revoke
                          </button>
                        )}
                      </div>
                    ) : (
                      <div className="integration-details">
                        <div className="text-center">
                          {isIntegrationNotAvailable(integrationType) ? (
                            <p
                              style={{ margin: 0, marginBottom: 35 }}
                              className="item-box-tagline font-bold"
                            >
                              {'This feature is not available in your current plan'}
                            </p>
                          ) : (
                            <>
                              {this.state.hasJiraAccounts &&
                              integrationType.type === 'jiracloud' ? (
                                <Link to="extensions/jira-cloud">
                                  <button
                                    className="main-button-inverse"
                                    style={{ marginRight: 20 }}
                                  >
                                    Reconfigure
                                  </button>
                                </Link>
                              ) : (
                                <>
                                  {integrationType.type === 'jiracloud' ? (
                                    <Link to="extensions/jira-cloud">
                                      <button className="main-button" style={{ marginRight: 20 }}>
                                        Integrate
                                      </button>
                                    </Link>
                                  ) : (
                                    <button
                                      className="main-button"
                                      onClick={this.integrate.bind(null, integrationType.type)}
                                    >
                                      {integrationType.type === 'sso' ? 'Configure' : 'Integrate'}
                                    </button>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </div>
                        {integrationType.description && this.state.openModal === 'hangouts' ? (
                          <div
                            onClick={event => {
                              event.stopPropagation();
                            }}
                            className="integration-details-container"
                            style={{ width: 450 }}
                          >
                            <p
                              style={{
                                position: 'absolute',
                                cursor: 'pointer',
                                fontWeight: 600,
                                right: 10,
                                margin: 0,
                                top: 14,
                              }}
                              onClick={() => {
                                this.setState({
                                  openModal: '',
                                });
                              }}
                            >
                              X
                            </p>
                            <div
                              className="item-box-tagline"
                              style={{ marginBottom: 20, lineHeight: 1.5 }}
                            >
                              {integrationType.description}
                            </div>
                            <div className="font-bold item-box-tagline cursor-pointer description-key">
                              <p>{md5(API.config.organizationId)}</p>
                              <CopyToClipboard
                                text={md5(API.config.organizationId)}
                                onCopy={() => {
                                  this.setState({
                                    copied: true,
                                  });
                                }}
                              >
                                <button className="copy-button">
                                  {this.state.copied ? <span>Copied!</span> : <span>Copy</span>}
                                </button>
                              </CopyToClipboard>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {!this.isSlackV2Enabled ? (
          <DialogModalFrame id="slack_config" width="500px" onClose={this.checkSlackAndHide}>
            {openModal === 'slack' && (
              <Slack
                status={this.state.slackStatus}
                selectedChannel={this.state.slackChannel}
                width={500}
                close={this.hideOpenModals}
                integrateError={this.state.errors?.E_SLACK_INTEGRATE ?? null}
                showEmptyChannelError={this.state.showEmptyChannelError}
                resetShowEmptyChannelError={this.resetShowEmptyChannelError}
                checkAndSetDirty={this.checkAndSetDirty}
              />
            )}
          </DialogModalFrame>
        ) : (
          <SlackV2
            isOpen={openModal === 'slack'}
            slackStatus={this.state.slackStatus}
            onClose={this.hideOpenModals}
          />
        )}
        {openModal === 'circleci' && (
          <CircleciIntegrationModal
            isOpen={openModal === 'circleci'}
            onClose={this.hideOpenModals}
          />
        )}

        <DialogModalFrame id="jira_config" width="800px" onClose={this.checkDirtyAndCloseModal}>
          {openModal === 'jiracloud' && (
            <JiraCloudModal
              hide={this.hideOpenModals}
              checkAndSetDirty={this.checkAndSetDirty}
              removeModalDirty={this.removeModalDirty}
            />
          )}
          {openModal === 'jiraserver' && (
            <JiraServerModal
              hide={this.hideOpenModals}
              checkAndSetDirty={this.checkAndSetDirty}
              removeModalDirty={this.removeModalDirty}
            />
          )}
        </DialogModalFrame>
        {openModal === 'servicenow' && (
          <ServiceNow
            isOpen={openModal === 'servicenow'}
            serviceNowStatus={this.state.serviceNowStatus}
            onClose={(isDirty: boolean) => {
              if (isDirty) this.setUnsavedChanges(true);
              else this.hideOpenModals();
            }}
          />
        )}
        <DialogModalFrame id="sso_config" width="600px" onClose={this.checkDirtyAndCloseModal}>
          {openModal === 'sso' && (
            <SSOModal
              hide={this.hideOpenModals}
              showIntegrationMessage={this.showIntegrationMessage}
              checkAndSetDirty={this.checkAndSetDirty}
              removeModalDirty={this.removeModalDirty}
            />
          )}
        </DialogModalFrame>

        <DialogModalFrame
          id="slack_revoke_confirm"
          width="450px"
          onClose={this.checkDirtyAndCloseModal}
        >
          {openModal === 'confirm_revoke' && (
            <ConfirmModal
              displayText={
                this.state.revokeText ??
                `Are you sure you want to revoke ${this.state.selectedIntegration?.name} ?`
              }
              buttonText="Yes"
              hide={this.revoke}
            />
          )}
        </DialogModalFrame>
        <DialogModalFrame
          hideClose
          id="msteams"
          width="58vw"
          onClose={this.checkDirtyAndCloseModal}
        >
          {openModal === 'msteams' && (
            <MSTeams
              error={this.state.errors?.E_MSTEAMS_ERROR ?? undefined}
              config={this.state.extensionMSTeamsConfig}
              onClose={this.hideOpenModals}
              refresh={() => this.checkStatus()}
              checkAndSetDirty={this.checkAndSetDirty}
              checkDirtyAndCloseModal={this.checkDirtyAndCloseModal}
            />
          )}
        </DialogModalFrame>
        <UnsavedChangesGuard
          isManual={true}
          showModal={this.state.showUnsavedChangesModal}
          onModalClose={this.closeUnsavedChangesModal}
          onDiscardChanges={this.onDiscardChanges}
        />
      </div>
    );
  }
}
const Integration = connect((state: IAppState) => ({
  organization: state.organization,
  userInfo: state.userInfo,
  roles: state.roles,
}))(Integrations);

function IntegrationsHOC(props: IProps) {
  const _createSnack = SnackContext();
  const [successMessage, setSuccessMessage] = React.useState<string>('');
  const [hasMessage, setHasMessage] = React.useState<boolean>(false);
  const showMessage = (message: string) => {
    setSuccessMessage(message);
    setHasMessage(true);
  };
  useEffect(() => {
    if (hasMessage) {
      _createSnack(
        <SnackBar background={Theme.theme.success.default} className="incident_list__styled_snack">
          <Para color={Theme.theme.shades.white}> {successMessage}</Para>
        </SnackBar>,
      );
      setTimeout(() => setHasMessage(false), 3000);
    } else {
      _createSnack(null);
    }

    return () => _createSnack(null);
  });
  return <Integration {...props} showMessage={showMessage} />;
}
export default withRouter(IntegrationsHOC);
