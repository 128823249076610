import {
  Box,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
} from '@chakra-ui/react';
import { truncate } from 'core/helpers/stringUtils';
import { LightBulbIcon, TooltipGreyIcon } from 'icons';
import { AssigneeAvatar, Checkbox, Divider, Radio } from 'library/atoms';
import { AssigneeType } from 'library/enums';
import { ChangeEvent } from 'react';

import { USERNAME_MAX_LENGTH } from './constants';
import { THEME_COLORS } from '../../theme/colors';

type Props = {
  id: string;
  name: string;
  username?: string | null;
  isSelected?: boolean;
  type?: AssigneeType;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  allowMulti?: boolean;
  suggested?: boolean;
  suggestedTooltip?: string;
};

export const Entity = ({
  id,
  name,
  username,
  isSelected,
  type,
  onChange,
  allowMulti = false,
  suggested = false,
  suggestedTooltip = undefined,
}: Props) => {
  return (
    <>
      {/** eslint-disable-next-line @typescript-eslint/ban-ts-comment
      @ts-ignore */}
      <HStack width="100%" justifyContent="space-between" alignItems="center">
        <HStack alignItems="center" p={2} columnGap={2}>
          {allowMulti ? (
            <Checkbox size="lg" value={id} isChecked={isSelected} onChange={onChange} />
          ) : (
            <Radio value={id} isChecked={!!isSelected} onChange={onChange} />
          )}
          {type && <AssigneeAvatar name={name} type={type} />}
          <VStack alignItems="left">
            <Text variant="body" color="secondary.1000">
              {name}
            </Text>
            {type === 'user' && username && (
              <Text variant="body" color="secondary.700">
                {truncate(username, USERNAME_MAX_LENGTH)}
              </Text>
            )}
          </VStack>
        </HStack>
        {suggested && (
          <div
            style={{
              backgroundColor: THEME_COLORS.secondary[100],
              padding: '3px 6px',
              borderRadius: '3px',
            }}
          >
            {/** eslint-disable-next-line @typescript-eslint/ban-ts-comment
            @ts-ignore */}
            <HStack justifyContent="space-evenly" alignItems="center" gap="1.5">
              <LightBulbIcon />
              <Text fontWeight={700} fontSize="10.5px" color={THEME_COLORS.secondary[700]}>
                Suggested
              </Text>
              {suggestedTooltip && (
                <Popover trigger="hover" placement="bottom-end" offset={[8, 16]}>
                  <PopoverTrigger>
                    <Box style={{ marginInlineStart: '0px' }}>
                      <TooltipGreyIcon />
                    </Box>
                  </PopoverTrigger>
                  <PopoverContent
                    bg={THEME_COLORS.secondary[100]}
                    width="240px"
                    border="none"
                    borderRadius="0px"
                  >
                    <PopoverArrow bg={THEME_COLORS.secondary[100]} />
                    <PopoverBody padding="8px">
                      <Text
                        fontSize="12.5px"
                        textAlign="center"
                        color={THEME_COLORS.secondary[950]}
                      >
                        {suggestedTooltip}
                      </Text>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              )}
            </HStack>
          </div>
        )}
      </HStack>
      <Divider />
    </>
  );
};
