import React, { HTMLAttributes, ReactNode, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Shell, Block } from './helper';
import IconButton from './IconButton';
import ContainerLoad from './ContainerLoad';
import { CloseIcon } from 'icons';
import hasChildren from './HasChildrent';
import { useOutsideClick } from '@chakra-ui/react';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * if the app root is different from react default ('root')
   */
  root?: string | 'root';
  id: string;
  onClose: () => void;
  children: ReactNode;
  height?: string | 'auto';
  width?: string | '800px';
  padding?: string | '32px';
  hideClose?: boolean | false;
  closeIconTop?: string | 'props.padding';
  openOnRender?: boolean | false;
  isLoading?: boolean | false;
  skipFocusTrap?: boolean | false;
}

const _modalRoot = document.createElement('div');
document.body.appendChild(_modalRoot);
const DialogModalFrame: React.FC<IProps> = props => {
  const open = () => {
    window.requestAnimationFrame(() => {
      document.body.style.overflowY = 'hidden';
    });
  };

  const triggerClose = () => props.onClose();

  const closeModal = () => {
    window.requestAnimationFrame(() => {
      document.body.style.overflowY = 'auto';
    });
    triggerClose();
  };

  useEffect(() => {
    if (props.openOnRender) {
      open();
    }
    return () => {
      closeModal();
    };
  }, []);

  // const ref = useRef(null);
  // useOutsideClick({
  //   ref: ref,
  //   handler: closeModal,
  // });

  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        closeModal();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);

  const { root, id, children, hideClose, padding, skipFocusTrap, ...blockProps } = props;
  const isChildNotEmpty = hasChildren(children);

  const rawShell = (
    <Shell id={`_shell`}>
      <div style={{ margin: '100px 0' }}>
        {isChildNotEmpty && (
          <div style={{ zIndex: 999, position: 'relative' }}>
            <ContainerLoad isLoading={props.isLoading || false} />
          </div>
        )}
        <Block tabIndex={0} padding={isChildNotEmpty ? padding : '0'} {...blockProps}>
          {isChildNotEmpty && (
            <React.Fragment>
              {children}
              {hideClose !== true && (
                <IconButton
                  onClick={closeModal}
                  style={{
                    position: 'absolute',
                    right: padding || '32px',
                    top: blockProps.closeIconTop || padding || '32px',
                  }}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </React.Fragment>
          )}
        </Block>
      </div>
    </Shell>
  );

  return ReactDOM.createPortal(rawShell, _modalRoot);
};

/** @component */
export default DialogModalFrame;
