import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { API } from '../../../../../core/api';
import { createStandaloneToast } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { queryKeys } from '../slo.constants';

const toast = createStandaloneToast();

const createSlo = async (sloID: number, payload: any) => {
  const data = axios.post(
    `${API.config.batman}/organizations/${API.config.organizationId}/slo`,
    payload,
  );
  return data;
};

const updateSlo = async (sloID: number, payload: any, teamID: string) => {
  const data = axios.put(
    `${API.config.batman}/organizations/${API.config.organizationId}/slo/${sloID}?owner_id=${teamID}`,
    payload,
  );
  return data;
};

export function useCreateSlo() {
  const queryClient = useQueryClient();
  const history = useHistory();

  const navigateOnSuccess = () => {
    setTimeout(() => {
      history.push('/slo');
    }, 1000);
  };

  const { mutate: createSlo_ } = useMutation(
    (slo: { sloID: number; payload: any }) => createSlo(slo.sloID, slo.payload),
    {
      onError: (error: any) => {
        const title = error?.response?.data?.meta?.error_message || 'error connecting to server';
        toast.closeAll();
        toast({ title, status: 'error', variant: 'subtle', isClosable: true });
      },
      onSuccess: data => {
        queryClient.invalidateQueries(queryKeys.SLOLIST, { refetchInactive: true });

        toast({
          title: 'SLO Created',
          description: 'SLO created successfully',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        navigateOnSuccess();
      },
      onSettled: () => {
        // queryClient.invalidateQueries(queryKeys.SLOFALSEPOSITIVE);
        // queryClient.invalidateQueries(queryKeys.SLOVIOLATING);
      },
    },
  );

  return { createSlo_ };
}

export function useUpdateSlo() {
  const history = useHistory();
  const queryClient = useQueryClient();

  const navigateOnSuccess = () => {
    setTimeout(() => {
      history.push('/slo');
    }, 1000);
  };

  const { mutate: updateSLO } = useMutation(
    (slo: { sloID: number; payload: any; teamID: string }) =>
      updateSlo(slo.sloID, slo.payload, slo.teamID),
    {
      onError: (error: any) => {
        const title = error?.response?.data?.meta?.error_message || 'error connecting to server';
        toast.closeAll();
        toast({ title, status: 'error', variant: 'subtle', isClosable: true });
      },
      onSuccess: data => {
        //  removing query invalidate tag queryKeys.SLOLIST because the component has refetchonMount true
        toast({
          title: 'SLO Updated',
          description: 'SLO Updated successfully',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        navigateOnSuccess();
      },
    },
  );
  return { updateSLO };
}
