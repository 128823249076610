import envVar from './config';

export const AppConfig = {
  pusher: envVar.VITE_PUSHER_KEY ?? '',
  pusher_host: envVar.VITE_PUSHER_HOST ?? '',
  mixpanel: envVar.VITE_MIXPANEL_KEY ?? '',
  sentry: {
    dns: {
      web: envVar.VITE_SENTRY_KEY ?? '',
    },
  },
  log_rocket: envVar.VITE_LOGROCKET_KEY ?? '',
  intercom: envVar.VITE_INTERCOM_KEY ?? '',
  userflow: envVar.VITE_USERFLOW_KEY ?? '',
  onesignal: envVar.VITE_ONESIGNAL_KEY ?? '',

  slack: envVar.VITE_SLACK_KEY ?? '',
  slackv2: envVar.VITE_SLACK_KEY_V2 ?? '',
  ms_teams: envVar.VITE_MS_TEAMS_KEY ?? '',

  cube_url: envVar.VITE_CUBE_URL ?? 'http://localhost:4000',

  mixpanel_proxy_url: envVar.VITE_MIXPANEL_PROXY_URL ?? '',

  incidents_url: envVar.VITE_INCIDENTS_URL ?? 'incidents.squadcast.com',
  status_page_url: envVar.VITE_STATUS_PAGE_URL ?? 'status.squadcast.io',
  status_page_host: envVar.VITE_STATUS_PAGE_HOST ?? 'https://statuspage.squadcast.com',
  ger_url: envVar.VITE_GER_URL ?? 'global-events.squadcast.com',

  sso_auth_url: envVar.VITE_SSO_AUTH_URL ?? 'https://auth.squadcast',
  EU_sso_auth_url: envVar.VITE_EU_SSO_AUTH_URL ?? 'https://auth.eu.squadcast',

  billing_url: envVar.VITE_BILLING_URL ?? 'https://squadcast.chargebeeportal.com/',
  ms_teams_bot_reauthorization_url: envVar.VITE_MS_TEAMS_BOT_REAUTHORIZATION_URL ?? '',

  platform_backend_url: envVar.VITE_PLATFORM_BACKEND_URL ?? 'https://platform-backend.squadcast',
  EU_platform_backend_url:
    envVar.VITE_EU_PLATFORM_BACKEND_URL ?? 'https://platform-backend.eu.squadcast',
  internal_api_url: envVar.VITE_INTERNAL_API_URL ?? 'https://internal-api.squadcast',
  EU_internal_api_url: envVar.VITE_EU_PLATFORM_BACKEND_URL ?? 'https://internal-api.eu.squadcast',
  api_url: envVar.VITE_API_URL ?? 'https://api.squadcast',
  EU_api_url: envVar.VITE_API_URL ?? 'https://api.eu.squadcast',
  app_url: envVar.VITE_URL ?? 'https://app.squadcast',
  eu_app_url: envVar.VITE_EU_URL ?? 'https://app.eu.squadcast.com',
  us_app_url: envVar.VITE_US_URL ?? 'https://app.squadcast.com',
  webforms_url: envVar.VITE_WEBFORM_URL ?? 'webforms.squadcast.com',
  webforms_cname: envVar.VITE_WEBFORM_CNAME ?? 'webforms.prod.squadcast.io',
};
