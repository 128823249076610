import { Grid, CopyIconButton, DialogModalFrame } from 'uie/components';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import breaks from 'remark-breaks';
import { Flex, useDisclosure } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { useState } from 'react';
import { Modal } from 'library/molecules';
import { Text } from 'library/atoms/Text';
import { FormButton } from 'library/atoms';
import { checkIfStorageHost } from 'core/util/checkStorageHost';

interface IProps {
  description: string;
}

const CustomCodeRenderer = (props: any) => {
  return (
    <div className="incident_details__code-container description">
      <pre className="incident_details__raw-code">{props.value}</pre>
      <div className="incident_details__code-copy">
        <CopyIconButton type="transparent">{props.value}</CopyIconButton>
      </div>
    </div>
  );
};

export const IncidentDescription = ({ description }: IProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [linktoFile, setLinkToFile] = useState('');

  const handleLinkClick = (href: string) => {
    setLinkToFile(href);
    onOpen();
  };
  const renderMdLink = (props: any) => {
    if (checkIfStorageHost(props.href)) {
      return (
        <span
          onClick={() => handleLinkClick(props.href)}
          style={{ textDecoration: 'none', cursor: 'pointer', color: '#1b7ff1' }}
        >
          {props.children} <ExternalLinkIcon w={2.5} h={2.5} position={'relative'} top={-1.5} />
        </span>
      );
    }
    return (
      // eslint-disable-next-line react/prop-types
      <>
        <a href={props.href} target="_blank" rel="noopener noreferrer" className="nnnnn">
          {/* eslint-disable-next-line react/prop-types*/}
          {props.children}
        </a>
        {props.href?.startsWith('https://storage.squadcast') && props.href?.endsWith('.mp3') && (
          <audio
            controls
            style={{
              marginTop: '5px',
            }}
          >
            <source src={props.href} type="audio/mpeg" />
          </audio>
        )}
      </>
    );
  };

  return (
    <Grid flexWidth={12}>
      <Grid type="column" className="incident_details__block">
        <Grid type="column" className="mt-10 ml-10">
          <div style={{ color: 'var(--shades-cement)', textDecoration: 'none' }}>
            <ReactMarkdown
              className="incident_details__mde"
              plugins={[breaks, gfm]}
              renderers={{ link: renderMdLink, code: CustomCodeRenderer }}
              source={description}
            />
          </div>
          <DialogModalFrame
            root="unsaved-changes-confirm"
            id="modalFrame"
            onClose={onClose}
            padding="20px"
            width="520px"
          >
            {isOpen && (
              <>
                <Text style={{ fontWeight: 'bold' }}>Caution !</Text>

                <Text>Attachments are not scanned for virus or malware</Text>
                <Grid
                  style={{ marginTop: 20, gap: 10, justifyContent: 'flex-end' }}
                  className="mr-20"
                >
                  <FormButton
                    variant="secondary"
                    borderWidth={'1.5px'}
                    title="Cancel"
                    onClick={() => {
                      onClose();
                      setLinkToFile('');
                    }}
                  />
                  <FormButton
                    title="Download"
                    onClick={() => {
                      window.open(linktoFile, '_blank');
                      onClose();
                      setLinkToFile('');
                    }}
                  />
                </Grid>
              </>
            )}
          </DialogModalFrame>
        </Grid>
      </Grid>
    </Grid>
  );
};
