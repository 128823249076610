import axios from 'axios';
import { IAppState } from 'core/interfaces/IAppState';
import { API } from '../api';
import {
  IBillingAddSubscriptionReq,
  IBillingPlan,
  IPricingEntity,
} from '../interfaces/IBillingPlan';

type IPricingEntityPluralMap = {
  [key in IPricingEntity]?: string;
};

export type Limit = number | 'unlimited' | undefined;
export const plurals: IPricingEntityPluralMap = {
  service: 'Services',
  postmortem: 'Postmortems',
  user: 'Users',
  stakeholder: 'Stakeholders',
  'status-page': 'Status Pages',
  'export-incident-timeline': 'Activity Timeline',
  teams: 'Teams',
  'tagging-rules': 'Tagging Rules',
  'deduplication-rules': 'Deduplication Rules',
  'routing-rules': 'Routing Rules',
  'suppression-rules': 'Suppression Rules',
  'event-hooks': 'Webhooks',
  'postmortem-templates': 'Postmortem Templates',
  runbooks: 'Runbooks',
  'auto-pause-transient-alerts': 'Auto Pause Transient Alerts',
  'delayed-notification': 'Delayed Notifications',
  'additional-responders': 'Additional Responders',
};
export class Result<T> {
  private _loaded = false;
  private value: T | undefined;

  public static loading<T>(): Result<T> {
    return new Result<T>();
  }

  public get isLoaded(): boolean {
    return this._loaded;
  }

  public get(): T {
    if (!this._loaded) {
      throw new Error('Cannot unwrap value from a still-loading result');
    }

    return this.value!;
  }

  public static data<T>(val: T): Result<T> {
    const loaded = new Result<T>();
    loaded._loaded = true;
    loaded.value = val;
    return loaded;
  }
}

class BillingService {
  private _apiBilling = `${API.config.endpoint}/organizations/${API.config.organizationId}/billings/subscription`;
  private _apiBatman = `${API.config.batman}/organizations/${API.config.organizationId}/billing/plan`;
  private _apiPublic = `${API.config.endpoint}/public/billings/plans`;

  public static DEFAULT_FREE_PLAN_SLUG = 'free-monthly-2022-02';
  public static GRANDFATHERED_ESSENTIAL_PLAN_SLUG = 'essential-free-monthly-2019-09';

  public static GRANDFATHERED_PRO_MONTHLY_SLUG = 'pro-monthly-2019-09';
  public static GRANDFATHERED_PRO_ANNUAL_SLUG = 'pro-annual-2019-09';

  public static NEW_PRO_MONTHLY_SLUG = 'pro-monthly-2022-09';
  public static NEW_PRO_ANNUAL_SLUG = 'pro-annual-2022-09';
  public static NEW_PRO_ANNUAL_EGP_SLUG = 'pro-annual-egp-2023-11';

  public static PREMIUM_MONTHLY_SLUG = 'premium-monthly-2022-09';
  public static PREMIUM_ANNUAL_SLUG = 'premium-annual-2022-09';
  public static PREMIUM_QUARTERLY_SLUG = 'premium-quaterly-2022-09';

  public static PRODUCT_TRIAL_PLAN_SLUG = 'product-trial-2022-05';
  public static PRODUCT_TRIAL_EXTENDED_PLAN_SLUG = 'product-trial-extended-2022-05';

  public static ENTERPRISE_MONTHLY_SLUG = 'enterprise-monthly-2019-09';
  public static ENTERPRISE_ANNUAL_SLUG = 'enterprise-annual-2019-09';
  public static NEW_ENTERPRISE_MONTHLY_SLUG = 'enterprise-monthly-2024-08';
  public static NEW_ENTERPRISE_ANNUAL_SLUG = 'enterprise-annual-2024-08';

  public static GROUP_TRIAL = 'TRIAL';
  public static GROUP_FREE = 'FREE';
  public static GROUP_OLD_PRO = 'OLD-PRO';
  public static GROUP_NEW_PRO = 'NEW-PRO';
  public static GROUP_PREMIUM = 'PREMIUM';
  public static GROUP_ENTERPRISE = 'ENTERPRISE';

  public static ORDER_OF_PLANS = [
    this.GROUP_TRIAL,
    this.GROUP_FREE,
    this.GROUP_OLD_PRO,
    this.GROUP_NEW_PRO,
    this.GROUP_PREMIUM,
    this.GROUP_ENTERPRISE,
  ];

  public static LIMITED_PLANS_DISPLAY_NAME = {
    [this.DEFAULT_FREE_PLAN_SLUG]: 'Free',
    [this.GRANDFATHERED_ESSENTIAL_PLAN_SLUG]: 'Free',
    [this.NEW_PRO_MONTHLY_SLUG]: 'Pro',
    [this.NEW_PRO_ANNUAL_SLUG]: 'Pro',
    [this.NEW_PRO_ANNUAL_EGP_SLUG]: 'Pro',
    [this.PRODUCT_TRIAL_EXTENDED_PLAN_SLUG]: 'Trial',
    [this.PRODUCT_TRIAL_PLAN_SLUG]: 'Trial',
  };

  public static PLANS_CONFIG = {
    [this.DEFAULT_FREE_PLAN_SLUG]: {
      plan_name: 'Free',
      plan_group: this.GROUP_FREE,
    },
    [this.GRANDFATHERED_ESSENTIAL_PLAN_SLUG]: {
      plan_name: 'Free',
      plan_group: this.GROUP_FREE,
    },
    [this.NEW_PRO_MONTHLY_SLUG]: {
      plan_name: 'Pro Monthly',
      plan_group: this.GROUP_NEW_PRO,
    },
    [this.NEW_PRO_ANNUAL_SLUG]: {
      plan_name: 'Pro Annual',
      plan_group: this.GROUP_NEW_PRO,
    },
    [this.NEW_PRO_ANNUAL_EGP_SLUG]: {
      plan_name: 'Pro Annual',
      plan_group: this.GROUP_NEW_PRO,
    },
    [this.PRODUCT_TRIAL_EXTENDED_PLAN_SLUG]: {
      plan_name: 'Trial Extended',
      plan_group: this.GROUP_TRIAL,
    },
    [this.PRODUCT_TRIAL_PLAN_SLUG]: {
      plan_name: 'Trial',
      plan_group: this.GROUP_TRIAL,
    },
    [this.GRANDFATHERED_PRO_ANNUAL_SLUG]: {
      plan_name: 'Pro Annual',
      plan_group: this.GROUP_OLD_PRO,
    },
    [this.GRANDFATHERED_PRO_MONTHLY_SLUG]: {
      plan_name: 'Pro Monthly',
      plan_group: this.GROUP_OLD_PRO,
    },
    [this.PREMIUM_MONTHLY_SLUG]: {
      plan_name: 'Premium Monthly',
      plan_group: this.GROUP_PREMIUM,
    },
    [this.PREMIUM_ANNUAL_SLUG]: {
      plan_name: 'Premium Annual',
      plan_group: this.GROUP_PREMIUM,
    },
    [this.PREMIUM_QUARTERLY_SLUG]: {
      plan_name: 'Premium Quarterly',
      plan_group: this.GROUP_PREMIUM,
    },
    [this.ENTERPRISE_MONTHLY_SLUG]: {
      plan_name: 'Enterprise Monthly',
      plan_group: this.GROUP_ENTERPRISE,
    },
    [this.NEW_ENTERPRISE_MONTHLY_SLUG]: {
      plan_name: 'Enterprise Monthly',
      plan_group: this.GROUP_ENTERPRISE,
    },
    [this.ENTERPRISE_ANNUAL_SLUG]: {
      plan_name: 'Enterprise Annual',
      plan_group: this.GROUP_ENTERPRISE,
    },
    [this.NEW_ENTERPRISE_ANNUAL_SLUG]: {
      plan_name: 'Enterprise Annual',
      plan_group: this.GROUP_ENTERPRISE,
    },
  };

  public addSubscription = (req: IBillingAddSubscriptionReq) =>
    axios.post(`${this._apiBilling}`, req);
  public cancelSub = () => axios.post(`${this._apiBilling}/cancel`);
  public billPlan = () => axios.get<{ data: IBillingPlan }>(`${this._apiBatman}`);
  public plans = () => axios.get(`${this._apiPublic}`);

  public static getPlanDisplayName(planSlug: string) {
    return this.PLANS_CONFIG.hasOwnProperty(planSlug)
      ? this.PLANS_CONFIG[planSlug].plan_name
      : null;
  }

  public static getPlanSuggestionsToUpgrade({
    organization,
  }: Pick<IAppState, 'organization'>): string[] {
    const planSlug = organization.plan.p?.active ? organization.plan.p?.plan_slug : '';
    switch (planSlug) {
      case this.ENTERPRISE_ANNUAL_SLUG:
      case this.NEW_ENTERPRISE_ANNUAL_SLUG:
        return [];
      case this.ENTERPRISE_MONTHLY_SLUG:
      case this.NEW_ENTERPRISE_MONTHLY_SLUG:
        return [this.ENTERPRISE_ANNUAL_SLUG];
      case this.PREMIUM_ANNUAL_SLUG:
      case this.PREMIUM_QUARTERLY_SLUG:
      case this.PREMIUM_MONTHLY_SLUG:
        return [this.ENTERPRISE_ANNUAL_SLUG, this.ENTERPRISE_MONTHLY_SLUG];
      default:
        return [this.PREMIUM_ANNUAL_SLUG, this.PREMIUM_MONTHLY_SLUG];
    }
  }

  public static isUpgradeable(
    { organization }: Pick<IAppState, 'organization'>,
    toUpgradePlanSlug: string,
  ): boolean {
    const activePlanSlug = organization.plan.p?.active ? organization.plan.p?.plan_slug : '';
    return activePlanSlug === ''
      ? true
      : this.ORDER_OF_PLANS.findIndex(
          plan => plan === this.PLANS_CONFIG[toUpgradePlanSlug].plan_group,
        ) >
          this.ORDER_OF_PLANS.findIndex(
            plan => plan === this.PLANS_CONFIG[activePlanSlug].plan_group,
          );
  }

  public static isLimitExceeded(
    { organization }: Pick<IAppState, 'organization'>,
    pricingEntity: IPricingEntity,
    currentCount: () => number,
  ) {
    const planActive = organization.plan.p?.active || false;
    if (!planActive) {
      return true;
    }
    const rules = organization.plan.p?.rules?.[pricingEntity] || null;
    if (rules == null) {
      return true;
    }

    if (rules.unlimited) {
      return false;
    }
    if (currentCount() >= rules.quantity) {
      return true;
    }
    return false;
  }

  public static isSomeLimitedPlan({ organization }: Pick<IAppState, 'organization'>) {
    const planSlug = organization.plan.p?.plan_slug || '';
    return this.LIMITED_PLANS_DISPLAY_NAME.hasOwnProperty(planSlug);
  }

  public static isOldProPlanActive({ organization }: Pick<IAppState, 'organization'>) {
    const planSlug = organization.plan.p?.plan_slug || '';
    const isActive = organization.plan.p?.active;
    return (
      (planSlug === this.GRANDFATHERED_PRO_ANNUAL_SLUG ||
        planSlug === this.GRANDFATHERED_PRO_MONTHLY_SLUG) &&
      isActive
    );
  }

  public static getLimit(
    { organization }: Pick<IAppState, 'organization'>,
    pricingEntity: IPricingEntity,
  ) {
    const planActive = organization?.plan.p?.active || false;
    if (!planActive) {
      return undefined;
    }
    const rules = organization?.plan.p?.rules?.[pricingEntity] || null;
    if (rules == null) {
      return undefined;
    }
    return rules.unlimited ? 'unlimited' : rules.quantity;
  }

  public static getPlanLimit(
    { organization }: Pick<IAppState, 'organization'>,
    pricingEntity: IPricingEntity,
  ): Result<Limit> {
    if (!organization.plan.p) {
      return Result.loading();
    }
    if (!organization.plan.p.active) {
      return Result.data(undefined);
    }
    const rules = organization.plan.p.rules[pricingEntity];
    if (!rules) {
      return Result.data(undefined);
    }
    return Result.data(rules.unlimited ? 'unlimited' : rules.quantity);
  }

  public static getRule(
    { organization }: Pick<IAppState, 'organization'>,
    pricingEntity: IPricingEntity,
  ) {
    const planActive = organization.plan.p?.active || false;
    if (!planActive) {
      return undefined;
    }
    const rules = organization.plan.p?.rules?.[pricingEntity] || null;
    if (rules == null) {
      return undefined;
    }
    return rules;
  }

  public static isFeatureDisabled(
    props: Pick<IAppState, 'organization'>,
    pricingEntity: IPricingEntity,
  ) {
    const limit = this.getLimit(props, pricingEntity);
    return limit === 0 || limit === undefined;
  }

  public static isFeatDisabled(limit: Limit): boolean {
    return limit === 0 || limit === undefined;
  }

  public static getMessage(
    limit: number | undefined | 'unlimited',
    entity: IPricingEntity,
    props: Pick<IAppState, 'organization'>,
    renewable?: boolean,
  ) {
    if (!props.organization.plan.p?.active) {
      return 'You do not have an active plan and hence can not use this feature';
    }
    const planText = BillingService.getLimitedPlanName(props);
    const renewText = renewable ? `in a billing month` : '';

    switch (limit) {
      case undefined:
      case 0:
        switch (entity) {
          case 'custom-rbac':
            return `On the ${planText} plan, you do not have access to add/update custom roles`;
          case 'modify-default-rbac':
            return `On the ${planText} plan, you do not have access to modify default roles`;
          case 'roundrobin-escalation':
            return `On the ${planText} plan, you do not have access to advanced Escalation Rule Settings`;
          case 'postmortem-templates':
          case 'status-page':
          case 'runbooks':
          case 'postmortem':
            return `On the ${planText} plan, you do not have access to ${plurals[entity]}`;
          case 'slo':
            return `On the ${planText} plan, you do not have access to SLO feature`;
          case 'export-incident-timeline':
            return `On the ${planText} plan, you do not have access to export ${plurals[entity]}`;
          case 'stakeholder':
            return `On the ${planText} plan, you do not have access to add ${plurals[entity]}`;
          case 'webform':
            return `On the ${planText} plan, you do not have access to Webform feature`;
          case 'merge-incidents':
            return `On the ${planText} plan, you do not have access to Merge Incidents feature`;
          case 'ger':
            return `Upgrade to use Global Event Rulesets`;
          case 'custom-content-templates':
            return `On the ${planText} plan, you do not have access to Custom Content Template feature`;
          case 'workflows':
            return `On the ${planText} plan, you do not have access to Workflows feature`;
          case 'past-incidents':
            return `On the ${planText} plan, you do not have access to Past Incidents feature`;
          case 'auto-pause-transient-alerts':
            return `On the ${planText} plan, you do not have access to Auto Pause Transient Alerts feature`;
          case 'service-graph':
            return `On the ${planText} plan, you do not have access to Service Graph feature`;
          case 'snooze-notifications':
            return `On the ${planText} plan, you do not have access to Snooze Notification feature`;
          case 'delayed-notification':
          case 'additional-responders':
            return `On the ${planText} plan, you do not have access to ${plurals[entity]} feature`;
          case 'audit-logs':
            return `On the ${planText} plan, you do not have access to audit logs feature`;
          case 'incident-summary':
            return `On the ${planText} plan, you do not have access to Incident Summary feature`;
          case 'suggest-runbooks':
            return `On the ${planText} plan, you do not have access to Suggest Runbooks feature`;
          case 'suggest-additional-responders':
            return `On the ${planText} plan, you do not have access to Suggest Additional Responders feature`;
          default:
            return `On the ${planText} plan, you do not have access to ${plurals[entity]}`;
        }
        break;

      case 1:
        switch (entity) {
          case 'teams':
            return `On the ${planText} plan, you do not have access to add more ${plurals[entity]}`;
          default:
            return renewable
              ? `On the ${planText} plan, you have access to add only ${limit} ${plurals[entity]} ${renewText}`
              : `On the ${planText} plan, you do not have access to add more than ${limit} ${plurals[entity]}`;
        }
      default:
        return renewable
          ? `On the ${planText} plan, you have access to add only ${limit} ${plurals[entity]} ${renewText}`
          : `On the ${planText} plan, you do not have access to add more than ${limit} ${plurals[entity]}`;
    }
  }

  public static getHeader(
    limit: number | undefined | 'unlimited',
    entity: IPricingEntity,
    props: Pick<IAppState, 'organization'>,
  ) {
    if (entity === 'export-incident-timeline') {
      return 'Upgrade to Export Timelines';
    }
    switch (limit) {
      case undefined:
      case 0:
        switch (entity) {
          case 'custom-rbac':
            return 'Upgrade to add/update custom-roles ';
          case 'modify-default-rbac':
            return 'Upgrade to modify default-roles';
          case 'roundrobin-escalation':
            return `Upgrade to use advance Escalation Rules`;
          case 'postmortem-templates':
          case 'status-page':
          case 'runbooks':
          case 'postmortem':
          case 'delayed-notification':
          case 'additional-responders':
            return `Upgrade to use ${plurals[entity]}`;
          case 'slo':
            return `Upgrade to use SLOs`;
          case 'webform':
            return `Upgrade to use Webforms`;
          case 'stakeholder':
            return `Upgrade to Add Stakeholder`;
          case 'merge-incidents':
            return `Upgrade to use Merge Incidents`;
          case 'custom-content-templates':
            return 'Upgrade to use Custom Content Templates';
          case 'workflows':
            return 'Upgrade to use Workflows';
          case 'past-incidents':
            return `Upgrade to use Past Incidents`;
          case 'ger':
            return `Upgrade to use Global Event Rulesets`;
          case 'auto-pause-transient-alerts':
            return `Upgrade to use Auto Pause Transient Alerts`;
          case 'service-graph':
            return `Upgrade to use Service Graph`;
          case 'snooze-notifications':
            return 'Upgrade to use Snooze Notifications';
          case 'audit-logs':
            return 'Upgrade to use Audit Logs';
          case 'incident-summary':
            return 'Upgrade to use Incident Summary';
          case 'suggest-runbooks':
            return 'Upgrade to use Runbook Suggestions';
          case 'suggest-additional-responders':
            return 'Upgrade to use Additional Responder Suggestions';
          default:
            return `Upgrade to Add more ${plurals[entity]}`;
        }
        break;

      case 1:
        switch (entity) {
          case 'teams':
            return `Upgrade to Add Teams`;
          default:
            return `Upgrade to Add more ${plurals[entity]}`;
        }
      default:
        return `Upgrade to Add more ${plurals[entity]}`;
    }
  }

  public static isSomeProductTrialPlan(props: Pick<IAppState, 'organization'>) {
    const planSlug = props.organization.plan.p?.plan_slug;
    return (
      planSlug === BillingService.PRODUCT_TRIAL_PLAN_SLUG ||
      planSlug === BillingService.PRODUCT_TRIAL_EXTENDED_PLAN_SLUG
    );
  }

  public static isOnPremiumPlan(props: Pick<IAppState, 'organization'>) {
    const planSlug = props.organization.plan.p?.plan_slug;
    return (
      planSlug === BillingService.PREMIUM_MONTHLY_SLUG ||
      planSlug === BillingService.PREMIUM_ANNUAL_SLUG
    );
  }

  public static isOnEnterprisePlan(props: Pick<IAppState, 'organization'>) {
    const planSlug = props.organization.plan.p?.plan_slug;
    return (
      planSlug === BillingService.ENTERPRISE_MONTHLY_SLUG ||
      planSlug === BillingService.ENTERPRISE_ANNUAL_SLUG
    );
  }

  public static getDiscountInfo = (plan: string) => {
    switch (plan.toLowerCase()) {
      case 'pro':
        return 'Pay annually & save 25%';
      case 'premium':
        return 'Pay annually & save 16%';
      case 'enterprise':
        return 'Pay annually & save 19%';
    }
    return '';
  };

  public static getLimitedPlanName(props: Pick<IAppState, 'organization'>) {
    const planSlug = props.organization.plan.p?.plan_slug;
    if (!planSlug) {
      return 'Current';
    }
    return this.LIMITED_PLANS_DISPLAY_NAME.hasOwnProperty(planSlug)
      ? this.LIMITED_PLANS_DISPLAY_NAME[planSlug]
      : 'Current';
  }

  public static isOnNormalProductTrialPlan(props: Pick<IAppState, 'organization'>) {
    const planSlug = props.organization.plan.p?.plan_slug;
    return planSlug === BillingService.PRODUCT_TRIAL_PLAN_SLUG;
  }

  public static isOnExtendedProductTrialPlan(props: Pick<IAppState, 'organization'>) {
    const planSlug = props.organization.plan.p?.plan_slug;
    return planSlug === BillingService.PRODUCT_TRIAL_EXTENDED_PLAN_SLUG;
  }

  public static isProductTrialExpired(props: Pick<IAppState, 'organization'>) {
    const planActive = props.organization.plan.p?.active;
    return BillingService.isSomeProductTrialPlan(props) && !planActive;
  }

  public static hasManageBillingPermission(props: Pick<IAppState, 'organization'>) {
    const currentUser = props.organization.currentUser.u;
    const currentUserAbilitiesSlug = currentUser?.abilities.map(a => a.slug) || [];
    return currentUser?.role === 'account_owner'
      ? true
      : currentUserAbilitiesSlug.includes('manage-billing');
  }
}

export default BillingService;
