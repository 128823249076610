import React, { useMemo, useEffect, useState, useCallback, useRef } from 'react';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Grid,
  Heading,
  HStack,
  ScaleFade,
  Text,
  VStack,
  Tag,
  TagLabel,
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { generatePath, Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import Header from 'components/chakra/Header';
import Layout from 'components/chakra/Layout';
import Loader from 'components/chakra/Loader';
import SchedulesHeader from '../schedules.view/schedules.header';
import SchedulesInfo from './schedules.info';
import SchedulesView from '../schedules.view';
import ScheduleActions from '../schedules.actions';
import { CalendarViewType } from '../interface/schedule';
import ScheduleOverride from '../common/Override';
import ScheduleConfirmationModal from '../common/ConfirmationModal';
import { SCHEDULES_V2_DETAIL_PATH, SCHEDULES_V2_PATH } from 'views/main/routes/routes';
import { schedulesTextCopy, ScheduleActionTypes } from '../constants/schedules.copy';
import { useScheduleHeaderContext } from '../schedules.view/schedules.header/context';
import { useScheduleActionsContext } from '../schedules.actions/context';
import { getStartEndDateTime } from '../helpers/helpers.date';
import { useWhoIsOncallQuery } from 'views/main/organization/schedules/graphql/query';
import { API } from 'core';
import {
  getOverrideDetail,
  unmountOverrideRoute,
  unmountRemoveOverrideRoute,
  useGetOverrideParams,
} from '../helpers/helpers.override';
import {
  useGetOverrideQuery,
  useGetOverrideParticipantsQuery,
  useGetScheduleQuery,
} from 'views/main/organization/schedules/graphql/query';
import { Schedule } from 'views/main/organization/schedules/graphql/types';
import {
  useSaveOverrideMutation,
  useUpdateOverrideMutation,
  useDeleteOverrideMutation,
} from 'views/main/organization/schedules/graphql/mutation';
import {
  defaultReactQueryConfig,
  reactQueryConfigError,
  reactQueryConfig,
} from '../helpers/helpers.schedule';
import { RotationViewType } from '../constants/schedules.rotation-type';
import { useGetAllPatternParticipants } from '../helpers/helpers.customrotations';
import ScheduleTags from '../schedules.view/schedules.tags';
import { maxEscalationsCount, maxTagsCount } from '../constants/schedules.view';
import ScheduleEscalationPolicies from '../schedules.view/schedules.escalations';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_SCHEDULE_V2_DETAIL_PAGE_VIEWED } from 'core/const/tracker';
import useUINavigationFunctions from '../../navigation-flows/useUINavigationFunctionV2';
import { EntityType } from '../../navigation-flows/helpers';

import { getUserAccessStore, setUserAccessStore } from 'core/userAccess/UserAccessStore';
import { EntityACLMeta } from 'core/userAccess/types';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';
import { truncate } from 'core/helpers/stringUtils';
import { GlobalTeamIDGate } from 'core/hooks/useCompareGlobalAndEntityTeamId';

function ScheduleDetail() {
  const { scheduleId } = useParams<{ scheduleId: string }>();

  const history = useHistory();

  const {
    calendarRef,
    calendarNextWeekRef,
    activeRotViewType,
    activeViewType,
    visibleDates,
    onSyncCalendar,
  } = useScheduleHeaderContext();
  const { onActionClick, openAlertDialog, openEditSchedule, userHasReadPermission } =
    useScheduleActionsContext();
  const {
    overrideModal,
    overrideRotation,
    overrideEventId,
    overrideParticipant,
    overrideStartDate,
    overrideEndDate,
    overrideStartTime,
    overrideEndTime,
    removeOverrideModalOpen,
    removeOverrideId,
    removeOverrideRotation,
  } = useGetOverrideParams();
  const allParticipants = useGetAllPatternParticipants();
  const { getEntityComponent } = useUINavigationFunctions();

  const [selectedInfoDate, setSelectedInfoDate] = useState(new Date());
  const [showGaps, setShowGaps] = useState(true);
  const queryClient = useQueryClient();

  const [startTime, endTime] = useMemo(() => {
    switch (activeViewType.value) {
      case CalendarViewType.dayGridMonth:
        return [
          visibleDates.oneMonthView[0],
          visibleDates.oneMonthView[visibleDates.oneMonthView.length - 1],
        ];
      default:
        return [
          visibleDates.twoWeeksView[0],
          visibleDates.twoWeeksView[visibleDates.twoWeeksView.length - 1],
        ];
    }
  }, [activeViewType, visibleDates]);

  const dayFilterParams = useMemo(() => {
    const selectedDateTime = getStartEndDateTime(selectedInfoDate, 'day');
    return {
      ID: Number(scheduleId),
      eventsFilter: {
        startTime: selectedDateTime.start,
        endTime: selectedDateTime.end,
      },
      from: selectedDateTime.start,
      till: selectedDateTime.end,
    };
  }, [scheduleId, selectedInfoDate]);

  const scheduleFilterParams = useMemo(() => {
    return {
      ID: Number(scheduleId),
      eventsFilter: {
        startTime: startTime.toJSDate(),
        endTime: endTime.endOf('day').toUTC(),
      },
      from: startTime.toJSDate(),
      till: endTime.endOf('day').toUTC(),
    };
  }, [scheduleId, startTime, endTime]);

  const onToggleGaps = () => setShowGaps(oldState => !oldState);

  const {
    data: { schedule: scheduleInfo } = {},
    isLoading,
    isSuccess,
  } = useGetScheduleQuery(scheduleFilterParams, {
    ...defaultReactQueryConfig,
    refetchOnMount: true,
    onError: reactQueryConfigError('Get Schedule'),
    onSuccess: data => {
      const acl: Record<string, EntityACLMeta> = {};
      acl[data?.schedule?.ID ?? ''] = {
        has_delete_access: data.schedule?.acl?.hasDeleteAccess ?? false,
        has_update_access: data.schedule?.acl?.hasUpdateAccess ?? false,
        has_update_owner_access: data.schedule?.acl?.hasUpdateOwnerAccess ?? false,
      };
      setUserAccessStore({
        entityACLMap: {
          ...getUserAccessStore().entityACLMap,
          schedules: {
            ...getUserAccessStore().entityACLMap?.schedules,
            ...acl,
          },
        },
      });
      AppTracker.track(T_WA_UP_SCHEDULE_V2_DETAIL_PAGE_VIEWED);
    },
  });
  const { data: { schedule: scheduleDayInfo } = {} } = useGetScheduleQuery(dayFilterParams, {
    ...defaultReactQueryConfig,
    refetchOnMount: true,
    onError: reactQueryConfigError('Get Schedule'),
  });

  const onCallSchedules = useMemo(
    () => (scheduleInfo?.ID ? [scheduleInfo.ID] : []),
    [scheduleInfo],
  );
  const { data: onCallUsers } = useWhoIsOncallQuery(
    {
      filters: {
        teamID: API.config.teamId,
        ScheduleIDs: onCallSchedules,
        youAndYourSquads: false,
      },
    },
    {
      enabled: !!onCallSchedules.length,
    },
  );
  const onCallUserName = useMemo(
    () =>
      !scheduleInfo?.paused
        ? Array.from(new Set(onCallUsers?.whoIsOncall?.[0]?.oncallParticipants))
        : [],
    [onCallUsers, allParticipants],
  );

  const tags = scheduleInfo?.tags?.filter(tag => !!tag.key && !!tag.value);
  const escalationPolicies = scheduleInfo?.escalationPolicies?.filter(ep => !!ep.name);

  const { data: { schedule: scheduleOverrideInfo } = {} } = useGetOverrideParticipantsQuery({
    ID: Number.parseInt(scheduleId),
    from: getStartEndDateTime(selectedInfoDate, 'day').start,
    till: getStartEndDateTime(selectedInfoDate, 'day').end,
  });

  const overrideParticipantsInfo = useMemo(
    () => scheduleOverrideInfo?.overrides,
    [scheduleOverrideInfo],
  );

  const { data: overrideInfo } = useGetOverrideQuery(
    { ID: Number(overrideEventId) },
    { enabled: !!overrideEventId },
  );

  const openRemoveOverrideModal = useMemo(() => {
    return removeOverrideModalOpen && !!removeOverrideId && !!removeOverrideRotation;
  }, [removeOverrideModalOpen, removeOverrideId, removeOverrideRotation]);

  const overrideDetail = useMemo(() => {
    return getOverrideDetail({
      schedules: [scheduleInfo as Schedule],
      overrideInfo,
      overrideModal,
      overrideRotation,
      overrideEventId,
      overrideParticipant: overrideParticipant ?? ([] as string[]),
      overrideStartDate,
      overrideEndDate,
      overrideStartTime,
      overrideEndTime,
    });
  }, [
    scheduleInfo,
    overrideInfo,
    overrideModal,
    overrideRotation,
    overrideEventId,
    overrideParticipant,
    overrideStartDate,
    overrideEndDate,
    overrideStartTime,
    overrideEndTime,
  ]);

  const onCloseOverride = useCallback(() => {
    history.push(unmountOverrideRoute(history.location.pathname, history.location.search));
  }, [history]);

  const onRemoveOverride = useCallback(async () => {
    if (removeOverrideId) {
      try {
        await deleteOverride({ id: Number(removeOverrideId) });
        onCloseRemoveOverride();
      } catch (error) {}
    }
  }, [removeOverrideId]);

  const onCloseRemoveOverride = useCallback(() => {
    history.push(unmountRemoveOverrideRoute(history.location.pathname, history.location.search));
  }, [history]);

  const { mutateAsync: saveOverride, isLoading: isSavingOverride } = useSaveOverrideMutation({
    ...reactQueryConfig.mutation.createOverride,
    onSuccess: () => {
      queryClient.invalidateQueries('getSchedule');
      queryClient.invalidateQueries('whoIsOncall');

      queryClient.invalidateQueries('getOverrideParticipants');
      reactQueryConfig.mutation.createOverride.onSuccess();
    },
  });
  const { mutateAsync: updateOverride, isLoading: isUpdatingOverride } = useUpdateOverrideMutation({
    ...reactQueryConfig.mutation.updateOverride,
    onSuccess: () => {
      queryClient.invalidateQueries('getSchedule');
      queryClient.invalidateQueries('whoIsOncall');

      queryClient.invalidateQueries('getOverrideParticipants');
      reactQueryConfig.mutation.updateOverride.onSuccess();
    },
  });
  const { mutateAsync: deleteOverride, isLoading: isDeletingOverride } = useDeleteOverrideMutation({
    ...reactQueryConfig.mutation.deleteOverride,
    onSuccess: () => {
      queryClient.invalidateQueries('getSchedule');
      queryClient.invalidateQueries('whoIsOncall');

      queryClient.invalidateQueries('getOverrideParticipants');
      reactQueryConfig.mutation.deleteOverride.onSuccess();
    },
  });

  const hasUpdateAccess = useUserAccess().hasUpdateAccess;
  const hasDeleteAccess = useUserAccess().hasDeleteAccess;

  const hasUpdate = hasUpdateAccess('schedules', scheduleId);

  const hasDelete = hasDeleteAccess('schedules', scheduleId);

  const modifiedScheduleInfo = useMemo(() => {
    if (!scheduleInfo) return {};

    return {
      ...scheduleInfo,
      gaps: [RotationViewType.gaps, RotationViewType.finalRotation].includes(
        activeRotViewType.value,
      )
        ? scheduleInfo?.gaps
        : [...(showGaps ? scheduleDayInfo?.gaps ?? [] : [])],
    };
  }, [scheduleInfo, scheduleDayInfo, activeViewType, activeRotViewType, showGaps]);

  if (!userHasReadPermission) {
    return (
      <HStack justifyContent="center" mt={5}>
        <Text>You do not have permission to view Schedules!</Text>
      </HStack>
    );
  }

  if (!isLoading && !scheduleInfo) {
    return (
      <HStack justifyContent="center" mt={5}>
        <Text>The schedule information could not be displayed!</Text>
      </HStack>
    );
  }

  if (isLoading || scheduleInfo) {
    return (
      <Box>
        <Layout height={170}>
          <Loader.Spinner
            isLoading={isLoading}
            loadingMessage="Initializing Schedules ..."
            centered
            spinnerProps={{ size: 'lg' }}
          >
            {scheduleInfo && (
              <>
                <GlobalTeamIDGate entityTeamId={scheduleInfo.teamID} />
                <Header
                  paddingY={4}
                  title={
                    <VStack alignItems="flex-start">
                      <Breadcrumb spacing={2} separator={<ChevronRightIcon color="gray.500" />}>
                        <BreadcrumbItem>
                          <BreadcrumbLink
                            as={Link}
                            to={SCHEDULES_V2_PATH + `?view=${CalendarViewType.twoGridWeek}`}
                          >
                            Schedules
                          </BreadcrumbLink>
                        </BreadcrumbItem>

                        <BreadcrumbItem
                          as={Link}
                          to={generatePath(SCHEDULES_V2_DETAIL_PATH, { scheduleId })}
                          isCurrentPage
                        >
                          <BreadcrumbLink>{scheduleInfo.name}</BreadcrumbLink>
                        </BreadcrumbItem>
                      </Breadcrumb>

                      <Box>
                        <VStack alignItems="flex-start">
                          <Heading as="h3" variant="h3">
                            {scheduleInfo.name}
                            {scheduleInfo.paused && (
                              <Tag size="md" m={2.5}>
                                <TagLabel fontWeight="normal">Paused</TagLabel>
                              </Tag>
                            )}
                          </Heading>
                          <ScheduleTags tags={tags ?? []} maxTagsCount={maxTagsCount.detail} />
                        </VStack>
                        <Text mt={2}>{scheduleInfo.description}</Text>
                      </Box>
                    </VStack>
                  }
                  actions={
                    <HStack>
                      <NoPermissionTooltip isDisabled={hasDelete}>
                        <Button
                          variant="outlineBlue"
                          onClick={() => {
                            scheduleInfo &&
                              onActionClick({
                                type: ScheduleActionTypes.DELETE,
                                params: {
                                  scheduleId: scheduleInfo.ID,
                                  scheduleName: scheduleInfo.name,
                                },
                              });
                            openAlertDialog();
                          }}
                          isDisabled={!hasDelete}
                        >
                          {schedulesTextCopy.detail.remove}
                        </Button>
                      </NoPermissionTooltip>
                      <NoPermissionTooltip isDisabled={hasUpdate}>
                        <Button
                          onClick={() => {
                            scheduleInfo &&
                              onActionClick({
                                type: ScheduleActionTypes.EDIT_SCHEDULE,
                                params: {
                                  scheduleId: scheduleInfo.ID,
                                  scheduleName: scheduleInfo.name,
                                },
                              });
                            openEditSchedule();
                          }}
                          isDisabled={!hasUpdate || !!scheduleInfo.paused}
                        >
                          {schedulesTextCopy.detail.edit}
                        </Button>
                      </NoPermissionTooltip>
                    </HStack>
                  }
                />

                <Header
                  paddingY={2}
                  title={
                    <HStack alignItems="center" gap={0.2}>
                      <Text fontSize={14} fontWeight={800} lineHeight="21px">
                        Used in Escalation Policy:
                      </Text>
                      {escalationPolicies && escalationPolicies.length > 0 ? (
                        <ScheduleEscalationPolicies
                          escalationPolicies={escalationPolicies}
                          maxEscalationsCount={maxEscalationsCount.details}
                          fontSize={14}
                          fontWeight={800}
                        />
                      ) : (
                        <Text fontSize={14} fontWeight={800} lineHeight="21px">
                          None
                        </Text>
                      )}
                      <Text fontSize="x-small">|</Text>
                      <Text fontSize={14} fontWeight={800} lineHeight="21px">
                        Currently On Call:
                      </Text>

                      {onCallUserName?.map((user, index) => {
                        const participantComponent = (
                          <Text
                            fontSize={'14px'}
                            fontWeight={user.type === 'squad' ? 500 : 900}
                            color={'#3D6DD8'}
                            _hover={{ cursor: 'pointer' }}
                            maxW={250}
                          >
                            {onCallUserName.length === 1
                              ? truncate(user.participant.name, 20)
                              : truncate(user.participant.name, 20) +
                                (index + 1 === onCallUserName.length ? '' : ', ')}
                          </Text>
                        );

                        const participantComponentWithLinkStyles = (
                          <Text
                            fontSize={'14px'}
                            fontWeight={user.type === 'squad' ? 500 : 900}
                            color={'#3D6DD8'}
                            _hover={{ cursor: 'pointer' }}
                            maxW={250}
                          >
                            {onCallUserName.length === 1
                              ? truncate(user.participant.name, 20)
                              : truncate(user.participant.name, 20) +
                                (index + 1 === onCallUserName.length ? '' : ', ')}
                          </Text>
                        );
                        let nameComponent = participantComponent;
                        nameComponent = getEntityComponent({
                          componentType: user.type === 'squad' ? 'linkWOPopover' : 'linkWPopover',
                          type: user.type as EntityType,
                          id: user.ID,
                          popoverTrigger: participantComponentWithLinkStyles,
                          popoverPlacement: 'left-start',
                        });
                        return <React.Fragment key={user.ID}>{nameComponent}</React.Fragment>;
                      })}
                    </HStack>
                  }
                  actions={
                    <HStack gap={1}>
                      <ScheduleActions schedule={scheduleInfo} isScheduleDetailsView={true} />
                    </HStack>
                  }
                />
              </>
            )}
          </Loader.Spinner>
        </Layout>

        <Layout height="calc(100vh - 350px)" py={0}>
          <Box my={2}>
            <ScaleFade initialScale={0.9} in={isSuccess}>
              {isSuccess && (
                <VStack h="inherit">
                  <SchedulesHeader enableRotationSelection />

                  <Grid templateColumns="3.25fr 1.25fr" gap={4} bg="gray.200" w="100%" h="inherit">
                    <SchedulesView
                      calendarRef={calendarRef}
                      calendarNextWeekRef={calendarNextWeekRef}
                      calendarView={activeViewType.value}
                      rotationViewType={activeRotViewType.value}
                      schedules={modifiedScheduleInfo ? [modifiedScheduleInfo as Schedule] : []}
                      visibleDates={visibleDates.twoWeeksView}
                      height="calc(100vh - 350px)"
                      isScheduleDetailsView
                      gaps={{
                        all: scheduleInfo?.gaps ?? [],
                        day: scheduleDayInfo?.gaps ?? [],
                      }}
                      showGaps={showGaps}
                      onSyncCalendar={onSyncCalendar}
                    />

                    <Box height="calc(100vh - 350px)" overflowY="auto" bg="white">
                      <SchedulesInfo
                        schedule={scheduleDayInfo ?? null}
                        showGaps={showGaps}
                        onToggleGaps={onToggleGaps}
                        selectedDate={selectedInfoDate}
                        onDateSelect={date => setSelectedInfoDate(date)}
                        overrideParticipants={overrideParticipantsInfo}
                      />
                    </Box>
                  </Grid>
                </VStack>
              )}
            </ScaleFade>
          </Box>
        </Layout>

        <ScheduleOverride
          isOpen={overrideModal}
          onClose={onCloseOverride}
          overrideDetail={overrideDetail}
          isLoading={isLoading}
          updateOverrideId={overrideEventId}
          isSavingOverride={isSavingOverride || isUpdatingOverride}
          onSave={saveOverride}
          onUpdate={updateOverride}
        />

        <ScheduleConfirmationModal
          isOpen={openRemoveOverrideModal}
          isButtonLoading={isDeletingOverride}
          onClose={onCloseRemoveOverride}
          onConfirm={onRemoveOverride}
          title={schedulesTextCopy.override.remove.title}
          body={schedulesTextCopy.override.remove.body}
        />
      </Box>
    );
  }

  return <></>;
}

export default ScheduleDetail;
