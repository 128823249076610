// Activity types
export const TASK = 'TASK';
export const RUNBOOK = 'RUNBOOK';
export const RUNBOOK_STEP = 'RUNBOOK_STEP';

// Task And Runbook activity Actions
export const TASK_UPDATE = 'task_updated';
export const TASK_ADDED = 'task_added';
export const TASK_CHECKED = 'task_checked';
export const TASK_DELETED = 'task_deleted';
export const TASK_UNCHECKED = 'task_unchecked';
export const RUNBOOK_ADDED = 'runbook_attached';
export const RUNBOOK_CHECKED = 'runbook_step_checked';
export const RUNBOOK_UNCHECKED = 'runbook_step_unchecked';
export const RUNBOOK_DELETED = 'runbook_detached';

// Event action types grouping
export const TASK_EVENTS = [TASK_UPDATE, TASK_ADDED, TASK_CHECKED, TASK_DELETED, TASK_UNCHECKED];
export const RUNBOOK_EVENTS = [RUNBOOK_ADDED, RUNBOOK_CHECKED, RUNBOOK_UNCHECKED, RUNBOOK_DELETED];
export const ADD_OR_UPDATE_EVENTS = [TASK_UPDATE, TASK_ADDED, RUNBOOK_ADDED];
export const CHECK_EVENTS = [TASK_CHECKED, TASK_UNCHECKED, RUNBOOK_UNCHECKED, RUNBOOK_CHECKED];
export const DELETE_EVENTS = [TASK_DELETED, RUNBOOK_DELETED];
export const taskAndRunbookEventList = [...TASK_EVENTS, ...RUNBOOK_EVENTS];

export const TASK_RUNBOOK_SPLIT_WORDS = {
  [RUNBOOK_ADDED]: ' attached ',
  [RUNBOOK_DELETED]: ' detached ',
  [TASK_ADDED]: ' added ',
  [TASK_UPDATE]: ' updated ',
  [TASK_DELETED]: ' deleted ',
  [RUNBOOK_CHECKED]: ' checked ',
  [RUNBOOK_UNCHECKED]: ' unchecked ',
  [TASK_CHECKED]: ' checked ',
  [TASK_UNCHECKED]: ' unchecked ',
};

// Table header
export const runbookListTable = [
  { name: '', size: '5%' },
  { name: 'Runbook Name', size: '20%' },
  { name: 'Created At', size: '20%' },
  { name: 'Created By', size: '12%' },
  { name: 'Last Modified At', size: '20%' },
  { name: 'Last Modified By', size: '13%' },
  { name: 'Instances Used', size: '10%' },
];

export const DateFormats = {
  create_task_runbook: 'MMM DD YYYY h:mm:ss A',
};

export const componentState = {
  BUSY: 'BUSY',
  DONE: 'DONE',
};

export const CHECK = 'CHECK';
export const CONTENT = 'CONTENT';

export const snackMsg = {
  default: 'Something went wrong please try again',
  ADD_TASK: 'Error occured while adding the task',
  UPDATE_TASK: 'Error while updating the task',
  DELETE_TASK: 'Error while deleting the task',
  TASK_SHOULD_NOT_BE_EMPTY: 'Task should not be empty',
  ADD_RUNBOOK: 'Error occured while attaching the runbook',
  UPDATE_RUNBOOK: 'Error while updating the runbook',
  DELETE_RUNBOOK: 'Error while deleting the runbook',
  default_SUCCESS: 'Something went wrong please try again',
  ADD_TASK_SUCCESS: 'Task has been added Successfully!',
  UPDATE_TASK_SUCCESS: 'Task has been updated Successfully!',
  DELETE_TASK_SUCCESS: 'Task has been deleted Successfully!',
  ADD_RUNBOOK_SUCCESS: 'Runbook has been added Successfully!',
  UPDATE_RUNBOOK_SUCCESS: 'Error while updating the runbook',
  DELETE_RUNBOOK_SUCCESS: 'Runbook has been deleted Successfully!',
  fetchRunbookError: 'Error while fetching the runbook',
  fetchSuggestedRunbooksError: 'Error while fetching suggested runbooks',
};

export const EXPANDED = 'expanded';
export const COLLAPSED = 'collapsed';

export const YES = 'YES';
export const NO = 'NO';
