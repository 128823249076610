import React, { useState, useEffect, useRef } from 'react';
import { Grid, Theme, SnackContext, ContainerLoad } from 'uie/components';
import { IPatchReqRunbook } from '../../../../../../../core/interfaces/ITask';
import { IRunbook, ISuggestedRunbook } from '../../../../../../../core/interfaces/IRunBook';
import { IAppState } from '../../../../../../../core/interfaces/IAppState';
import { connect } from 'react-redux';
import { BillingService, TaskAndRunbookService } from '../../../../../../../core/services';

import { requestOrganizationRunbook } from '../../../../../../../core/actions';
import { requestIncidentDetailRunbook } from 'core/actions';
import { RUNBOOK, snackMsg } from '../taskAndRunbook.constants';
import { RunbookButtons } from '../addTaskOrRunbookbuttons';
import SnackBarComp from '../../../../runbooks/common/snackbar.component';
import RunbookModal from './RunbookModal';
import RunbookList from './RunbookList';
import { isAxiosError } from '../taskOrRunbook.helper';
import { AppTracker } from '../../../../../../../shared/analytics/tracker';
import { T_WA_GS_RUNBOOK_ATTACHED } from '../../../../../../../core/const/tracker';
import { T_WA_GS_ATTACHED_RUNBOOK_REMOVED } from '../../../../../../../core/const/tracker';

import { RunbookDeleteModal } from '../deleteModal';
import NoSuggestedRunbooksModal from './NoSuggestedRunbooksModal';

interface IProps_ extends Pick<IAppState, 'organization' | 'APP_CONFIG' | 'incidentRunbooks'> {
  incidentId: string;
  requestOrganizationRunbook: typeof requestOrganizationRunbook;
  requestIncidentDetailRunbook: typeof requestIncidentDetailRunbook;
  focusMessageId: { id: string; type?: string; index?: number };
  currentTab: string;
  getIncidentDetails: () => void;
  isSuppressedIncident: boolean;
}

export type RunbookAttachMode = 'all' | 'suggested';

const RunbookTab = ({ ...props }: IProps_) => {
  const { theme } = Theme;
  const taskRefs = useRef<Array<any>>([]);
  taskRefs.current = [];
  const { sidebarState } = props.APP_CONFIG;
  /** START : Use ref list for task and runbook elment scroll from activity timeline action */

  const runbookRefs = useRef<Array<HTMLDivElement>>([]);
  runbookRefs.current = [];

  const [enableDelete, setEnableDelete] = useState(-1);

  const [deleteModal, setEnableDeleteModal] = useState<boolean>(false);

  const [deleteRunbookId, setDeleteRunbookId] = useState<string>('');

  const [enableRunbookModal, setEnableRunbookModal] = useState<boolean>(false);
  const [collapseRunbook, setRunbookCollapse] = useState<boolean>(true);

  const [collapseRunbookStep, setRunbookStepCollapse] = useState<Array<number>>([]);

  const [organizationrunbookList, setOrganizationRunbookList] = useState<Array<IRunbook>>([]);
  const [suggestedRunbookList, setSuggestedRunbookList] = useState<Array<ISuggestedRunbook>>([]);

  const [selectedRunbooksList, setSelectedRunbooksList] = useState<Array<IRunbook>>([]);

  const [focusID, setFocusId] = useState<string>('');

  const [runbookAttachMode, setRunbookAttachMode] = useState<RunbookAttachMode>('all');

  const _taskAndRunbookService = new TaskAndRunbookService(props.incidentId);

  const incidentrunbookList = props.incidentRunbooks.runbook;
  const isSuppressedIncident = props.isSuppressedIncident;

  const focusMessage = (runbookIs: string, type: string, index: number | undefined) => {
    const runbookIndex = incidentrunbookList.findIndex(
      _ => _.id == runbookIs || _.runbook_id == runbookIs,
    );
    const ref = runbookRefs.current[runbookIndex];
    if (ref) {
      ref.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });

      ref.style.backgroundColor = theme.primary.light;
      setTimeout(() => {
        ref.style.backgroundColor = '';
        setFocusId(props.focusMessageId.id);
      }, 5000);
    }
  };

  const _createSnack = SnackContext();
  const [showSnack, setShowSnack] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [background, setBackground] = useState<string>('');

  const hideSnackBar = () => {
    setShowSnack(false);
    setMessage('');
  };

  const showSnackBar = (msg: string, bg: string, timeout: number) => {
    setMessage(msg);
    setBackground(bg);
    setShowSnack(true);
    setTimeout(hideSnackBar, timeout);
  };

  const errorMsg = (err: any, defaultMsg: string) => {
    if (isAxiosError(err)) {
      const errMsg = err?.response?.data?.meta?.error_message ?? defaultMsg;
      showSnackBar(errMsg, theme.danger.default, 8000);
    } else {
      showSnackBar(defaultMsg, theme.danger.default, 8000);
    }
  };

  const fetchOrganizationRunbook = async () => {
    try {
      const {
        data: { data },
      } = await _taskAndRunbookService.fetchOrganizationRunbook();
      data.sort((a: IRunbook, b: IRunbook) => {
        return a.name.localeCompare(b.name);
      }),
        setOrganizationRunbookList(data);
    } catch (err: any) {
      errorMsg(err, snackMsg.fetchRunbookError);
    }
  };

  const fetchSuggestedRunbooks = async () => {
    const canUseSuggestedRunbooks =
      BillingService.getLimit({ organization: props.organization }, 'suggest-runbooks') ===
      'unlimited';
    if (canUseSuggestedRunbooks) {
      getSuggestedRunbooksFromAPI();
    }
  };

  const getSuggestedRunbooksFromAPI = async () => {
    try {
      const {
        data: { data },
      } = await _taskAndRunbookService.fetchSuggestedRunbooksByIncidentId(props.incidentId);
      setSuggestedRunbookList(data);
    } catch (err: any) {
      errorMsg(err, snackMsg.fetchSuggestedRunbooksError);
    }
  };

  const AddRunbook = async () => {
    const runbookId = selectedRunbooksList.map((runbook: IRunbook) => runbook!.id || '');

    try {
      await _taskAndRunbookService.addRunbook({ runbooks: runbookId });
      showSnackBar(snackMsg.ADD_RUNBOOK_SUCCESS, theme.success.default, 8000);
      const includesSuggestedRunbook =
        runbookAttachMode === 'suggested' &&
        suggestedRunbookList.some(
          (runbook: ISuggestedRunbook) => runbook.id && runbookId.includes(runbook.id),
        );
      AppTracker.track(T_WA_GS_RUNBOOK_ATTACHED, {
        'Incident ID': props.incidentId,
        'Runbook ID': runbookId,
        'Is Suggested': includesSuggestedRunbook,
      });
    } catch (err: any) {
      errorMsg(err, snackMsg.ADD_RUNBOOK);
    } finally {
      setEnableRunbookModal(false);
      props.requestIncidentDetailRunbook(props.incidentId);
      setSelectedRunbooksList([]);
      fetchOrganizationRunbook();
      fetchSuggestedRunbooks();
      props.getIncidentDetails();
    }
  };

  const updateRunbook = async (runbookId: string, step: IPatchReqRunbook) => {
    try {
      await _taskAndRunbookService.updateRunbook(step, runbookId);
      showSnackBar(
        `Runbook has been marked ${step.completed ? 'complete' : 'Incomplete'} Successfully!`,
        theme.success.default,
        3000,
      );
    } catch (err: any) {
      errorMsg(err, snackMsg.UPDATE_RUNBOOK);
    } finally {
      props.requestIncidentDetailRunbook(props.incidentId);
      props.getIncidentDetails();
    }
  };

  const deleteRunbook = async (runbook_id: string) => {
    try {
      await _taskAndRunbookService.deleteRunbook(runbook_id);
      AppTracker.track(T_WA_GS_ATTACHED_RUNBOOK_REMOVED, {});
      showSnackBar(snackMsg.DELETE_RUNBOOK_SUCCESS, theme.success.default, 3000);
    } catch (err: any) {
      errorMsg(err, snackMsg.DELETE_RUNBOOK);
    } finally {
      props.requestIncidentDetailRunbook(props.incidentId);
      setEnableDeleteModal(false);
      setDeleteRunbookId('');
      fetchOrganizationRunbook();
      fetchSuggestedRunbooks();
      props.getIncidentDetails();
    }
  };

  useEffect(() => {
    if (runbookRefs.current) {
      if (props.focusMessageId.id !== focusID) {
        if (props.focusMessageId.type === RUNBOOK) {
          if (typeof props.focusMessageId.id !== 'undefined') {
            focusMessage(
              props.focusMessageId.id,
              props.focusMessageId.type,
              props.focusMessageId.index,
            );
          }
        }
      }
    }
  });
  useEffect(() => {
    if (showSnack) {
      _createSnack(
        <SnackBarComp message={message} background={background} hideSnackBar={hideSnackBar} />,
      );
    } else {
      _createSnack(null);
    }
  });
  useEffect(() => {
    fetchOrganizationRunbook();
    fetchSuggestedRunbooks();
  }, []);

  return (
    <>
      <Grid
        width={'100%'}
        alignItems="flex-start"
        type="column"
        justifyContent="flex-start"
        style={{ paddingRight: '20px' }}
      >
        {props.incidentRunbooks.loading && (
          <div style={{ width: '100%' }}>
            <ContainerLoad isLoading={true} color={theme.primary.default} />
          </div>
        )}
        <RunbookButtons
          setSelectedRunbooksList={(list: Array<IRunbook>) => setSelectedRunbooksList(list)}
          setEnableRunbookModal={(enable: boolean) => setEnableRunbookModal(enable)}
          isSuppressedIncident={isSuppressedIncident}
          setRunbookAttachMode={setRunbookAttachMode}
          hasRunbooksToSuggest={suggestedRunbookList.length > 0}
          incidentId={props.incidentId}
        />
        <RunbookList
          collapseRunbook={collapseRunbook}
          collapseRunbookStep={collapseRunbookStep}
          enbleDelete={enableDelete}
          incidentrunbookList={incidentrunbookList}
          setDeleteRunbookId={(id: string) => setDeleteRunbookId(id)}
          setEnableDelete={setEnableDelete}
          setEnableDeleteModal={setEnableDeleteModal}
          setRunbookCollapse={setRunbookCollapse}
          setRunbookStepCollapse={setRunbookStepCollapse}
          sidebarState={sidebarState}
          updateRunbook={updateRunbook}
          ref={runbookRefs}
          isSuppressedIncident={isSuppressedIncident}
        />
      </Grid>
      <NoSuggestedRunbooksModal
        isOpen={
          enableRunbookModal &&
          runbookAttachMode === 'suggested' &&
          suggestedRunbookList.length === 0
        }
        onClose={() => setEnableRunbookModal(false)}
        onConfirm={() => setRunbookAttachMode('all')}
      />
      <RunbookModal
        incidentId={props.incidentId}
        organizationrunbookList={organizationrunbookList}
        enableRunbookModal={enableRunbookModal}
        setEnableModal={setEnableRunbookModal}
        selectedRunbooksList={selectedRunbooksList}
        setSelectedRunbooksList={setSelectedRunbooksList}
        AddRunbook={AddRunbook}
        isSuggestedMode={runbookAttachMode === 'suggested'}
        suggestedRunbookList={suggestedRunbookList}
        setRunbookAttachMode={setRunbookAttachMode}
      />
      <RunbookDeleteModal
        action={deleteRunbook}
        deleteRunbookId={deleteRunbookId}
        setDeleteRunbookId={setDeleteRunbookId}
        deleteModal={deleteModal}
        setEnableDeleteModal={setEnableDeleteModal}
      />
    </>
  );
};

export default connect(
  ({ organization, APP_CONFIG, incidentRunbooks }: IAppState) => ({
    organization,
    incidentRunbooks,
    APP_CONFIG,
  }),
  { requestOrganizationRunbook, requestIncidentDetailRunbook },
)(React.memo(RunbookTab));
