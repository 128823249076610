import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import RunbookList from './runbook.listComponent';
import RunbookCreateOrUpdate from './runbook.createOrUpdateComponent';
import { IAppState } from '../../../../core/interfaces/IAppState';
import { RouteChildrenProps } from 'react-router-dom';
import { requestOrganizationRunbook } from '../../../../core/actions';
import { SnackContext } from 'uie/components';
import { runbook_index, RUNBOOKS } from './runbooks.constant';
import { AppTracker } from '../../../../shared/analytics/tracker';
import { T_WA_GS_RUNBOOK_VIEWED } from '../../../../core/const/tracker';
import SnackBarComp from './common/snackbar.component';
import UpgradePlanModal from 'components/upgradeplan.modal';
import { BillingService } from 'core';
import { userunbookOwnerFilter, OwnerFilter } from './runbookHOC/RunbookOwnerFilter';
import { ITeam } from 'core/interfaces/ITeams';
import { useOrganizationConfig } from '../schedules';
import { ChakraProvider } from '@chakra-ui/react';
import libraryTheme from 'library/theme';
type IMix = Pick<IAppState, 'organization'> & RouteChildrenProps<{ id: string; mode: string }>;

interface IProps extends IMix {
  requestOrganizationRunbook: typeof requestOrganizationRunbook;
  ownerFilter: OwnerFilter;
  filterQueryParam?: string;
}

export const RenderForNoRunbook = () => {
  return (
    <div className="runbook_no_runbook_container">
      <div className="runbook_txt_center">
        <img
          src="/icons/empty-states/empty-runbook.svg"
          alt="runbooks"
          className="runbook_display_blk"
        />
        <p className="empty-state-headline">Access required!</p>
      </div>
    </div>
  );
};

const Runbooks = (props: IProps) => {
  const getPathParams = (): { id: string; mode: string } => {
    const { match } = props;
    const isMatchCorrect = match !== null && typeof match.params !== 'undefined';
    const id = (isMatchCorrect && match.params.id) || '';
    const mode = (isMatchCorrect && match.params.mode) || '';
    return { id, mode };
  };

  const prevFilterQueryParam = useRef(props.filterQueryParam);

  const _createSnack = SnackContext();
  const [showSnack, setShowSnack] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [background, setBackground] = useState<string>('');
  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);
  const [planLimitExceeded, setPlanLimitExceeded] = useState<boolean>(false);
  const {
    organization: {
      selectedTeam: { action: selectedTeamAction },
    },
  } = useOrganizationConfig();
  const updatePlanLimitExceeded = () => {
    setPlanLimitExceeded(BillingService.isFeatureDisabled(props, 'runbooks'));
  };

  useEffect(() => {
    updatePlanLimitExceeded();
    AppTracker.track(T_WA_GS_RUNBOOK_VIEWED);
  }, []);

  useEffect(() => {
    setShowUpgradeModal(planLimitExceeded);
  }, [planLimitExceeded]);

  const {
    organization: {
      runbooks: { runbook, action },
    },
    location,
  } = props;

  const { pathname } = location;
  const { id, mode } = getPathParams();

  const isCreateOrUpdateRunbook =
    pathname.includes(runbook_index.CREATE) || pathname.includes(runbook_index.UPDATE);

  useEffect(() => {
    //it only triggers redux action if filter query is changed,
    // if filter query is removed after adding it will become empty string again causing the condition to fail and wont get all unfiltered data

    if (props.filterQueryParam !== prevFilterQueryParam.current) {
      props.requestOrganizationRunbook({ entity_owner: props.filterQueryParam });
      prevFilterQueryParam.current = props.filterQueryParam;
    }
  }, [props.filterQueryParam]);

  useEffect(() => {
    if (!showSnack) {
      _createSnack(null);
    } else {
      _createSnack(
        <SnackBarComp message={message} background={background} hideSnackBar={hideSnackBar} />,
      );
    }
    return () => _createSnack(null);
  });

  const hideSnackBar = () => {
    setShowSnack(false);
    setMessage('');
  };

  const showSnackBar = (msg: string, bg: string, timeout: number) => {
    setMessage(msg);
    setBackground(bg);
    setShowSnack(true);
    setTimeout(hideSnackBar, timeout);
  };

  const routeToRunBookList = () => {
    const { requestOrganizationRunbook, history, filterQueryParam } = props;

    setTimeout(() => {
      history.push('/runbooks');
    }, 1000);

    requestOrganizationRunbook({ entity_owner: filterQueryParam });
  };

  return (
    <ChakraProvider theme={libraryTheme}>
      <div>
        {!isCreateOrUpdateRunbook && (
          <RunbookList
            dataState={action}
            ownerFilter={props.ownerFilter}
            filterQueryParam={props.filterQueryParam || ''}
            runbooks={runbook}
            callParentComp={routeToRunBookList}
            showSnackBar={showSnackBar}
          />
        )}
        {isCreateOrUpdateRunbook && (
          <RunbookCreateOrUpdate
            mode={mode}
            runbookId={id}
            pathname={pathname}
            callParentComp={routeToRunBookList}
            teamId={props.organization.selectedTeam.teamId}
            showSnackBar={showSnackBar}
            currentTeam={props.organization?.selectedTeam?.team as ITeam}
            squads={props.organization.squads.s}
            users={props.organization.users.u}
            entityOwner={{
              id: props.organization.currentUser.u?.id ?? '',
              type: 'user',
            }}
          />
        )}
        <UpgradePlanModal
          hasBillingPermission={BillingService.hasManageBillingPermission(props)}
          showModal={showUpgradeModal}
          message={BillingService.getMessage(0, 'runbooks', props)}
          onCancel={() => {
            setShowUpgradeModal(false);
            (props as any).history.goBack();
          }}
          header={BillingService.getHeader(0, 'runbooks', props)}
          onUpgrade={() => {
            setShowUpgradeModal(false);
          }}
        />{' '}
      </div>
    </ChakraProvider>
  );
};

export default connect(
  ({ organization }: IAppState) => ({
    organization,
  }),
  { requestOrganizationRunbook },
)(userunbookOwnerFilter(Runbooks));
