import '@fullcalendar/react/dist/vdom'; // solves problem with Vite
import FullCalendar from '@fullcalendar/react';
import { DateTime } from 'luxon';
import React, {
  ChangeEvent,
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import { RotationViewType, rotationViewType } from '../../constants/schedules.rotation-type';
import {
  getDateTime,
  getOneMonthDateRange,
  getOneWeekDateRange,
  getTwoWeekDateRange,
} from '../../helpers/helpers.date';
import { CalendarViewType } from '../../interface/schedule';
import { useOrganizationConfig } from '../..';

export type ScheduleHeaderProviderProps = {
  /** Ref to store rendered FullCalendar instance */
  calendarRef: React.MutableRefObject<FullCalendar | null>;
  /** Ref to store rendered FullCalendar instance to show next week for 2 week timegrid view */
  calendarNextWeekRef: React.MutableRefObject<FullCalendar | null>;
  /** Identifies whether all schedules are expanded or not. undefined will be default state. */
  allExpanded: boolean | undefined;
  onExpandAll: (expandStatus: boolean) => void;

  activeRotViewType: IRotationType;
  onChangeActiveRotation: (rotationViewType: IRotationType) => void;

  activeViewType: IViewType;
  onChangeViewType: (view: IViewType['value']) => void;

  schedulesViewTypes: typeof schedulesViewTypes;

  visibleDates: { twoWeeksView: DateTime[]; oneWeekView: DateTime[]; oneMonthView: DateTime[] };
  onPrevious: (e: any) => void;
  onNext: (e: any) => void;

  clickedHeaderButton: string;
  resetClickedHeaderButton: () => void;
  onToday: (e: any) => void;

  activeDate: Date;
  resetActiveDate: () => void;

  /** Sync the selected date with the calendar */
  onSyncCalendar: () => void;

  currentScheduleTimezone: string;

  updateCurrentTimezone: (tz: React.SetStateAction<string>) => void;
};

interface Props {
  children: ReactNode;
  defaultViewType?: IViewType['value'];
  disabledView?: IViewType['value'][];
  defaultRotationViewType?: RotationViewType;
  isScheduleDetailsView: boolean;
}

export type IRotationType = typeof rotationViewType[number];
export type IViewType = typeof schedulesViewTypes[0];

export const schedulesViewTypes = [
  { label: '1 Week', value: CalendarViewType.gridWeek },
  { label: '2 Weeks', value: CalendarViewType.twoGridWeek },
  { label: '1 Week', value: CalendarViewType.timeGridWeek },
  { label: '2 Weeks', value: CalendarViewType.twotimeGridWeek },
  { label: 'Month', value: CalendarViewType.dayGridMonth },
  { label: 'List', value: CalendarViewType.listView },
];

const defaultRotation = rotationViewType[0];
const defaultView = schedulesViewTypes[0];

const ScheduleHeaderContext = createContext<ScheduleHeaderProviderProps>({
  calendarRef: { current: null },
  calendarNextWeekRef: { current: null },
  allExpanded: false,
  onExpandAll: status => {},
  activeRotViewType: defaultRotation,
  onChangeActiveRotation: rotation => {},
  activeViewType: defaultView,
  onChangeViewType: view => {},
  schedulesViewTypes,
  visibleDates: { twoWeeksView: [], oneWeekView: [], oneMonthView: [] },
  onPrevious: e => {},
  onNext: e => {},
  clickedHeaderButton: '',
  resetClickedHeaderButton: () => {},
  onToday: e => {},
  activeDate: new Date(),
  resetActiveDate: () => {},
  onSyncCalendar: () => {},
  updateCurrentTimezone: () => {},
  currentScheduleTimezone: '',
});

const useQueryParams = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const ScheduleHeaderProvider = ({
  children,
  defaultViewType,
  disabledView,
  defaultRotationViewType,
  isScheduleDetailsView,
}: Props) => {
  const {
    organization: { currentUser },
  } = useOrganizationConfig();

  const calendarRef = useRef<FullCalendar | null>(null);
  const calendarNextWeekRef = useRef<FullCalendar | null>(null);
  const query = useQueryParams();
  const viewQueryParam = query.get('view');
  const timezoneQueryParam = query.get('timezone') ?? '';
  const location = useLocation();

  const [activeDate, setActiveDate] = useState(new Date());

  const [allExpanded, setAllExpanded] = useState<boolean | undefined>(undefined);
  const [activeRotViewType, setActiveRotationView] = useState<IRotationType>(
    rotationViewType.find(view => view.value === defaultRotationViewType) ?? defaultRotation,
  );
  const [activeViewType, setActiveViewType] = useState<IViewType>(
    schedulesViewTypes.find(v => v.value === defaultViewType) ?? defaultView,
  );
  const [clickedHeaderButton, setClickedHeaderButton] = useState<string>('');
  const [currentScheduleTimezone, setCurrentScheduleTimezone] = useState<string>(
    currentUser.u?.time_zone ?? '',
  );

  const visibleDates = useMemo(
    () => ({
      twoWeeksView: getTwoWeekDateRange(activeDate, currentScheduleTimezone),
      oneWeekView: getOneWeekDateRange(activeDate, currentScheduleTimezone),
      oneMonthView: getOneMonthDateRange(activeDate, currentScheduleTimezone),
    }),
    [activeDate, currentScheduleTimezone],
  );

  const onDateNavigation = useCallback(
    (navigationType: 'next' | 'prev', event: ChangeEvent<HTMLButtonElement>) => {
      const navigationDate = getDateTime(activeDate);
      let updatedDate = new Date();
      switch (activeViewType.value) {
        case CalendarViewType.twoGridWeek: {
          updatedDate = navigationDate
            .plus({ week: navigationType === 'prev' ? -2 : 2 })
            .toJSDate();
          calendarRef.current
            ?.getApi()
            .incrementDate({ weeks: navigationType === 'prev' ? -2 : 2 });
          break;
        }
        case CalendarViewType.listView: {
          updatedDate = navigationDate
            .plus({ week: navigationType === 'prev' ? -2 : 2 })
            .toJSDate();
          calendarRef.current
            ?.getApi()
            .incrementDate({ weeks: navigationType === 'prev' ? -2 : 2 });
          break;
        }
        case CalendarViewType.gridWeek:
        case CalendarViewType.timeGridWeek: {
          updatedDate = navigationDate
            .plus({ week: navigationType === 'prev' ? -1 : 1 })
            .toJSDate();
          calendarRef.current
            ?.getApi()
            .incrementDate({ weeks: navigationType === 'prev' ? -1 : 1 });
          break;
        }
        case CalendarViewType.twotimeGridWeek: {
          updatedDate = navigationDate
            .plus({ week: navigationType === 'prev' ? -2 : 2 })
            .toJSDate();
          calendarRef.current
            ?.getApi()
            .incrementDate({ weeks: navigationType === 'prev' ? -2 : 2 });
          break;
        }
        case CalendarViewType.dayGridMonth: {
          updatedDate = navigationDate
            .plus({ month: navigationType === 'prev' ? -1 : 1 })
            .toJSDate();
          if (navigationType === 'prev') {
            calendarRef.current?.getApi().prev();
          } else {
            calendarRef.current?.getApi().next();
          }
          break;
        }
        default:
          updatedDate = new Date();
      }
      resetActiveDate(updatedDate);

      if (
        [CalendarViewType.dayGridMonth, CalendarViewType.timeGridWeek].includes(
          activeViewType.value,
        )
      ) {
        setClickedHeaderButton(event.currentTarget.name);
      }
    },
    [activeViewType.value, activeDate],
  );

  const onPrevious = useCallback(
    (event: ChangeEvent<HTMLButtonElement>) => onDateNavigation('prev', event),
    [onDateNavigation],
  );

  const onNext = useCallback((event: any) => onDateNavigation('next', event), [onDateNavigation]);

  /**
   * Enables all expand or all collapse for schedule.
   * If executed without parameter, returns it to default state with undefined value.
   */
  const onExpandAll = useCallback((expandStatus?: boolean) => setAllExpanded(expandStatus), []);

  const onChangeActiveRotation = useCallback(
    (rotationType: IRotationType) => setActiveRotationView(rotationType),
    [],
  );

  /**
   * Syncs FullCalendar with the activeDate when it's reinitialized or new instance is created
   */
  const onSyncCalendar = useCallback(() => {
    if (activeDate) {
      calendarRef.current?.getApi().gotoDate(activeDate);
      const nextWeekDate = getDateTime(activeDate).plus({ week: 1 }).toJSDate();
      calendarNextWeekRef.current?.getApi().gotoDate(nextWeekDate);
    }
  }, [activeDate]);

  const onChangeViewType = useCallback(
    (view: IViewType['value']) => {
      if (view === CalendarViewType.dayGridMonth) {
        onSyncCalendar();
      }
      setActiveViewType(schedulesViewTypes.find(sv => sv.value === view) ?? defaultView);
    },
    [onSyncCalendar, defaultView],
  );

  const updateCurrentTimezone = useCallback((tz: React.SetStateAction<string>) => {
    if (tz) {
      setCurrentScheduleTimezone(tz);
    }
  }, []);

  const resetClickedHeaderButton = useCallback(() => setClickedHeaderButton(''), []);

  const resetActiveDate = useCallback((date?: Date) => {
    setActiveDate(date ?? new Date());
    if (!date) {
      calendarRef.current?.getApi().today();
    }
  }, []);

  const resetNextWeekActiveDate = useCallback((navigationType: 'next' | 'prev' = 'next') => {
    if (calendarNextWeekRef.current) {
      calendarNextWeekRef.current
        ?.getApi()
        .incrementDate({ weeks: navigationType === 'prev' ? -1 : 1 });
    }
  }, []);

  const onToday = useCallback((event: any) => {
    setClickedHeaderButton(event.currentTarget.name);
    resetActiveDate();
  }, []);

  const value = {
    calendarRef,
    calendarNextWeekRef,
    allExpanded,
    onExpandAll,
    activeRotViewType,
    onChangeActiveRotation,
    activeViewType,
    onChangeViewType,
    schedulesViewTypes: schedulesViewTypes.filter(view => !disabledView?.includes(view.value)),
    visibleDates,
    onPrevious,
    onNext,
    clickedHeaderButton,
    resetClickedHeaderButton,
    onToday,
    activeDate,
    resetActiveDate,
    onSyncCalendar,
    currentScheduleTimezone,
    updateCurrentTimezone,
  };

  useEffect(() => {
    if (defaultViewType) {
      setActiveViewType(schedulesViewTypes.find(v => v.value === defaultViewType) ?? defaultView);
    }
  }, [defaultViewType]);

  useEffect(() => {
    /**
     * Navigating the calendar async as it takes time to load calendar
     */
    if (defaultViewType === CalendarViewType.twotimeGridWeek) {
      setTimeout(() => {
        calendarNextWeekRef.current?.getApi().gotoDate(activeDate);
        resetNextWeekActiveDate('next');
      }, 1000);
    }
  }, [defaultViewType, activeDate]);

  useEffect(() => {
    if (viewQueryParam) {
      setActiveViewType(
        schedulesViewTypes.find(v => v.value === viewQueryParam) ??
          (isScheduleDetailsView ? schedulesViewTypes[3] : defaultView),
      );
    }
  }, [viewQueryParam, location.pathname, isScheduleDetailsView]);

  useEffect(() => {
    updateCurrentTimezone(isScheduleDetailsView ? timezoneQueryParam : '');
  }, [timezoneQueryParam, location.pathname, isScheduleDetailsView]);

  return <ScheduleHeaderContext.Provider value={value}>{children}</ScheduleHeaderContext.Provider>;
};

const ScheduleHeaderConsumer = ScheduleHeaderContext.Consumer;

const useScheduleHeaderContext = () => useContext(ScheduleHeaderContext);

export {
  ScheduleHeaderProvider,
  ScheduleHeaderConsumer,
  ScheduleHeaderContext,
  useScheduleHeaderContext,
};
