import { IAppState } from 'core/interfaces/IAppState';
import { useEffect, useMemo, useState } from 'react';
import { useOrganizationConfig } from '../../schedules';
import { ActiveFilterTag } from '../ActiveFilterTags';
import {
  decodeFilterParam,
  getDropdownOptionDetails,
  getDropdownOptionDetailsForTeam,
} from '../helpers';
import { SelectedFilterTag } from '../SelectedFilterTags';
import { API } from 'core';
import axios from 'axios';

export enum DropdownSection {
  YOU_AND_YOUR_SQUADS = 'You & Your Squads',
  OTHER_SQUADS = 'Other Squads',
  USERS = 'Users',
}

export enum FilterObjectKeys {
  USER = 'user',
  SQUAD = 'squad',
  CURRENT_TEAM = 'current_team',
}

export enum Owner {
  USER = 'user',
  SQUAD = 'squad',
  TEAM = 'team',
}

export type Option = { label: string; value: string; type: Owner; username?: string };

export type DropdownOption = {
  label: DropdownSection;
  options: Option[];
};

export type FilterObject = {
  [FilterObjectKeys.USER]: string[];
  [FilterObjectKeys.SQUAD]: string[];
  [FilterObjectKeys.CURRENT_TEAM]: boolean;
};

export enum FilterEntity {
  SERVICE = 'Service',
  SCHEDULE = 'Schedule',
  ESCALATION_POLICY = 'Escalation Policy',
  WEBFORM = 'Webform',
  SLO = 'SLO',
  RUNBOOK = 'Runbook',
  GER = 'Global Event Rulesets',
  ALL_ENTITY_OWNER = 'Entity Owner',
}

export const ownerTagKeys = {
  [FilterEntity.SERVICE]: 'Service Owner',
  [FilterEntity.SCHEDULE]: 'Schedule Owner',
  [FilterEntity.ESCALATION_POLICY]: 'Escalation Policy Owner',
  [FilterEntity.WEBFORM]: 'Webform Owner',
  [FilterEntity.SLO]: 'SLO Owner',
  [FilterEntity.RUNBOOK]: 'Runbook Owner',
  [FilterEntity.GER]: 'Global Event Rulesets Owner',
  [FilterEntity.ALL_ENTITY_OWNER]: 'Owner',
};

interface Props {
  organizationFromProps?: IAppState['organization'];
  queryParamsString: string;
  entity: FilterEntity;
  selectedTeamId?: string;
  initialFilterValues?: FilterObject;
  enabled?: boolean;
}

const defaultInitialFilterValues = {
  [FilterObjectKeys.USER]: [],
  [FilterObjectKeys.SQUAD]: [],
  [FilterObjectKeys.CURRENT_TEAM]: false,
} as FilterObject;

const useGetSqauds = (selectedTeamId: string, enabled: boolean) => {
  const [squads, setSquads] = useState<any>([]);
  const fetchSquads = async () => {
    try {
      const req = await axios.get(
        `${API.config.batman}/organizations/${API.config.organizationId}/squads?owner_id=${selectedTeamId}`,
      );

      if (req?.data) {
        setSquads(req?.data?.data);
        return req?.data?.data;
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (enabled && selectedTeamId) {
      fetchSquads();
    }
  }, []);
  return { squads };
};

export const useEntityOwnerFilter = ({
  organizationFromProps,
  queryParamsString,
  entity,
  selectedTeamId,
  initialFilterValues = defaultInitialFilterValues,
  enabled = true,
}: Props) => {
  const { organization: org } = useOrganizationConfig();
  const organization = organizationFromProps || org;
  const { squads } = useGetSqauds(selectedTeamId || '', enabled);
  const squadList = squads || [];

  const {
    currentUserOption,
    currentUser,
    allUserOptions,
    allSquadOptions,
    teamOption,
    selectAllOption,
    squadsOfCurrentUser,
    otherSquads,
    otherUsersOption,
  } = getDropdownOptionDetails(
    organization,
    selectedTeamId,
    squadList.length ? squadList : undefined,
  );

  const allDropdownOptions = [
    currentUserOption || squadsOfCurrentUser.length > 0
      ? {
          label: DropdownSection.YOU_AND_YOUR_SQUADS,
          options: [selectAllOption, ...currentUserOption, ...squadsOfCurrentUser],
        }
      : null,
    otherUsersOption.length > 0
      ? { label: DropdownSection.USERS, options: [...otherUsersOption] }
      : null,
    otherSquads.length > 0
      ? { label: DropdownSection.OTHER_SQUADS, options: [...otherSquads] }
      : null,
  ].filter(Boolean) as DropdownOption[];

  const [teamId, setTeamId] = useState(API.config.teamId);
  const defaultActiveFilterValue = useMemo(() => {
    if (organization && !queryParamsString) {
      const organizationConfig = organization.currentOrg.o?.config;
      if (organizationConfig && organizationConfig['show_default_owner_filters']) {
        const updatedFilter = { ...initialFilterValues };
        updatedFilter[FilterObjectKeys.USER] = [currentUser?.id ?? ''].filter(Boolean);
        updatedFilter[FilterObjectKeys.SQUAD] = squadsOfCurrentUser.map(
          (squad: any) => squad.value,
        );
        updatedFilter[FilterObjectKeys.CURRENT_TEAM] = false;
        return updatedFilter;
      }
    }
    return initialFilterValues;
  }, [queryParamsString, organization, currentUser, squadsOfCurrentUser]);
  const [activeFilterObject, setActiveFilterObject] =
    useState<FilterObject>(defaultActiveFilterValue);
  const [selectedFilterObject, setSelectedFilterObject] =
    useState<FilterObject>(initialFilterValues);
  const [selectedFilterTags, setSelectedFilterTags] = useState<SelectedFilterTag[]>([]);
  const [activeFilterTags, setActiveFilterTags] = useState<ActiveFilterTag[]>([]);
  const [dropdownOptions, setDropdownOptions] = useState<DropdownOption[]>(allDropdownOptions);

  const [ownerFilterApplied, setOwnerFilterApplied] = useState<boolean>(false);
  const [ownerFilterSelected, setOwnerFilterSelected] = useState<boolean>(false);

  const filterDropdownOptions = (type?: 'assignee' | 'service_owner') => {
    let updatedDropdownOptions = allDropdownOptions
      .map((option: any) => {
        switch (option.label) {
          case DropdownSection.USERS: {
            const filteredOptions = option.options.filter(
              (opt: any) => !selectedFilterObject[FilterObjectKeys.USER].includes(opt.value),
            );
            return { ...option, options: filteredOptions };
          }
          case DropdownSection.OTHER_SQUADS: {
            const filteredOptions = option.options.filter(
              (opt: any) => !selectedFilterObject[FilterObjectKeys.SQUAD].includes(opt.value),
            );
            return { ...option, options: filteredOptions };
          }
          case DropdownSection.YOU_AND_YOUR_SQUADS: {
            const filteredOptions = option.options.filter(
              (opt: any) =>
                ![
                  ...selectedFilterObject[FilterObjectKeys.USER],
                  ...selectedFilterObject[FilterObjectKeys.SQUAD],
                ].includes(opt.value),
            );
            return { ...option, options: filteredOptions };
          }
        }
      })
      .filter(Boolean) as DropdownOption[];
    const youAndYourSquadSection = updatedDropdownOptions.find(
      option => option.label === DropdownSection.YOU_AND_YOUR_SQUADS,
    );
    if (
      youAndYourSquadSection &&
      youAndYourSquadSection.options.length === 1 &&
      youAndYourSquadSection.options.find(opt => opt.value === 'select-all')
    ) {
      updatedDropdownOptions = updatedDropdownOptions.filter(
        option => option.label !== DropdownSection.YOU_AND_YOUR_SQUADS,
      );
    }
    setDropdownOptions(updatedDropdownOptions);
    return updatedDropdownOptions;
  };

  const clearSelection = () => {
    setActiveFilterTags([]);
    setSelectedFilterTags([]);
    setSelectedFilterObject(initialFilterValues);
  };

  const onChangeHandler = ({ value, type }: Option) => {
    if (value && type) {
      const currentFilter = { ...selectedFilterObject };
      if (value === 'select-all') {
        if (!currentFilter[FilterObjectKeys.USER].find(value => value === currentUser?.id)) {
          currentFilter[FilterObjectKeys.USER].push(currentUser?.id ?? '');
        }
        squadsOfCurrentUser.forEach((squad: any) => {
          if (!currentFilter[FilterObjectKeys.SQUAD].find(value => value === squad.value)) {
            currentFilter[FilterObjectKeys.SQUAD].push(squad.value);
          }
        });
      } else {
        switch (type) {
          case Owner.USER:
            currentFilter[FilterObjectKeys.USER].push(value);
            break;
          case Owner.SQUAD:
            currentFilter[FilterObjectKeys.SQUAD].push(value);
            break;
          case Owner.TEAM:
            currentFilter[FilterObjectKeys.CURRENT_TEAM] = true;
        }
      }
      setSelectedFilterObject(currentFilter);
    }
  };

  const getUserTagValue = (user: {
    value: string;
    label: string;
    type: Owner;
    username: string;
  }) => {
    const isCurrentUser = user.value === currentUser?.id;
    return `${user.label}${isCurrentUser ? ' (You)' : ''}`;
  };

  const getUpdatedSelectedFilterTags = () => {
    const updatedTags: SelectedFilterTag[] = [];
    selectedFilterObject[FilterObjectKeys.USER].forEach(value => {
      const user = allUserOptions.find(opt => opt.value === value);
      if (user) {
        updatedTags.push({
          type: Owner.USER,
          id: value,
          title: getUserTagValue(user),
          username: user.username,
        });
      }
    });
    selectedFilterObject[FilterObjectKeys.SQUAD].forEach(value => {
      const squad = allSquadOptions.find(opt => opt.value === value);
      if (squad) {
        const isSquadOfCurrentUser = !!squadsOfCurrentUser.find(s => s.value === value);
        updatedTags.push({
          type: Owner.SQUAD,
          id: value,
          title: `${squad.label}${isSquadOfCurrentUser ? ' (Your Squad)' : ''}`,
        });
      }
    });
    if (selectedFilterObject[FilterObjectKeys.CURRENT_TEAM] && teamOption) {
      updatedTags.push({
        type: Owner.TEAM,
        id: teamOption.value,
        title: teamOption.label,
      });
    }
    setSelectedFilterTags(updatedTags);
  };

  const getUpdatedActiveFilterTags = () => {
    const updatedTags: ActiveFilterTag[] = [];
    const tagKey = ownerTagKeys[entity];
    activeFilterObject[FilterObjectKeys.USER].forEach(value => {
      const user = allUserOptions.find(opt => opt.value === value);
      if (user) {
        updatedTags.push({
          type: Owner.USER,
          id: value,
          key: tagKey,
          value: getUserTagValue(user),
          username: user.username,
        });
      }
    });
    activeFilterObject[FilterObjectKeys.SQUAD].forEach(value => {
      const squad = allSquadOptions.find(opt => opt.value === value);
      if (squad) {
        const isSquadOfCurrentUser = !!squadsOfCurrentUser.find(s => s.value === value);
        updatedTags.push({
          type: Owner.SQUAD,
          id: value,
          key: tagKey,
          value: `${squad.label}${isSquadOfCurrentUser ? ' (Your Squad)' : ''}`,
        });
      }
    });
    if (activeFilterObject[FilterObjectKeys.CURRENT_TEAM] && teamOption) {
      updatedTags.push({
        type: Owner.TEAM,
        id: teamOption.value,
        key: tagKey,
        value: teamOption.label,
      });
    }
    setActiveFilterTags(updatedTags);
  };

  const updateActiveFiltersObject = (filterObject: FilterObject) => {
    const updatedActiveFiltersObject = { ...activeFilterObject };
    Object.keys(filterObject).forEach(key => {
      switch (key) {
        case FilterObjectKeys.SQUAD:
        case FilterObjectKeys.USER:
          updatedActiveFiltersObject[key] = [...filterObject[key]];
      }
    });
    setActiveFilterObject(updatedActiveFiltersObject);
  };

  const syncSelectedFilterWithActiveFilter = () => {
    const updatedSelectedFiltersObject = { ...selectedFilterObject };
    Object.keys(activeFilterObject).forEach(key => {
      switch (key) {
        case FilterObjectKeys.CURRENT_TEAM:
          updatedSelectedFiltersObject[key] = activeFilterObject[key];
          break;
        case FilterObjectKeys.SQUAD:
        case FilterObjectKeys.USER:
          updatedSelectedFiltersObject[key] = [...activeFilterObject[key]];
      }
    });
    setSelectedFilterObject(updatedSelectedFiltersObject);
  };

  const handleSelectedFilterTagClick = (type: Owner, id: string) => {
    const updatedFilterObject = { ...selectedFilterObject };
    let updatedTags;

    if (id === 'meAndMySquads') {
      const ownSquads = allDropdownOptions[0].options.filter(
        (opt: Option) => opt.type === Owner.SQUAD,
      );
      const ownUsers = allDropdownOptions[0].options.filter(
        (opt: Option) => opt.type === Owner.USER,
      );
      updatedFilterObject[FilterObjectKeys.USER] = updatedFilterObject[
        FilterObjectKeys.USER
      ].filter(value => value !== ownUsers[0].value);

      if (ownSquads.length) {
        // Extracting IDs from arrayOfObjects
        const objectIds = (updatedFilterObject[FilterObjectKeys.SQUAD] as unknown as Option[]).map(
          obj => obj.value,
        );

        // Finding IDs present in arrayOfIds but not in objectIds
        const nonCommonIds = (ownSquads as unknown as string[]).filter(
          (id: string) => !objectIds.includes(id),
        ) as Array<string>;

        // Finding objects with IDs present in nonCommonIds
        const nonCommonObjects = (
          updatedFilterObject[FilterObjectKeys.SQUAD] as unknown as Option[]
        ).filter(obj => nonCommonIds.includes(obj.value));

        updatedFilterObject[FilterObjectKeys.SQUAD] = nonCommonObjects as unknown as Array<string>;
      }
    } else {
      updatedTags = selectedFilterTags.filter(tag => tag.id !== id);
      switch (type) {
        case Owner.TEAM:
          updatedFilterObject[FilterObjectKeys.CURRENT_TEAM] = false;
          break;
        case Owner.USER:
        case Owner.SQUAD:
          updatedFilterObject[FilterObjectKeys.USER] = updatedFilterObject[
            FilterObjectKeys.USER
          ].filter(value => value !== id);
          updatedFilterObject[FilterObjectKeys.SQUAD] = updatedFilterObject[
            FilterObjectKeys.SQUAD
          ].filter(value => value !== id);
      }
    }
    setSelectedFilterObject(updatedFilterObject);
    setSelectedFilterTags(updatedTags as SelectedFilterTag[]);
  };

  const handleActiveFilterTagClick = (type: Owner, id: string) => {
    const updatedFilterObject = { ...activeFilterObject };
    const updatedTags = activeFilterTags.filter(tag => tag.id !== id);
    switch (type) {
      case Owner.TEAM:
        updatedFilterObject[FilterObjectKeys.CURRENT_TEAM] = false;
        break;
      case Owner.USER:
      case Owner.SQUAD:
        updatedFilterObject[FilterObjectKeys.USER] = updatedFilterObject[
          FilterObjectKeys.USER
        ].filter(value => value !== id);
        updatedFilterObject[FilterObjectKeys.SQUAD] = updatedFilterObject[
          FilterObjectKeys.SQUAD
        ].filter(value => value !== id);
    }
    setActiveFilterObject(updatedFilterObject);
    setActiveFilterTags(updatedTags);
  };

  const resetOwnerFilters = () => {
    setActiveFilterObject({
      [FilterObjectKeys.USER]: [],
      [FilterObjectKeys.SQUAD]: [],
      [FilterObjectKeys.CURRENT_TEAM]: false,
    } as FilterObject);
  };

  const clearSelectedFilterValues = () => {
    setSelectedFilterObject({
      [FilterObjectKeys.USER]: [],
      [FilterObjectKeys.SQUAD]: [],
      [FilterObjectKeys.CURRENT_TEAM]: false,
    } as FilterObject);
  };

  const clearAllSection = () => {
    filterDropdownOptions();
  };

  useEffect(() => {
    filterDropdownOptions();
    getUpdatedSelectedFilterTags();
  }, [selectedFilterObject]);

  useEffect(() => {
    if (selectedTeamId && squadList?.length) filterDropdownOptions();
  }, [squadList]);

  useEffect(() => {
    getUpdatedActiveFilterTags();
    syncSelectedFilterWithActiveFilter();
  }, [activeFilterObject]);

  useEffect(() => {
    if (queryParamsString) {
      const decodedFilterObject = decodeFilterParam(queryParamsString);
      updateActiveFiltersObject(decodedFilterObject);
    }
  }, [queryParamsString]);

  useEffect(() => {
    if (
      selectedFilterObject[FilterObjectKeys.CURRENT_TEAM] ||
      [
        ...selectedFilterObject[FilterObjectKeys.SQUAD],
        ...selectedFilterObject[FilterObjectKeys.USER],
      ].length > 0
    ) {
      setOwnerFilterSelected(true);
    } else {
      setOwnerFilterSelected(false);
    }
  }, [selectedFilterObject]);

  useEffect(() => {
    if (
      activeFilterObject[FilterObjectKeys.CURRENT_TEAM] ||
      [...activeFilterObject[FilterObjectKeys.SQUAD], ...activeFilterObject[FilterObjectKeys.USER]]
        .length > 0
    ) {
      setOwnerFilterApplied(true);
    } else {
      setOwnerFilterApplied(false);
    }
  }, [activeFilterObject]);

  useEffect(() => {
    //Reset owner filters if team is changed
    if (teamId !== API.config.teamId) {
      resetOwnerFilters();
      setTeamId(API.config.teamId);
    }
  }, [API.config.teamId]);

  return {
    activeFilterObject,
    clearSelectedFilterValues,
    selectedFilterObject,
    dropdownOptions,
    /**
     * onChangeHandler is a function that is passed to the dropdown component to handle the change event
     * @param {string} key - the key of the dropdown
     * @param {string} value - the value of the dropdown
     * @param {boolean} checked - the checked value of the dropdown
     */
    onChangeHandler,
    activeFilterTags,
    selectedFilterTags,
    resetOwnerFilters,
    handleSelectedFilterTagClick,
    handleActiveFilterTagClick,
    ownerFilterApplied,
    ownerFilterSelected,
    syncSelectedFilterWithActiveFilter,
    clearSelection,
    clearAllSection,
  };
};
