import './index.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import {
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select as ChakraSelect,
  Switch,
  Text,
} from '@chakra-ui/react';
import { Col, Row, SpinLoader } from '@squadcast/alchemy-ui/carbon';
import axios from 'axios';
import { deepCopy, pick } from 'core/helpers';
import { Locale } from 'core/helpers/dateUtils';
import equal from 'fast-deep-equal/es6/react';
import moment, { Moment } from 'moment';
import * as React from 'react';
import { SingleDatePicker } from 'react-dates';
import { connect } from 'react-redux';
import Select from 'react-select';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { API } from '../../../../../../core/api';
import { T_WA_GS_SERVICE_MAINTENANCE } from '../../../../../../core/const/tracker';
import { IAppState } from '../../../../../../core/interfaces/IAppState';
import { AppTracker } from '../../../../../../shared/analytics/tracker';
import IllustrationBox from '../../common/IllustrationBox';
import MaintenanceIllustration from '../../icons/Illustrations/Maintenance.png';
import { serviceListUrl } from '../../contants/service.contant';

interface IProps extends RouteComponentProps, Pick<IAppState, 'organization' | 'userInfo'> {
  hide: () => void;
  serviceId?: string | null | undefined;
  checkAndSetDirty: () => void;
  onSave: (payload: any) => void;
  serviceList: Array<string> | null;
}
interface ruleExtension {
  repeatTill?: Date | null;
  daily?: boolean;
  weekly?: boolean;
  twoWeekly?: boolean;
  threeWeekly?: boolean;
  monthly?: boolean;
  maintenanceStartDate: Date;
  maintenanceEndDate?: Date | null;
  maintenanceStartTime?: string;
  maintenanceEndTime?: string;
  status?: string;
  deleted: boolean;
  days?: any[];
  maintenanceStartValidationMessage: string;
  maintenanceEndValidationMessage: string;
  maintenanceRepeatValidationMessage: string;
  repeatButton: boolean;
  repeatOptions: repeatOption[];
  repeatsEvery: repeatOption;
  showEditConfig?: number | null;
  repetition?: boolean;
  showDatePicker?: 'start-date' | 'end-date' | 'repeat-till' | '';
  [key: string]: any;
}
interface repeatOption {
  label: string;
  value: string;
}
interface State {
  rules: ruleExtension[];
  rulesData: ruleExtension[];
  onMaintenance: boolean;
  times: string[];
  saving: boolean;
  loading: boolean;
  hasChanged: boolean;
  scroll: number;
}

class MaintenanceModal extends React.Component<IProps, State> {
  private initialState?: State;

  constructor(props: IProps) {
    super(props);
    const times = [moment().startOf('day').format('HH:mm')];
    let momentObj = moment().startOf('day');
    // eslint-disable-next-line no-constant-condition
    while (true) {
      momentObj = momentObj.add(15, 'minutes');
      if (momentObj.format('HH:mm') === times[0]) {
        break;
      } else {
        times.push(momentObj.format('HH:mm'));
      }
    }
    this.state = {
      rules: [],
      rulesData: [],
      onMaintenance: false,
      times,
      saving: false,
      loading: false,
      hasChanged: false,
      scroll: 0,
    };
    this.save = this.save.bind(this);
    this.addNewRule = this.addNewRule.bind(this);
    this.deleteRule = this.deleteRule.bind(this);
    this.scrollLeft = this.scrollLeft.bind(this);
    this.scrollRight = this.scrollRight.bind(this);
    this.repeatTimeHandler = this.repeatTimeHandler.bind(this);
    this.updateRepeatOptions = this.updateRepeatOptions.bind(this);
    this.isSaveButtonDisabled = this.isSaveButtonDisabled.bind(this);
  }
  public deleteRule(index: number) {
    const rules = this.state.rules;
    rules[index].deleted = true;
    rules.splice(index, 1);
    this.setState({
      rules,
      hasChanged: true,
    });
  }

  getNewRule = (): ruleExtension => {
    const startOfHour = moment().startOf('hour').format('HH:mm');
    return {
      maintenanceStartDate: moment()
        .set({
          hour: parseInt(startOfHour.split(':')[0], 10),
          minute: parseInt(startOfHour.split(':')[1], 10),
          second: 0,
          millisecond: 0,
        })
        .toDate(),
      maintenanceEndDate: null,
      maintenanceStartTime: startOfHour,
      maintenanceEndTime: '',
      repeatTill: moment()
        .set({
          hour: parseInt(startOfHour.split(':')[0], 10),
          minute: parseInt(startOfHour.split(':')[1], 10),
          second: 0,
          millisecond: 0,
        })
        .toDate(),
      daily: false,
      weekly: false,
      monthly: false,
      status: 'New Maintenance Schedule',
      deleted: false,
      maintenanceStartValidationMessage: '',
      maintenanceEndValidationMessage: '',
      maintenanceRepeatValidationMessage: '',
      repeatButton: false,
      repeatOptions: [this.repeatOptions[0]],
      repeatsEvery: this.repeatOptions[0],
      days: [],
      showEditConfig: null,
      repetition: false,
      showDatePicker: '',
    };
  };

  public addNewRule() {
    const rules = this.state.rules.slice();
    rules.push(this.getNewRule());

    this.setState({
      rules,
      hasChanged: true,
    });
  }

  public repeatingDates() {
    const rules = this.state.rules;
    rules.forEach((rule: ruleExtension) => {
      if (!rule.daily && !rule.weekly && !rule.monthly && !rule.twoWeekly && !rule.threeWeekly)
        return;

      const timePeriodDays = rule.daily
        ? 1
        : rule.weekly
        ? 7
        : rule.twoWeekly
        ? 14
        : rule.threeWeekly
        ? 21
        : 30;

      const maintenanceStartDate = moment(rule.maintenanceStartDate);
      const maintenanceEndDate = moment(rule.maintenanceEndDate);
      const repeatTill = moment(rule.repeatTill);

      const days = [];
      for (let i = 0; i <= 100; i++) {
        const iterStartDate = maintenanceStartDate.clone().add(i * timePeriodDays, 'd');
        const iterEndDate = maintenanceEndDate.clone().add(i * timePeriodDays, 'd');
        const minEndDate = moment.min([iterEndDate, repeatTill]);
        if (iterStartDate.isBefore(minEndDate)) {
          days.push({
            start: iterStartDate,
            end: minEndDate,
          });
        } else {
          break;
        }
      }
      rule.days = days;
    });
    this.setState({
      rules,
    });
  }

  public save() {
    const data: any = {};
    const rules = this.state.rules;

    this.setState({
      hasChanged: false,
    });

    if (!this.state.onMaintenance) {
      data.onMaintenance = false;
      for (let i = 0; i < rules.length; i++) {
        rules[i].deleted = true;
      }
    } else {
      data.onMaintenance = true;
      for (let i = 0; i < rules.length; i++) {
        if (rules[i].status !== 'Scheduled Maintenance') {
          if (!rules[i].maintenanceStartDate) {
            rules[i].maintenanceStartValidationMessage = 'Please select a maintenance start date';
            this.setState({
              rules,
            });
            return;
          }
          if (
            (rules[i].maintenanceEndDate && !rules[i].maintenanceEndTime) ||
            (!rules[i].maintenanceEndDate && rules[i].maintenanceEndTime)
          ) {
            rules[i].maintenanceEndValidationMessage = `Please select a maintenance end ${
              rules[i].maintenanceEndDate ? 'time' : 'date'
            }`;
            this.setState({
              rules,
            });
            return;
          }

          const maintenanceStartDate = moment(rules[i].maintenanceStartDate);
          const repeatTill = moment(rules[i].repeatTill);
          rules[i].maintenanceStartDate = maintenanceStartDate.toDate();
          if (rules[i].maintenanceEndDate) {
            const maintenanceEndDate = moment(rules[i].maintenanceEndDate);
            rules[i].maintenanceEndDate = maintenanceEndDate.toDate();

            const doesRepeat =
              rules[i].daily ||
              rules[i].weekly ||
              rules[i].twoWeekly ||
              rules[i].threeWeekly ||
              rules[i].monthly;
            rules[i].repeatTill = doesRepeat ? repeatTill.toDate() : maintenanceEndDate.toDate();
            if (maintenanceStartDate.isSame(maintenanceEndDate)) {
              rules[i].maintenanceEndValidationMessage = 'End time cannot be same as start time';
              this.setState({
                rules,
              });
              return;
            }
            if (maintenanceEndDate.isBefore(maintenanceStartDate)) {
              rules[i].maintenanceEndValidationMessage = 'End time cannot be before start time';
              this.setState({
                rules,
              });
              return;
            }

            if (maintenanceEndDate.isBefore(moment())) {
              rules[i].maintenanceEndValidationMessage =
                'Maintenance end date should be a future date';
              this.setState({
                rules,
              });
              return;
            }
            if (rules[i].repetition) {
              if (
                repeatTill.isBefore(moment()) ||
                (rules[i].repeatButton ? repeatTill.isBefore(maintenanceEndDate) : false)
              ) {
                rules[i].maintenanceRepeatValidationMessage =
                  'Maintenance Repeat date should be a future date';
                this.setState({
                  rules,
                });
                return;
              }
            }
          }
        }

        if (!rules[i].maintenanceEndDate && !rules[i].maintenanceEndTime) {
          rules[i].repeatTill = null;
        }
      }
    }

    data.serviceMaintenance = rules;

    this.setState(
      {
        saving: true,
        rules,
      },
      () => {
        this.props.onSave({
          session_token: API.config.sessionId,
          organizationId: API.config.organizationId,
          serviceId: this.props.serviceId,
          data: {
            ...data,
            serviceMaintenance: data.serviceMaintenance.map((maintenance: ruleExtension) => {
              const [startTimeHour, startTimeMinute] =
                maintenance.maintenanceStartTime?.split(':') ?? [];
              const [endTimeHour, endTimeMinute] = maintenance.maintenanceEndTime?.split(':') ?? [];

              const response = {
                ...maintenance,
                maintenanceStartDate: this.toTZTDatetime(
                  new Date(maintenance.maintenanceStartDate),
                  Number(startTimeHour) * 60 + Number(startTimeMinute),
                ),
                maintenanceEndDate: maintenance.maintenanceEndDate
                  ? this.toTZTDatetime(
                      new Date(maintenance.maintenanceEndDate),
                      Number(endTimeHour) * 60 + Number(endTimeMinute),
                    )
                  : null,
              };

              if (response.repeatTill) {
                const doesRepeat =
                  response.daily ||
                  response.weekly ||
                  response.twoWeekly ||
                  response.threeWeekly ||
                  response.monthly;
                response.repeatTill = (doesRepeat
                  ? response.repeatTill.toISOString()
                  : response.maintenanceEndDate) as unknown as Date;
              }
              return response;
            }),
          },
        });
      },
    );

    AppTracker.track(T_WA_GS_SERVICE_MAINTENANCE, { 'Maintenance Mode': this.state.onMaintenance });
  }

  whenNoMaintenence() {
    const rules = this.state.rules.slice();
    rules.push(this.getNewRule());
    this.setState(
      {
        onMaintenance: false,
        rules,
        loading: false,
      },
      () => (this.initialState = deepCopy(this.state)),
    );
  }

  public componentDidMount() {
    this.setState(
      {
        loading: true,
      },
      () => {
        this.props.serviceId
          ? axios
              .get(
                API.config.endpoint +
                  `/organizations/${API.config.organizationId}/services/${this.props.serviceId}/maintenance`,
              )
              .then(response => {
                if (response.data.result) {
                  this.setState({
                    loading: false,
                  });
                  const rules = this.state.rules.slice();
                  if (response.data.data.length >= 1) {
                    response.data.data.forEach((res: any) => {
                      if (res.maintenanceFrom !== res.maintenanceTill) {
                        const startDateTime = this.getTZTime(new Date(res.maintenanceFrom));
                        const endDateTime = res.maintenanceTill
                          ? this.getTZTime(new Date(res.maintenanceTill))
                          : null;
                        const rule: ruleExtension = {
                          maintenanceStartDate: new Date(startDateTime.date),
                          maintenanceEndDate: endDateTime ? new Date(endDateTime.date) : null,
                          maintenanceStartTime: startDateTime.time,
                          maintenanceEndTime: endDateTime?.time ?? '',
                          daily: res.repetitionDaily,
                          weekly: res.repetitionWeekly,
                          monthly: res.repetitionMonthly,
                          twoWeekly: res.repetitionTwoWeekly,
                          threeWeekly: res.repetitionThreeWeekly,
                          repeatTill: new Date(res.repeatTill),
                          deleted: res.deleted,
                          status: 'Scheduled Maintenance',
                          maintenanceStartValidationMessage: '',
                          maintenanceEndValidationMessage: '',
                          maintenanceRepeatValidationMessage: '',
                          repeatButton: false,
                          showEditConfig: null,
                          repetition:
                            res.repetitionDaily ||
                            res.repetitionWeekly ||
                            res.repetitionMonthly ||
                            res.repetitionTwoWeekly ||
                            res.repetitionThreeWeekly,
                          repeatOptions: [this.repeatOptions[0]],
                          repeatsEvery: this.repeatOptions[0],
                        };
                        rule.repeatsEvery = this.defaultOption(rule);
                        rules.push(rule);
                      }
                    });
                  }

                  const startOfHour = moment().startOf('hour').format('HH:mm');
                  rules.forEach((res: ruleExtension) => {
                    if (res.status !== 'Scheduled Maintenance') {
                      rules.push({
                        maintenanceStartDate: moment()
                          .set({
                            hour: parseInt(startOfHour.split(':')[0], 10),
                            minute: parseInt(startOfHour.split(':')[1], 10),
                            second: 0,
                            millisecond: 0,
                          })
                          .toDate(),
                        maintenanceEndDate: moment()
                          .set({
                            hour: parseInt(startOfHour.split(':')[0], 10),
                            minute: parseInt(startOfHour.split(':')[1], 10),
                            second: 0,
                            millisecond: 0,
                          })
                          .toDate(),
                        maintenanceStartTime: startOfHour,
                        maintenanceEndTime: startOfHour,
                        daily: false,
                        weekly: false,
                        monthly: false,
                        repeatTill: moment()
                          .set({
                            hour: parseInt(startOfHour.split(':')[0], 10),
                            minute: parseInt(startOfHour.split(':')[1], 10),
                            second: 0,
                            millisecond: 0,
                          })
                          .toDate(),
                        status: 'New Maintenance Schedule',
                        deleted: false,
                        maintenanceStartValidationMessage: '',
                        maintenanceEndValidationMessage: '',
                        maintenanceRepeatValidationMessage: '',
                        repeatButton: false,
                        showEditConfig: null,
                        repetition: false,
                        repeatsEvery: this.repeatOptions[0],
                        repeatOptions: [this.repeatOptions[0]],
                      });
                    }
                  });

                  if (response.data.data.length === 0) {
                    ///Default case
                    this.whenNoMaintenence();
                  } else if (response.data.data) {
                    this.setState(
                      {
                        onMaintenance: true,
                        hasChanged: false,
                        rules,
                        rulesData: [...rules],
                      },
                      () => (this.initialState = deepCopy(this.state)),
                    );
                  }

                  this.repeatingDates();
                } else {
                }
              })
              .catch(err => {
                this.props.history.push(serviceListUrl);
              })
          : this.whenNoMaintenence();
      },
    );
  }
  public scrollRight(id: string) {
    let scrollLeft = this.state.scroll;
    scrollLeft += 485;
    this.setState({ scroll: scrollLeft });
    (this.refs[id] as HTMLElement).scrollLeft = scrollLeft;
  }

  public scrollLeft(id: string) {
    let scrollLeft = this.state.scroll;
    scrollLeft -= 485;
    if (scrollLeft < 0) {
      scrollLeft = 0;
    }
    this.setState({ scroll: scrollLeft });
    (this.refs[id] as HTMLElement).scrollLeft = scrollLeft;
  }

  public componentDidUpdate(prevProps: IProps, prevState: State) {
    if (
      this.initialState &&
      !equal(
        pick(this.state, 'onMaintenance', 'rules'),
        pick(this.initialState, 'onMaintenance', 'rules'),
      )
    ) {
      this.props.checkAndSetDirty();
    }

    const rulesChanged = !equal(pick(this.state, 'rules'), pick(prevState, 'rules'));
    if (rulesChanged) this.state.rules.forEach((el, idx) => this.updateRepeatOptions(idx));

    if (this.state.hasChanged && this.state.saving) {
      this.setState({ saving: false });
    }
  }

  public isSaveButtonDisabled() {
    if (this.state.saving || !this.state.hasChanged) {
      return true;
    }
    // todo: compare rules with rulesData
    return false;
  }

  repeatOptions = [
    { value: 'none', label: 'Never' },
    { value: 'daily', label: 'Day' },
    { value: 'weekly', label: 'Week' },
    { value: 'twoWeekly', label: 'Two Weeks' },
    { value: 'threeWeekly', label: 'Three Weeks' },
    { value: 'monthly', label: 'One Month' },
  ];
  defaultOption = (rule: ruleExtension) => {
    let defaultSelectedIndex = 0;
    ['daily', 'weekly', 'twoWeekly', 'threeWeekly', 'monthly'].forEach((item, i) => {
      if (rule[item]) {
        defaultSelectedIndex = i + 1;
      }
    });
    return this.repeatOptions[defaultSelectedIndex];
  };

  public repeatTimeHandler(index: number, newValue: string | undefined, updateHasChanged = false) {
    const rules = this.state.rules;
    if (newValue) {
      rules[index].daily = newValue === 'daily' ? true : false;
      rules[index].weekly = newValue === 'weekly' ? true : false;
      rules[index].twoWeekly = newValue === 'twoWeekly' ? true : false;
      rules[index].threeWeekly = newValue === 'threeWeekly' ? true : false;
      rules[index].monthly = newValue === 'monthly' ? true : false;
      rules[index].repeatButton = newValue !== 'none' ? true : false;
      rules[index].repetition = newValue !== 'none' ? true : false;
      rules[index].repeatsEvery =
        this.repeatOptions.find(el => el.value === newValue) ?? this.repeatOptions[0];
    }
    if (newValue === 'none') {
      rules[index].days = [];
      if (rules[index].maintenanceEndDate && rules[index].maintenanceEndTime) {
        const [endTimeHour, endTimeMinute] = rules[index].maintenanceEndTime?.split(':') ?? [];
        const maintenanceEndDate = rules[index].maintenanceEndDate
          ? this.toTZTDatetime(
              new Date(rules[index].maintenanceEndDate ?? new Date()),
              Number(endTimeHour) * 60 + Number(endTimeMinute),
            )
          : null;
        rules[index].repeatTill = moment(maintenanceEndDate).toDate();
      }
      this.setState({ rules });
    } else {
      this.setState({ rules }, this.repeatingDates);
    }
    if (updateHasChanged) {
      this.setState({ hasChanged: true });
    }
  }

  public updateRepeatOptions(index: number) {
    const rules = this.state.rules;
    if (rules[index].maintenanceEndDate && rules[index].maintenanceEndTime) {
      rules[index].repeatOptions = this.repeatOptions;
    } else {
      this.repeatTimeHandler(index, 'none');
      rules[index].repeatOptions = [this.repeatOptions[0]];
    }
  }

  /**
   * Create selected TZ Date with provided date and time
   * @param timezoneDate JS Date
   * @returns local date
   */
  toTZTDatetime = (timezoneDate: Date, minutes: number) => {
    if (!timezoneDate) return timezoneDate;
    return Locale.toTZDateTime(
      timezoneDate.getFullYear(),
      timezoneDate.getMonth() + 1,
      timezoneDate.getDate(),
    )
      .set({
        hour: 0,
        minute: minutes,
        second: 0,
        millisecond: 0,
      })
      .toISO();
  };

  /**
   * GET timezone time string
   * @param timezoneDate Js Date
   * @returns Moment Date
   */
  getTZTime = (timezoneDate: Date) => {
    if (!timezoneDate) return timezoneDate;
    const { year, month, day, hour, minute } = Locale.toDateTimeWTime(timezoneDate, {
      hour: Locale.toDateTime(timezoneDate).hour,
      minute: Locale.toDateTime(timezoneDate).minute,
    });

    return {
      date: `${year}/${month}/${day}`,
      time: `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`,
    };
  };

  render() {
    return (
      <div
        onClick={event => {
          event.stopPropagation();
        }}
      >
        {this.props.serviceList && (
          <HStack>
            <Text fontWeight="medium">Service(s) Selected:</Text>
            {this.props.serviceList && (
              <Badge isTruncated variant="outline" m={1}>
                {this.props.serviceList[0]}
              </Badge>
            )}
            {this.props.serviceList.length > 1 && (
              <Menu autoSelect={false}>
                <MenuButton as={Badge}>{`+${this.props.serviceList.length - 1}`}</MenuButton>
                <MenuList p={0}>
                  {this.props.serviceList &&
                    this.props.serviceList.slice(1).map((obj, index) => (
                      <MenuItem cursor="default" p={0} key="index" _hover={{ bg: 'white' }} m={1}>
                        <Badge>{obj}</Badge>
                      </MenuItem>
                    ))}
                </MenuList>
              </Menu>
            )}
          </HStack>
        )}
        <Flex>
          {!this.state.loading ? (
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="email-alerts" mb="0">
                Enable maintenance for service(s)
              </FormLabel>
              <Switch
                isChecked={this.state.onMaintenance}
                onChange={() => {
                  const { onMaintenance, rulesData } = this.state;
                  const hasChanged = !(onMaintenance && rulesData.length === 0);
                  this.setState({
                    onMaintenance: !onMaintenance,
                    hasChanged,
                  });
                }}
                id="email-alerts"
              />
            </FormControl>
          ) : null}
        </Flex>

        {this.state.loading ? (
          <div style={{ height: 300, position: 'relative' }}>
            <Col flexHeight={12} justifyContent="center">
              <Row justifyContent="center">
                <SpinLoader size="normal" />
              </Row>
            </Col>
          </div>
        ) : (
          <Box mt={10}>
            {this.state.onMaintenance ? (
              <Box>
                {this.state.rules.map((rule: ruleExtension, index) => {
                  return (
                    <Box
                      key={index}
                      m={1}
                      p={5}
                      borderRadius="6px"
                      border="solid 1px #ebecef"
                      bgColor="#ffffff"
                      pos={'relative'}
                    >
                      <Text>{rule.status}</Text>
                      <Box mt={30}>
                        <HStack alignItems="flex-start" justifyContent="space-between">
                          <div
                            className="float-left field-parent-div"
                            style={{ width: 'calc(50% - 10px)', position: 'relative' }}
                          >
                            <Text className="modal-container-sub-heading" m={0} mb={2}>
                              Start Date <span className="red-color-text">*</span>
                            </Text>
                            <div style={{ width: '95%' }}>
                              <div className="maintenance-modal-date-picker-wrapper">
                                <SingleDatePicker
                                  date={moment(
                                    rule.maintenanceStartDate.valueOf
                                      ? new Date(rule.maintenanceStartDate.valueOf())
                                      : rule.maintenanceStartDate,
                                  )}
                                  disabled={rule.status === 'Scheduled Maintenance'}
                                  onDateChange={(date: Moment | null) => {
                                    if (date) {
                                      const rules = this.state.rules;
                                      const rule = rules[index];
                                      if (rule.maintenanceStartTime) {
                                        rule.maintenanceStartDate = moment(date)
                                          .set({
                                            hour: parseInt(
                                              (rule.maintenanceStartTime as string).split(':')[0],
                                              10,
                                            ),
                                            minute: parseInt(
                                              (rule.maintenanceStartTime as string).split(':')[1],
                                              10,
                                            ),
                                          })
                                          .toDate();
                                      } else {
                                        rule.maintenanceStartDate = date.toDate();
                                      }
                                      rules[index] = rule;

                                      this.setState({ rules }, this.repeatingDates);
                                    }
                                  }}
                                  displayFormat="MMM D, yyyy"
                                  focused={rule.showDatePicker === 'start-date'}
                                  numberOfMonths={1}
                                  onFocusChange={({ focused }) => {
                                    const rules = this.state.rules;
                                    rules[index].maintenanceStartValidationMessage = '';
                                    rules[index].maintenanceEndValidationMessage = '';
                                    rules[index].showDatePicker = focused ? 'start-date' : '';

                                    this.setState({ rules }, this.repeatingDates);
                                  }}
                                  id={`maintenance-start-date-${index}`}
                                  hideKeyboardShortcutsPanel={true}
                                />
                              </div>
                            </div>
                            <div className="error-block maintenance-start-error">
                              {rule.maintenanceStartValidationMessage}
                            </div>
                          </div>
                          <div
                            className="float-right field-parent-div"
                            style={{ width: 'calc(50% - 10px)', position: 'relative' }}
                          >
                            <Text className="modal-container-sub-heading" m={0} mb={2}>
                              {' '}
                              Start Time <span className="red-color-text">*</span>
                            </Text>
                            <ChakraSelect
                              className={`maintenance-start-time select-design ${
                                rule.status === 'Scheduled Maintenance' ? 'input-disabled' : ''
                              }`}
                              width="95%"
                              value={this.state.rules[index].maintenanceStartTime}
                              onChange={event => {
                                const rules = this.state.rules;
                                const rule = rules[index];
                                rule.maintenanceStartTime = event.target.value;
                                rule.maintenanceStartDate = moment(rule.maintenanceStartDate)
                                  .set({
                                    hour: parseInt(
                                      (rule.maintenanceStartTime as string).split(':')[0],
                                      10,
                                    ),
                                    minute: parseInt(
                                      (rule.maintenanceStartTime as string).split(':')[1],
                                      10,
                                    ),
                                    second: 0,
                                    millisecond: 0,
                                  })
                                  .toDate();
                                rules[index] = rule;
                                this.setState({ rules }, this.repeatingDates);
                              }}
                            >
                              {this.state.times.map((time, index) => {
                                return (
                                  <option
                                    disabled={
                                      rule.status === 'Scheduled Maintenance' ? true : false
                                    }
                                    key={index}
                                    value={time}
                                  >
                                    {time} (
                                    {(moment as any).tz((moment as any).tz.guess()).format('z')})
                                  </option>
                                );
                              })}
                            </ChakraSelect>
                            <div className="error-block" />
                          </div>
                        </HStack>
                        <div className="">
                          <div
                            className="float-left field-parent-div"
                            style={{ width: 'calc(50% - 10px)', position: 'relative' }}
                          >
                            <Text className="modal-container-sub-heading" m={0} mb={2}>
                              {' '}
                              End Date
                            </Text>
                            <div style={{ width: '95%' }}>
                              <div className="maintenance-modal-date-picker-wrapper">
                                <SingleDatePicker
                                  date={
                                    rule.maintenanceEndDate
                                      ? moment(
                                          rule.maintenanceEndDate.valueOf
                                            ? new Date(rule.maintenanceEndDate.valueOf())
                                            : rule.maintenanceEndDate,
                                        )
                                      : null
                                  }
                                  disabled={rule.status === 'Scheduled Maintenance'}
                                  onDateChange={(date: Moment | null) => {
                                    if (date) {
                                      const rules = this.state.rules;
                                      const rule = rules[index];
                                      if (rule.maintenanceEndTime) {
                                        rule.maintenanceEndDate = moment(date)
                                          .set({
                                            hour: parseInt(
                                              (rule.maintenanceEndTime as string).split(':')[0],
                                              10,
                                            ),
                                            minute: parseInt(
                                              (rule.maintenanceEndTime as string).split(':')[1],
                                              10,
                                            ),
                                          })
                                          .toDate();
                                      } else {
                                        rule.maintenanceEndDate = date.toDate();
                                      }
                                      rules[index] = rule;
                                      this.setState(
                                        { rules, hasChanged: true },
                                        this.repeatingDates,
                                      );
                                      this.updateRepeatOptions(index);
                                    }
                                  }}
                                  displayFormat="MMM D, yyyy"
                                  focused={rule.showDatePicker === 'end-date'}
                                  numberOfMonths={1}
                                  onFocusChange={({ focused }) => {
                                    const rules = this.state.rules;
                                    rules[index].maintenanceStartValidationMessage = '';
                                    rules[index].maintenanceEndValidationMessage = '';
                                    rules[index].showDatePicker = focused ? 'end-date' : '';

                                    this.setState({ rules, hasChanged: true }, this.repeatingDates);
                                  }}
                                  id={`maintenance-end-date-${index}`}
                                  hideKeyboardShortcutsPanel={true}
                                />
                              </div>
                            </div>
                            <div className="error-block maintenance-end-date-error">
                              {rule.maintenanceEndValidationMessage.indexOf('date') !== -1
                                ? rule.maintenanceEndValidationMessage
                                : ''}
                            </div>
                          </div>
                          <div
                            className="float-right field-parent-div"
                            style={{ width: 'calc(50% - 10px)', position: 'relative' }}
                          >
                            <Text className="modal-container-sub-heading" m={0} mb={2}>
                              {' '}
                              End Time
                            </Text>
                            <ChakraSelect
                              className={
                                'maintenance-end-time select-design ' +
                                (rule.status === 'Scheduled Maintenance' ? 'input-disabled' : '') +
                                (rule.maintenanceEndValidationMessage ? 'text-field-border ' : '')
                              }
                              value={this.state.rules[index].maintenanceEndTime}
                              onChange={event => {
                                const rules = this.state.rules;
                                const rule = rules[index];
                                rule.maintenanceEndTime = event.target.value;
                                if (rule.maintenanceEndDate) {
                                  rule.maintenanceEndDate = moment(rule.maintenanceEndDate)
                                    .set({
                                      hour: parseInt(
                                        (rule.maintenanceEndTime as string).split(':')[0],
                                        10,
                                      ),
                                      minute: parseInt(
                                        (rule.maintenanceEndTime as string).split(':')[1],
                                        10,
                                      ),
                                      second: 0,
                                      millisecond: 0,
                                    })
                                    .toDate();
                                }
                                rules[index] = rule;
                                this.setState({ rules, hasChanged: true }, this.repeatingDates);
                                this.updateRepeatOptions(index);
                              }}
                              width="95%"
                              onFocus={() => {
                                const rules = this.state.rules;
                                rules[index].maintenanceStartValidationMessage = '';
                                rules[index].maintenanceEndValidationMessage = '';
                                this.setState({ rules });
                              }}
                            >
                              <option value={''}>Choose Time</option>
                              {this.state.times.map((time, index) => {
                                return (
                                  <option
                                    disabled={
                                      rule.status === 'Scheduled Maintenance' ? true : false
                                    }
                                    key={index}
                                    value={time}
                                  >
                                    {time} (
                                    {(moment as any).tz((moment as any).tz.guess()).format('z')})
                                  </option>
                                );
                              })}
                            </ChakraSelect>
                            <div className="error-block maintenance-end-time-error">
                              {rule.maintenanceEndValidationMessage.indexOf('time') !== -1
                                ? rule.maintenanceEndValidationMessage
                                : ''}
                            </div>
                          </div>
                        </div>

                        <div style={{ width: '100%', display: 'flex' }}>
                          <div
                            className="float-left field-parent-div"
                            style={{ width: '100%', position: 'relative', paddingBottom: '15px' }}
                          >
                            <Text
                              m={0}
                              mb={3}
                              textTransform="none"
                              className="modal-container-sub-heading"
                            >
                              {' '}
                              Repeats every <span className="red-color-text">*</span>
                            </Text>
                            <div
                              className="float-left"
                              id={index.toString()}
                              style={{ width: '100%', display: 'flex', height: '50px' }}
                            >
                              <Select
                                styles={{
                                  input: (provided: any) => ({
                                    ...provided,
                                    width: '100%',
                                  }),
                                  option: (provided: any) => ({ ...provided, width: '100%' }),
                                  container: (provided: any) => ({
                                    ...provided,
                                    width: '97%',
                                    height: '100%',
                                  }),
                                  control: (provided: any) => ({ ...provided, height: '100%' }),
                                }}
                                value={this.state.rules[index].repeatsEvery}
                                options={this.state.rules[index].repeatOptions}
                                onChange={newValue =>
                                  this.repeatTimeHandler(index, newValue?.value, true)
                                }
                              ></Select>
                            </div>
                          </div>
                        </div>

                        {this.state.rules[index].maintenanceEndDate &&
                          this.state.rules[index].maintenanceEndTime && (
                            <Box className="">
                              <Box w={'calc(50% - 10px)'} pos={'relative'}>
                                <Text
                                  m={0}
                                  mb={3}
                                  textTransform="none"
                                  className="modal-container-sub-heading"
                                >
                                  Repeat till
                                </Text>
                                <div className="maintenance-modal-date-picker-wrapper">
                                  <SingleDatePicker
                                    date={moment(
                                      rule.repeatTill
                                        ? rule.repeatTill.valueOf
                                          ? new Date(rule.repeatTill.valueOf())
                                          : rule.repeatTill
                                        : new Date(),
                                    )}
                                    disabled={!rule.repeatButton}
                                    onDateChange={(date: Moment | null) => {
                                      if (date) {
                                        const rules = this.state.rules;
                                        rules[index].repeatTill = date
                                          .set({
                                            hour: parseInt(
                                              (rules[index].maintenanceEndTime as string).split(
                                                ':',
                                              )[0],
                                              10,
                                            ),
                                            minute: parseInt(
                                              (rules[index].maintenanceEndTime as string).split(
                                                ':',
                                              )[1],
                                              10,
                                            ),
                                            second: 0,
                                            millisecond: 0,
                                          })
                                          .toDate();
                                        this.setState(
                                          { rules, hasChanged: true },
                                          this.repeatingDates,
                                        );
                                      }
                                    }}
                                    displayFormat="MMM D, yyyy"
                                    focused={rule.showDatePicker === 'repeat-till'}
                                    numberOfMonths={1}
                                    onFocusChange={({ focused }) => {
                                      const rules = this.state.rules;
                                      rules[index].maintenanceStartValidationMessage = '';
                                      rules[index].maintenanceRepeatValidationMessage = '';
                                      rules[index].showDatePicker = focused ? 'repeat-till' : '';

                                      this.setState({ rules }, this.repeatingDates);
                                    }}
                                    id={`maintenance-repeat-till-${index}`}
                                    hideKeyboardShortcutsPanel={true}
                                  />
                                </div>
                                <div className="error-block maintenance-repeat-error">
                                  {rule.maintenanceRepeatValidationMessage.indexOf('date') !== -1
                                    ? rule.maintenanceRepeatValidationMessage
                                    : ''}
                                </div>
                              </Box>
                            </Box>
                          )}

                        <Box
                          pos={'absolute'}
                          right={'-25px'}
                          p={'12px'}
                          borderRadius={'50%'}
                          h={'50px'}
                          transform={'translateY(-50%)'}
                          top={'50%'}
                          zIndex={'1'}
                          cursor={'pointer'}
                          bgColor={'#fef5f6'}
                          border={'solid 1px #f04546'}
                        >
                          <Image
                            src="../../../../../../../icons/delete.svg"
                            onClick={this.deleteRule.bind(null, index)}
                            alt="delete"
                          />
                        </Box>
                        {rule.days && rule.days.length > 0 ? (
                          <Box>
                            <Text fontSize="large" m={0} mb={10}>
                              Upcoming Maintenance Schedule
                            </Text>
                          </Box>
                        ) : null}
                        <div
                          className="float-left"
                          style={
                            rule.days === undefined || rule.days.length === 0 || !rule.repetition
                              ? { display: 'none' }
                              : {}
                          }
                        >
                          <img
                            src="/icons/carousel-left.svg"
                            className="cursor-pointer repetition-left-arrow"
                            style={{ marginRight: 16 }}
                            onClick={() => {
                              this.scrollLeft(index.toString());
                            }}
                            alt="<-"
                          />
                        </div>

                        <div
                          className="scrollmenu"
                          ref={index.toString()}
                          style={{ scrollBehavior: 'smooth', overflowX: 'hidden' }}
                        >
                          {rule.repetition ? (
                            <div>
                              {rule.days !== undefined
                                ? rule.days.map((date: any, id: number) => {
                                    return (
                                      <Box cursor="default" key={id} display="table-cell" pr="15px">
                                        <div
                                          className="rectangle-repetition"
                                          onMouseOver={() => {
                                            const rules = this.state.rules;
                                            rules[index].showEditConfig = id;
                                            this.setState({ rules });
                                          }}
                                          onMouseLeave={() => {
                                            const rules = this.state.rules;
                                            rules[index].showEditConfig = null;
                                            this.setState({ rules });
                                          }}
                                        >
                                          <div className="rectangle-repetition-month">
                                            <div
                                              className="repetition-month-text"
                                              style={{ paddingTop: '8px', textAlign: 'center' }}
                                            >
                                              {date.start.format('MMM')}
                                            </div>
                                          </div>
                                          <Box
                                            h="24px"
                                            color="#0d2149"
                                            fontStyle="normal"
                                            fontWeight="normal"
                                            lineHeight="1.2"
                                            fontSize="20px"
                                            textAlign="center"
                                            p="10px"
                                          >
                                            <Box>{date.start.format('DD')}</Box>
                                          </Box>
                                          <Box
                                            h="14px"
                                            color="#0d2149"
                                            fontStyle="normal"
                                            fontWeight="normal"
                                            fontSize="12px"
                                            textAlign="center"
                                            p="10px"
                                          >
                                            <Box className="repetition-year-text">
                                              {date.start.format('YYYY')}
                                            </Box>
                                          </Box>
                                        </div>
                                      </Box>
                                    );
                                  })
                                : null}
                            </div>
                          ) : null}
                        </div>
                        <div
                          className="float-right "
                          style={
                            rule.days === undefined || rule.days.length === 0 || !rule.repetition
                              ? { display: 'none' }
                              : {}
                          }
                        >
                          <img
                            src="/icons/carousel-right.svg"
                            className="cursor-pointer repetition-right-arrow"
                            onClick={() => {
                              this.scrollRight(index.toString());
                            }}
                            alt="->"
                          />
                        </div>

                        {rule.showEditConfig !== null ? (
                          <div
                            className="config-modal-repetition animated fadeIn "
                            style={{ width: 190 }}
                          >
                            <div className=" no-select cursor-pointer">
                              <div className="float-left">
                                <Text className="item-box-tagline" m={0}>
                                  Start:{' '}
                                  {moment(rule.days![rule.showEditConfig as number].start).format(
                                    'DD/MM/YYYY : hh:mm:ss',
                                  )}
                                </Text>
                              </div>
                            </div>
                            <div className=" no-select cursor-pointer">
                              <div className="float-left">
                                <Text className="item-box-tagline" m={0}>
                                  End:{' '}
                                  {moment(rule.days![rule.showEditConfig as number].end).format(
                                    'DD/MM/YYYY : hh:mm:ss',
                                  )}
                                </Text>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </Box>
                    </Box>
                  );
                })}

                <Text mb={10} color="blue.600" cursor={'pointer'} onClick={this.addNewRule}>
                  Add {this.state.rules.length ? 'New' : 'a'} Maintenance Window
                </Text>
              </Box>
            ) : (
              <IllustrationBox
                width="200px"
                height="150px"
                image={MaintenanceIllustration}
                name="maintenance"
                msg="Enable maintenance for the selected service(s)"
              />
            )}

            {
              <Box mt={5}>
                <Button
                  variant={'default'}
                  disabled={this.isSaveButtonDisabled()}
                  onClick={this.save}
                >
                  Save
                </Button>
              </Box>
            }
          </Box>
        )}
      </div>
    );
  }
}
export default connect((state: IAppState) => ({
  organization: state.organization,
  userInfo: state.userInfo,
}))(withRouter(MaintenanceModal));
