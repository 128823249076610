export const env = {
  VITE_INTERCOM_KEY: 'lqtvxnd7',
  VITE_LOGROCKET_KEY: '9k2x4d/squadcasthq',
  VITE_MIXPANEL_KEY: 'eb1910ac575afaa4c398911e7f1241b0',
  VITE_MS_TEAMS_KEY: '11cdec78-0afe-453e-ad3e-d8dcc2c0ef98',
  VITE_ONESIGNAL_KEY: 'fb5ce362-0cd6-4178-a561-67e42b5ab87f',
  VITE_SLACK_KEY: '195334081766.687457268359',
  VITE_SLACK_KEY_V2: '195334081766.687457268359',
  //VITE_SLACK_KEY_V2: '4364456769781.4774982149746',
  VITE_USERFLOW_KEY: 'ct_ppy7ypd6vfbxtfto2bauedfihe',
  VITE_PUSHER_KEY: '3f73ce9e66324c6a3a64',

  VITE_INCIDENTS_URL: 'incidents.squadcast.com',
  VITE_GER_URL: 'global-events.squadcast.xyz',
  VITE_STATUS_PAGE_URL: 'status.squadcast.tech',

  VITE_BILLING_URL: 'https://squadcast.chargebeeportal.com/',
  VITE_MS_TEAMS_BOT_REAUTHORIZATION_URL:
    'https://login.microsoftonline.com/common/oauth2/authorize?response_type=code&client_id=7f900f38-8dc4-42c9-a341-7240939bcab7&redirect_uri=https://app.squadcast.xyz/settings/extension',

  VITE_US_URL: 'https://app.squadcast.com',
  VITE_EU_URL: 'https://app.eu.squadcast.com',

  // overrides
  VITE_SENTRY_KEY: '',

  VITE_CUBE_URL: 'https://analytics.squadcast.xyz',
  VITE_URL: 'https://app.squadcast.xyz',
  VITE_API_URL: 'https://api.squadcast.xyz',
  VITE_INTERNAL_API_URL: 'https://internal-api.squadcast.xyz',
  VITE_PLATFORM_BACKEND_URL: 'https://platform-backend.squadcast.xyz',
  VITE_SSO_AUTH_URL: 'https://auth.squadcast.xyz',
  VITE_WEBFORM_URL: 'webforms.squadcast.xyz',
  VITE_WEBFORM_CNAME: 'webforms.squadcast.xyz',
  VITE_PUSHER_HOST: 'websocket.squadcast.xyz',
  VITE_STATUS_PAGE_HOST: 'https://statuspage.squadcast.xyz',
  VITE_UNLEASH_URL: 'https://unleash.squadcast.xyz',
  VITE_UNLEASH_TOKEN: '*:production.e7457efeb68101d0c302d0d96bbe0b1d19b1e290a14fc512653b7f19',

  VITE_ENV: 'internal',
};
