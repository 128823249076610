import { AddRunbookIcon } from 'icons';
import { THEME_COLORS } from 'library/theme/colors';
import React from 'react';
import { DialogModalFrame, Grid, Para, TextButton, Theme } from 'uie/components';

import { RunbookAttachMode } from '.';
import { IRunbook, ISuggestedRunbook } from '../../../../../../../core/interfaces/IRunBook';
import RenderTable from '../addTaskOrRunbookTable';
import SuggestedRunbookTable from './SuggestedRunbookTable';
import { Button } from '@chakra-ui/react';

interface ImodalBtnProps {
  onCancel: () => void;
  onClickFn: () => void;
  btnTxt: string;
  isDisabled: boolean;
  newButtonStyles?: boolean;
}

export const ModalBtns = ({
  onCancel,
  onClickFn,
  isDisabled,
  btnTxt,
  newButtonStyles: newButtons,
}: ImodalBtnProps) => {
  if (newButtons) {
    return (
      <Grid justifyContent="flex-end" alignContent="flex-end" style={{ paddingTop: '15px' }}>
        {/**eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore */}
        <Button
          variant="outline"
          color={THEME_COLORS.brand.blue}
          borderColor={THEME_COLORS.primary[500]}
          borderRadius="3px"
          mr={3}
          onClick={onCancel}
          disabled={false}
        >
          Cancel
        </Button>
        <Button
          bg={THEME_COLORS.brand.blue}
          onClick={onClickFn}
          borderRadius="3px"
          height="32px"
          disabled={isDisabled}
        >
          {btnTxt}
        </Button>
      </Grid>
    );
  }

  return (
    <Grid justifyContent="flex-end" alignContent="flex-end" style={{ paddingTop: '15px' }}>
      <TextButton
        buttonType="ghost"
        style={{ padding: '1em' }}
        className=" mr-5"
        onClick={onCancel}
        disabled={false}
      >
        Cancel
      </TextButton>
      <TextButton
        buttonType="normal"
        style={{ color: 'white', padding: '1em' }}
        className=" mr-5"
        onClick={onClickFn}
        disabled={isDisabled}
        color={Theme.theme.primary.default}
      >
        {btnTxt}
      </TextButton>
    </Grid>
  );
};

interface IProps_ {
  incidentId: string;
  setEnableModal: (enableModal: boolean) => void;
  selectedRunbooksList: Array<IRunbook>;
  setSelectedRunbooksList: (list: Array<IRunbook>) => void;
  AddRunbook: () => void;
  enableRunbookModal: boolean;
  organizationrunbookList: IRunbook[];
  suggestedRunbookList: ISuggestedRunbook[];
  isSuggestedMode?: boolean;
  setRunbookAttachMode: React.Dispatch<React.SetStateAction<RunbookAttachMode>>;
}

const AddRunbookModal = ({ ...props }: IProps_) => {
  const onCancel = () => {
    props.setEnableModal(false);
  };

  const onClickFn = () => {
    props.AddRunbook();
  };

  const isDisabled = !props.selectedRunbooksList.length;

  const suggestedModeAndNoSuggestedRunbooks =
    props.isSuggestedMode && props.suggestedRunbookList?.length === 0;

  return (
    <DialogModalFrame
      id="incident_details__description_confirm_resolve_reassign_modal"
      onClose={() => {
        props.setEnableModal(false);
      }}
      padding="16px"
      width="70vw"
    >
      {props.enableRunbookModal && !suggestedModeAndNoSuggestedRunbooks && (
        <>
          <Grid type="column" style={{ padding: '15px' }}>
            <div className="item-box-icon-heading pr-20 mb-10">
              <AddRunbookIcon />
              <Para style={{ paddingLeft: '8px' }} fontWeight={400} fontSize={24}>
                {props.isSuggestedMode
                  ? `Suggested Runbook${props.suggestedRunbookList.length > 1 ? 's' : ''}`
                  : 'Attach Runbook'}
              </Para>
            </div>
            <div className="mb-10">
              {props.isSuggestedMode ? (
                <SuggestedRunbookTable
                  suggestedRunbookList={props.suggestedRunbookList}
                  incidentId={props.incidentId}
                  selectedRunbooksList={props.selectedRunbooksList}
                  setSelectedRunbooksList={(runbook: IRunbook[]) =>
                    props.setSelectedRunbooksList(runbook)
                  }
                />
              ) : (
                <RenderTable
                  runbook={props.organizationrunbookList}
                  selectedRunbooksList={props.selectedRunbooksList}
                  setSelectedRunbooksList={(runbook: IRunbook[]) =>
                    props.setSelectedRunbooksList(runbook)
                  }
                />
              )}
            </div>
            <ModalBtns
              onCancel={onCancel}
              onClickFn={onClickFn}
              isDisabled={isDisabled}
              btnTxt={props.isSuggestedMode ? 'Attach' : 'Add Runbooks'}
              newButtonStyles={props.isSuggestedMode}
            />
          </Grid>
        </>
      )}
    </DialogModalFrame>
  );
};
export default AddRunbookModal;
