import { HStack, Tooltip, VStack } from '@chakra-ui/react';
import {
  AppFrame,
  ErrorBlock,
  FocusBlock,
  Grid,
  Heading,
  IconButton,
  InputBlock,
  Label,
  MdBlock,
  Para,
  PulseLoader,
  SelectBox,
  TextButton,
  Theme,
} from 'uie/components';
import DOMPurify from 'core/dompurify';
import FileUploadService, {
  FileUploadFeature,
  OperationType,
} from 'core/services/service.fileUpload';
import { fileUploadViewConfig } from 'views/config/fileUploadConfig';
import { PrioritySelectBox } from 'views/shared/components';

import { CreateIncident } from '.';
import UnsavedChangesGuard from '../../../../../components/unsavedChangesGuard';
import { AddIcon, CloseIcon, EscalationIcon, SquadIcon, UserIcon } from '../../../../../icons';
import TagGroup from '../../incidentDetails/renders/updateTags/tagGroup';
import CreateIncidentWrapper from './wrapper';

const { theme } = Theme;
const { imageTexts, ...fileUploadConfig } = fileUploadViewConfig;

const Icons: any = {
  user: <UserIcon />,
  squad: <SquadIcon />,
  escalation: <EscalationIcon />,
};

export default function render(this: CreateIncident) {
  const {
    title,
    description,
    serviceId,
    serviceSearch,
    assign,
    assignSearch,
    componentState,
    tags,
    errors,
    priority,
  } = this.state;
  return (
    <AppFrame>
      <CreateIncidentWrapper>
        {createIncident => (
          <div style={{ padding: '16px 20px' }}>
            <div className="c-header">
              <Grid justifyContent="space-between" alignItems="center">
                <Heading height={35} fontSize={24}>
                  Create new incident
                </Heading>
                <IconButton onClick={this.onCancel}>
                  <CloseIcon fill={theme.shades.grey} />
                </IconButton>
              </Grid>
            </div>
            <div className="c-contents">
              <div>
                <Grid flexWidth={12} alignItems="flex-start">
                  <Grid flexWidth={4} alignItems="center" className="mt-10">
                    <Label htmlFor="incident_title">Incident Title</Label>
                  </Grid>
                  <Grid flexWidth={9} type="column">
                    <InputBlock
                      id="incident_title"
                      placeholder="Incident Title"
                      value={title}
                      onChange={this.onTextChange('title')}
                      error={!!errors.title}
                    />
                    {errors.title && <ErrorBlock>{errors.title}</ErrorBlock>}
                  </Grid>
                </Grid>
                <Grid flexWidth={12} className="mt-20" alignItems="flex-start">
                  <Grid flexWidth={4} alignItems="center" className="mt-10">
                    <Label htmlFor="incident_description">Incident Description (Optional)</Label>
                  </Grid>
                  <Grid flexWidth={9} type="column">
                    <MdBlock
                      id="incident_description"
                      onChange={this.onDescriptionChange}
                      value={description}
                      options={{
                        spellChecker: true,
                        maxHeight: '144px',
                        placeholder: 'Incident description (supports markdown)',
                        imageUploadFunction: this.fileUploadService.getUploadFunctionForFeature(
                          FileUploadFeature.INCIDENT,
                          OperationType.CREATE,
                        ),
                        imageTexts: {
                          ...imageTexts,
                          sbInit: '',
                        },
                        ...fileUploadConfig,
                        renderImageUploadsAsLinks: false,
                        renderingConfig: {
                          sanitizerFunction: description => {
                            return DOMPurify.sanitize(description);
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid flexWidth={12} className="mt-20" alignItems="flex-start">
                  <Grid flexWidth={4} alignItems="center" className="mt-10">
                    <Label htmlFor="incident_priority">Priority</Label>
                  </Grid>
                  <Grid flexWidth={9} type="column">
                    <PrioritySelectBox
                      priority={priority}
                      onChange={val => {
                        this.setState({ priority: val });
                      }}
                      disablePermissionCheck
                    />
                  </Grid>
                </Grid>
                <Grid flexWidth={12} className="mt-20" alignItems="flex-start">
                  <Grid flexWidth={4} alignItems="center" className="mt-10">
                    <Label htmlFor="incident_service">Service</Label>
                  </Grid>
                  <Grid flexWidth={9} type="column">
                    <SelectBox
                      id="incident_service"
                      hook={
                        serviceId.length > 0 ? (
                          <Para fontWeight={500}>
                            {this.oService[serviceId]?.name ?? 'Select Service'}
                          </Para>
                        ) : (
                          <Para color={theme.shades.grey}>Select Service</Para>
                        )
                      }
                      searchHookProps={{
                        placeholder: 'Search services',
                        value: serviceSearch,
                        onChange: this.onTextChange('serviceSearch'),
                        height: '24px',
                        fontSize: '16px',
                      }}
                      onValueChange={this.onSelectChange}
                      height="auto"
                      maxHeight="200px"
                      width="100%"
                      maxWidth="100%"
                      error={!!errors.serviceId}
                    >
                      {Object.values(this.oService)
                        .filter((s: any) =>
                          serviceSearch
                            ? s.name.toLowerCase().includes(serviceSearch.toLocaleLowerCase())
                            : true,
                        )
                        .map((s: any, i: number) => (
                          <FocusBlock key={i} value={s._id} isSelected={s._id === serviceId}>
                            {s.name}
                          </FocusBlock>
                        ))}
                    </SelectBox>
                    {errors.serviceId && <ErrorBlock>{errors.serviceId}</ErrorBlock>}
                  </Grid>
                </Grid>
                <Grid flexWidth={12} className="mt-20" alignItems="flex-start">
                  <Grid flexWidth={4} alignItems="center" className="mt-10">
                    <Label id="incident_assign_to">Assign To</Label>
                  </Grid>
                  <Grid flexWidth={9}>
                    <Grid flexWidth={3}>
                      <SelectBox
                        hook={
                          <Grid alignItems="center">
                            <div className="c-icon-container">{Icons[assign.type as any]}</div>
                            <Para className="ml-10 c-to-caps" fontWeight={500}>
                              {assign.type}
                            </Para>
                          </Grid>
                        }
                        onValueChange={this.onAssignTypeChange}
                        height="auto"
                        maxHeight="200px"
                        width="100%"
                        maxWidth="100%"
                        role="button"
                      >
                        {['user', 'squad', 'escalation'].map((t, i: number) => (
                          <FocusBlock key={i} value={t}>
                            <Grid alignItems="center">
                              <div className="c-icon-container">{Icons[t as any]}</div>
                              <Para className="ml-20 c-to-caps" fontWeight={500}>
                                {t}
                              </Para>
                            </Grid>
                          </FocusBlock>
                        ))}
                      </SelectBox>
                    </Grid>
                    <Grid className="ml-20" flexWidth={9} type="column">
                      <SelectBox
                        id="incident_assign_to"
                        hook={
                          (assign.id?.length ?? 0) > 0 ? (
                            <>
                              {assign.type === 'user' ? (
                                <Tooltip
                                  label={
                                    (this.assignes[assign.type][assign.id]?.name.length > 28 ||
                                      this.assignes[assign.type][assign.id]?.username.length >
                                        28) && (
                                      <VStack
                                        p="6px"
                                        alignItems="flex-start"
                                        style={{ backgroundColor: '#F2F2F2' }}
                                      >
                                        <span
                                          style={{
                                            fontWeight: 'bold',
                                            maxWidth: '30ch',
                                          }}
                                        >
                                          {this.assignes[assign.type][assign.id]?.name}
                                        </span>
                                        {this.assignes[assign.type][assign.id]?.username && (
                                          <span
                                            style={{
                                              fontWeight: 'normal',
                                              maxWidth: '30ch',
                                            }}
                                          >
                                            {this.assignes[assign.type][assign.id]?.username}
                                          </span>
                                        )}
                                      </VStack>
                                    )
                                  }
                                  color="gray.700"
                                  fontWeight={500}
                                  hasArrow
                                >
                                  <HStack>
                                    {this.assignes[assign.type][assign.id]?.name ? (
                                      <>
                                        <span style={{ fontWeight: 500, fontSize: 16 }}>
                                          {this.assignes[assign.type][assign.id]?.name.slice(0, 28)}
                                          {this.assignes[assign.type][assign.id]?.name.length >
                                            28 && `...`}
                                        </span>
                                        {this.assignes[assign.type][assign.id]?.username && (
                                          <span
                                            style={{ fontWeight: 500, color: 'grey', fontSize: 16 }}
                                          >
                                            (
                                            {this.assignes[assign.type][assign.id]?.username.slice(
                                              0,
                                              28,
                                            )}
                                            {this.assignes[assign.type][assign.id]?.username
                                              .length > 28 && `...`}
                                            )
                                          </span>
                                        )}
                                      </>
                                    ) : (
                                      'Not available'
                                    )}
                                  </HStack>
                                </Tooltip>
                              ) : (
                                <Para fontWeight={500}>
                                  {this.assignes[assign.type][assign.id]?.name ?? 'Not available'}
                                </Para>
                              )}
                            </>
                          ) : (
                            <Para color={theme.shades.grey}>Select {assign.type}</Para>
                          )
                        }
                        searchHookProps={{
                          placeholder: `Search ${assign.type}`,
                          value: assignSearch,
                          onChange: this.onTextChange('assignSearch'),
                          height: '24px',
                          fontSize: '16px',
                        }}
                        onValueChange={this.onAssign}
                        height="auto"
                        maxHeight="200px"
                        width="100%"
                        maxWidth="100%"
                        role="button"
                        error={!!errors.assignId}
                      >
                        {Object.values(this.assignes[assign.type])
                          .filter((s: any) =>
                            assignSearch
                              ? s.name.toLowerCase().includes(assignSearch.toLocaleLowerCase())
                              : true,
                          )
                          .map((s: any, i: number) => {
                            return (
                              <FocusBlock key={i} value={s._id} isSelected={s._id === assign.id}>
                                {assign.type === 'user' ? (
                                  s?.name ? (
                                    <VStack
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                      }}
                                    >
                                      <span style={{ maxWidth: '67ch' }}>
                                        {s?.name.slice(0, 60)} {s?.name.length > 60 && `...`}
                                      </span>
                                      {s?.username && (
                                        <span
                                          style={{ maxWidth: '67ch', color: 'grey', float: 'left' }}
                                        >
                                          {s?.username.slice(0, 60)}{' '}
                                          {s?.username.length > 60 && `...`}
                                        </span>
                                      )}
                                    </VStack>
                                  ) : (
                                    `${assign.type} deleted`
                                  )
                                ) : (
                                  s?.name ?? `${assign.type} deleted`
                                )}
                              </FocusBlock>
                            );
                          })}
                      </SelectBox>
                      {errors.assignId && <ErrorBlock>{errors.assignId}</ErrorBlock>}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid flexWidth={12} className="mt-20" alignItems="flex-start">
                  <Grid flexWidth={4} alignItems="center" className="mt-20">
                    <Label htmlFor="incident_title">Tags (Optional)</Label>
                  </Grid>
                  <Grid flexWidth={9} type="column">
                    {tags.map((t, i) => (
                      <Grid key={i} className="mt-10">
                        <Grid flexWidth={10}>
                          <TagGroup
                            value={{ key: t.key, value: t.value }}
                            keysOptions={this.state.orgTags.keys}
                            valuesMap={this.state.orgTags.valuesMap}
                            handleChange={this.handleTagChange(i)}
                            errorMsg={errors[`tag_${i}`]}
                          />
                        </Grid>
                        <Grid className="ml-20" flexWidth={9}>
                          <Grid flexWidth={12} justifyContent="space-between" alignItems="center">
                            <Grid
                              className="ml-10"
                              width="150px"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              {['#FF0A49', '#E1A500', '#00A96B', '#0C93E3', '#7E7E7E'].map(
                                (c, j) => (
                                  <IconButton
                                    buttonType="inverted"
                                    key={j}
                                    base={t.color === c ? '25px' : '20px'}
                                    color={c}
                                    onClick={this.setTagValue('color', i, c)}
                                  />
                                ),
                              )}
                            </Grid>
                            <Grid
                              className="ml-10"
                              width="150px"
                              justifyContent="flex-end"
                              alignItems="center"
                            >
                              {i === tags.length - 1 ? (
                                <IconButton onClick={this.addTag} color={theme.primary.default}>
                                  <AddIcon />
                                </IconButton>
                              ) : (
                                <IconButton
                                  onClick={this.removeTag(i)}
                                  color={theme.danger.default}
                                >
                                  <CloseIcon color={theme.danger.default} />
                                </IconButton>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Grid
                  flexWidth={12}
                  className="mt-40 mb-20"
                  alignItems="center"
                  type="column"
                  style={{ marginTop: 100 }}
                >
                  <ErrorBlock>{errors.network || ''}</ErrorBlock>
                  <TextButton
                    disabled={componentState === 'busy'}
                    height="56px"
                    width="100%"
                    onClick={() => this.handleCreateIncident(createIncident)}
                  >
                    {componentState === 'busy' ? (
                      <PulseLoader color={theme.shades.white} />
                    ) : (
                      <Para fontWeight={500} color={theme.shades.white}>
                        Create new incident
                      </Para>
                    )}
                  </TextButton>
                  {componentState !== 'busy' && (
                    <TextButton
                      buttonType="ghost"
                      style={{ marginTop: 10 }}
                      onClick={this.onCancel}
                    >
                      <Para fontWeight={500}>Cancel</Para>
                    </TextButton>
                  )}
                </Grid>
              </div>
            </div>
            <div style={{ marginBottom: 150 }} />

            <UnsavedChangesGuard checkDirty={this.checkDirty} />
          </div>
        )}
      </CreateIncidentWrapper>
    </AppFrame>
  );
}
