import { IAppState } from 'core/interfaces/IAppState';
import { IPatchReqRunbook, IRunbookListItem, ITask } from 'core/interfaces/ITask';
import React, { ForwardedRef, MutableRefObject } from 'react';
import { Grid } from 'uie/components';

import TaskOrRunbookStep from '../taskOrRunbookStep';
import IncidentRunbook from './Runbook';

interface IProps {
  ref: React.RefObject<HTMLDivElement[]>;
  sidebarState: IAppState['APP_CONFIG']['sidebarState'];
  collapseRunbook: boolean;
  setRunbookCollapse: (collapse: boolean) => void;
  incidentrunbookList: IRunbookListItem[];
  enbleDelete: number;
  setEnableDelete: (deleteIndex: number) => void;
  setEnableDeleteModal: (enbleDelete: boolean) => void;
  setDeleteRunbookId: (id: string) => void;
  collapseRunbookStep: number[];
  setRunbookStepCollapse: (step: number[]) => void;
  updateRunbook: (runbookId: string, step: IPatchReqRunbook) => void;
  isSuppressedIncident: boolean;
}

interface IRunbookStepsProps {
  index: number;
  element: ITask;
  runbook: IRunbookListItem | null;
  updateRunbook: (runbookId: string, step: IPatchReqRunbook) => void;
  sidebarState: IAppState['APP_CONFIG']['sidebarState'];
  isSuppressedIncident: boolean;
  withoutCheckbox?: boolean;
}

export const RunbookSteps = ({
  index,
  element,
  runbook,
  updateRunbook,
  sidebarState,
  isSuppressedIncident,
  withoutCheckbox = false,
}: IRunbookStepsProps) => {
  const { completed, content } = element;
  const id = (runbook && runbook.id) || '';
  return (
    <>
      <TaskOrRunbookStep
        checkedValue={completed}
        content={content}
        update={() => {
          updateRunbook(id, {
            index,
            completed: !completed,
          });
        }}
        sideBarState={sidebarState}
        isSuppressedIncident={isSuppressedIncident}
        withoutCheckbox={withoutCheckbox}
      />
    </>
  );
};
function RunbookList(props: IProps, ref: ForwardedRef<HTMLDivElement[]>) {
  const collpaseValue = props.collapseRunbook;
  const incidentRunbookList = props.incidentrunbookList;
  const incidentDetailRunbookRef = ref as MutableRefObject<HTMLDivElement[]>;

  const pushRefs = (element: HTMLDivElement | null) => {
    if (element && !incidentDetailRunbookRef.current.includes(element)) {
      incidentDetailRunbookRef.current.push(element);
    }
  };

  const RunbookStepAccordinTitle = (RnbkEle: IRunbookListItem) => {
    if (RnbkEle) {
      return `${RnbkEle.name || 'Runbook'} (${
        RnbkEle.steps.filter(_ => _.completed).length || 0
      } /${RnbkEle.steps.length || 0})`;
    }
    return `Runbook (0/0)`;
  };

  return (
    <Grid flexWidth={12} width={'100%'} className="ml-10 mb-10">
      {collpaseValue && (
        <div className="mt-10 mb-10 ml-10" style={{ width: '100%' }}>
          {incidentRunbookList.map((RnbkEle, index) => (
            <IncidentRunbook
              key={index}
              ref={pushRefs}
              title={RunbookStepAccordinTitle(RnbkEle)}
              collapse={props.collapseRunbookStep.includes(index)}
              steps={RnbkEle.steps}
              onTitleClick={() => {
                if (props.collapseRunbookStep.includes(index))
                  props.setRunbookStepCollapse(props.collapseRunbookStep.filter(_ => _ !== index));
                else props.setRunbookStepCollapse([...props.collapseRunbookStep, index]);
              }}
              onMouseEnter={() => props.setEnableDelete(index)}
              onMouseLeave={() => props.setEnableDelete(-1)}
              enableDelete={props.enbleDelete}
              setEnableDeleteModal={props.setEnableDeleteModal}
              setDeleteRunbookId={props.setDeleteRunbookId}
              isSuppressedIncident={props.isSuppressedIncident}
              index={index}
              runbook={RnbkEle}
              updateRunbook={props.updateRunbook}
              sidebarState={props.sidebarState}
            />
          ))}
        </div>
      )}
    </Grid>
  );
}

export default React.forwardRef(RunbookList);
