import { UseMutationResult } from 'react-query';
import { CreateIncidentReq, Exact } from '../../incident-list/graphql/generated/types';
import {
  CreateIncidentMutation,
  useCreateIncidentMutation,
} from '../../incident-list/graphql/mutation';

export type TCreateIncident = UseMutationResult<
  CreateIncidentMutation,
  unknown,
  Exact<{
    input: CreateIncidentReq;
  }>,
  unknown
>;

const CreateIncidentWrapper = ({
  children,
}: {
  children: (createIncident: TCreateIncident) => React.ReactNode;
}) => {
  const mutation = useCreateIncidentMutation();

  return <>{children(mutation)}</>;
};

export default CreateIncidentWrapper;
