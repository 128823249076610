import { ChakraProvider } from '@chakra-ui/react';
import {
  Checkbox,
  CopyIconButton,
  ErrorBlock,
  FocusBlock,
  Grid,
  IconButton,
  InputBlock,
  Label,
  MdBlock,
  Para,
  SelectBox,
  SpinLoader,
  SwitchBlock,
  TextButton,
  Theme,
  DropDown,
  DialogModalFrame,
} from 'uie/components';
import { deepCopy, findGracefully } from 'core/helpers';
import { FileUploadFeature, OperationType } from 'core/services/service.fileUpload';
import { UserAccessContextValue, withUserAccess } from 'core/userAccess/UserAccessContext';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';
import libraryTheme from 'library/theme';
import moment from 'moment';
import mustache from 'mustache';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';
import breaks from 'remark-breaks';
import gfm from 'remark-gfm';
import { fileUploadViewConfig } from 'views/config/fileUploadConfig';
import * as constant from 'views/main/organization/incident-list/filters/manager/constant';
import {
  T_WA_GS_POSTMORTEM_CREATED_WEB_V1,
  T_WA_GS_POSTMORTEM_DOWNLOADED_WEB_V1,
} from '../../../../../../core/const/tracker';
import { exception } from '../../../../../../core/exception';
import { IAppState } from '../../../../../../core/interfaces/IAppState';
import { IComponentErrorState } from '../../../../../../core/interfaces/IComponentState';
import { IIncident } from '../../../../../../core/interfaces/IIncidents';
import { IOrganization } from '../../../../../../core/interfaces/IOrganization';
import {
  IPostmortem,
  IPostmortemStatus,
  IPostmortemTemplate,
} from '../../../../../../core/interfaces/IPostmortems';
import {
  FileUploadService,
  PostmortemService,
  PostmortemTemplateService,
} from '../../../../../../core/services';
import { DeleteIcon } from '../../../../../../icons';
import { AppTracker } from '../../../../../../shared/analytics/tracker';
import * as helpers from './helpers';
import './index.css';
import { SelectPostMortemTemplate } from './select-postmortem-template';
import { Modal } from 'library/molecules';
import { Text } from 'library/atoms/Text';
import { FormButton } from 'library/atoms';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { checkIfStorageHost } from 'core/util/checkStorageHost';

const { theme } = Theme;

const CustomCodeRenderer = (props: any) => {
  return (
    <div className="incident_details__code-container">
      <pre className="incident_details__raw-code">{props.value}</pre>
      <div className="incident_details__code-copy">
        <CopyIconButton type="transparent">{props.value}</CopyIconButton>
      </div>
    </div>
  );
};

interface IProps extends Pick<IAppState, 'organization' | 'userInfo' | 'integrations'> {
  selectedOrg: IOrganization;
  incidentId: string;
  incident: IIncident;
  postmortem: IPostmortem | null;
  hideModal: () => void;
  getPostmortem: () => void;
  isInEdit?: boolean;
  onRefreshTable?: () => void;
  isModalDirty: boolean;
  setModalIsDirty: (isDirty: boolean) => void;
  onSaveUpdate: () => void;
  userAccess: UserAccessContextValue;
}

interface IState {
  postmortemTemplates: IPostmortemTemplate[];
  selectedPostmortemTemplate: IPostmortemTemplate | null;
  PTSearch: string;
  loading:
    | 'get_templates'
    | 'creating_postmortem'
    | 'updating'
    | 'deleting'
    | 'downloading_pdf'
    | 'downloading_md'
    | '';
  viewMode: 'View' | 'Edit';
  variables: { [key: string]: any };
  postmortem: IPostmortem;
  squadcastLink: string;
  showPreviewModal: boolean;
  errors: IComponentErrorState;
}

const { imageTexts, ...fileUploadConfig } = fileUploadViewConfig;

export class PostmortemModal extends React.Component<IProps, IState> {
  public _PTMService = new PostmortemService(this.props.incidentId);
  private _PTMTService = new PostmortemTemplateService(this.props.selectedOrg.organizationId);
  public fileUploadService = new FileUploadService();
  private modalTitle = 'View ';

  public _serviceFind = findGracefully(this.props.organization.services.s, 'id', 'Service Deleted');

  constructor(props: IProps) {
    super(props);

    this.state = {
      postmortemTemplates: [],
      selectedPostmortemTemplate: null,
      loading: '',
      PTSearch: '',
      viewMode: props.isInEdit ? 'Edit' : 'View',
      variables: {},
      postmortem: deepCopy(
        this.props.postmortem
          ? this.props.postmortem
          : {
              postmortem: '',
              follow_ups: [],
              attachments: [],
              title: this.props.incident.message,
            },
      ),
      errors: {},
      showPreviewModal: false,
      squadcastLink: '',
    };
  }

  public componentDidMount() {
    if (!this.props.postmortem) this.getPostmortemTemplates();
    this.defineVariables();
  }

  public getPostmortemTemplates = async () => {
    this.setState({ loading: 'get_templates' });
    try {
      const response = await this._PTMTService.get();

      if (response.data.data.length === 0) {
        this.setState({
          postmortemTemplates: [],
          selectedPostmortemTemplate: null,
        });
      } else {
        const postmortemTemplates: IPostmortemTemplate[] = response.data.data;
        const selectedPostmortemTemplate = postmortemTemplates.find(
          x => x.is_default,
        ) as IPostmortemTemplate;

        this.setState({ postmortemTemplates, loading: '' });
        this.selectPostmortemTemplate(selectedPostmortemTemplate);
      }
    } catch (err: any) {
      exception.handle('E_GET_POSTMORTEM_TEMPLATES', err);
      this.setState({ loading: '' });
    }
  };

  onTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!this.props.isModalDirty) this.props.setModalIsDirty(true);
    this.setState(({ postmortem }) => {
      postmortem.title = event.target.value;
      return { postmortem };
    });
  };

  public selectPostmortemTemplate = (selectedPostmortemTemplate: IPostmortemTemplate) => {
    this.setState(({ postmortem }) => {
      postmortem.postmortem = this.substituteVariables(selectedPostmortemTemplate.template);
      return { postmortem, selectedPostmortemTemplate };
    });
  };

  public handleSelectPostmortemTemplate = (_: any, pt: IPostmortemTemplate) => {
    this.selectPostmortemTemplate(pt);
  };

  public handleViewModeChange = (mode: string) => {
    if (this.modalTitle === 'View ') this.modalTitle = 'Edit ';
    this.setState({ viewMode: mode as 'View' | 'Edit' });
  };

  public handlePostmortemStatusChange = (status: IPostmortemStatus) => {
    if (!this.props.isModalDirty) this.props.setModalIsDirty(true);
    this.setState(({ postmortem }) => {
      postmortem.status = status;
      return { postmortem };
    });
  };

  public substituteVariables = (template: string) => {
    try {
      // Escape HTML encoding for markdown
      const newTemplate = template
        .replace(/{{[\s]*description[\s]*}}/g, '{{{description}}}')
        .replace(/{{[\s]*message[\s]*}}/g, '{{{message}}}')
        .replace(/{{[\s]*priority*}}/g, '{{{priority}}}')
        .replace(/{{[\s]*timeline_event_detail*}}/g, '{{{timeline_event_detail}}}')
        .replace(/{{[\s]*timeline_event_title*}}/g, '{{{timeline_event_title}}}');
      return mustache.render(newTemplate, this.state.variables);
    } catch (_) {
      return template;
    }
  };

  public handlePostmortemContentChange = (content: string) => {
    if (!this.props.isModalDirty) this.props.setModalIsDirty(true);
    this.setState(({ postmortem }) => {
      postmortem.postmortem = content;
      return { postmortem };
    });
  };

  public validateFollowUpTasks = () => {
    const errors: IComponentErrorState = {};
    let isError = false;

    this.state.postmortem.follow_ups?.forEach((followUp, idx) => {
      if (followUp.task.trim() === '') {
        errors[`e_${idx}_follow_up`] = 'task';
        isError = true;
      }
    });

    if (isError) errors.message = 'Please fill all the follow up fields';

    this.setState({ errors });
    return !isError;
  };

  public createPostmortem = async () => {
    if (!this.validateFollowUpTasks()) return;

    try {
      const newPostmortem = this.state.postmortem;
      this.setState({ loading: 'creating_postmortem' });
      newPostmortem.attachments = this.fileUploadService.accessAttachmentArrayForPostmortem();
      await this._PTMService.create(newPostmortem);
      this.fileUploadService.emptyAttachmentArrayForPostmortem();
      this.props.onSaveUpdate();
      this.props.getPostmortem();
    } catch (error: any) {
      this.setState({
        loading: '',
        errors: { message: error?.response?.data?.meta?.error_message ?? 'Network Error' },
      });
    }
    AppTracker.track(T_WA_GS_POSTMORTEM_CREATED_WEB_V1, {
      'Follow Ups': this.state.postmortem?.follow_ups
        ? this.state.postmortem?.follow_ups?.length > 0
        : false,
    });
  };

  public addFollowUp = () => {
    if (!this.props.isModalDirty) this.props.setModalIsDirty(true);
    this.setState(({ postmortem }) => {
      postmortem.follow_ups?.push({
        task: '',
        is_done: false,
      });
      return { postmortem };
    });
  };

  public deleteFollowUp = (index: number) => () => {
    if (!this.props.isModalDirty) {
      this.props.setModalIsDirty(true);
    }
    this.setState(({ postmortem }) => {
      postmortem.follow_ups?.splice(index, 1);
      return { postmortem };
    });
  };

  public updateFollowUp =
    (index: number, name: 'task' | 'is_done') => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!this.props.isModalDirty) {
        this.props.setModalIsDirty(true);
      }

      if (name === 'task') {
        const value = event.target.value;

        this.setState(({ postmortem }) => {
          if (postmortem.follow_ups) {
            postmortem.follow_ups[index].task = value;
          }
          return { postmortem };
        });
      } else if (name === 'is_done') {
        const checked = event.target.checked;
        this.setState(({ postmortem }) => {
          if (postmortem.follow_ups) {
            postmortem.follow_ups[index].is_done = checked;
          }
          return { postmortem };
        });
      }
    };

  public downloadPostmortem = (type: 'pdf' | 'md') => async () => {
    try {
      type === 'pdf'
        ? this.setState({ loading: 'downloading_pdf' })
        : this.setState({ loading: 'downloading_md' });

      const fileName = `${moment(new Date()).format('YYYYMMDDHHmm')}-${
        this.props.selectedOrg.organizationName
      }-PM-${this.props.incidentId}.${type}`;

      let postmortemFile;

      if (type === 'pdf') {
        const response = await this._PTMService.download('pdf');

        const res = await fetch(`data:application/pdf;base64,${response.data}`);
        const buf = await res.arrayBuffer();
        postmortemFile = new File([buf], fileName, { type: 'application/pdf' });

        AppTracker.track(T_WA_GS_POSTMORTEM_DOWNLOADED_WEB_V1, { 'Postmortem Format': type });
        // type === `md`
      } else {
        const { postmortem } = this.state;
        const postmortemMD = postmortem.postmortem;

        const followUpsMD = postmortem.follow_ups?.reduce(
          (accumulatedValue, followUp) =>
            `${accumulatedValue} - [${followUp.is_done ? 'x' : ' '}] ${followUp.task}\n`,
          '',
        );

        const fileContent = `${postmortemMD}\n\n# Follow-Ups\n${followUpsMD}`;

        postmortemFile =
          typeof File === 'function'
            ? new File([fileContent], fileName, { type: '.md' })
            : new Blob([postmortem.postmortem], { type: '.md' });

        AppTracker.track(T_WA_GS_POSTMORTEM_DOWNLOADED_WEB_V1, { 'Postmortem Format': type });
      }

      const blobURL = window.URL.createObjectURL(postmortemFile);

      const hiddenElement = document.createElement('a');
      hiddenElement.href = blobURL;
      hiddenElement.target = '_blank';
      hiddenElement.download = fileName;
      hiddenElement.click();

      this.setState({ loading: '' });
    } catch (err: any) {
      exception.handle('E_DOWNLOAD_POSTMORTEM', err);
      this.setState({
        loading: '',
        errors: { message: 'Error while downloading file. Please try again' },
      });
    }
  };

  public defineVariables = helpers.defineVariables.bind(this);
  public updatePostmortem = helpers.updatePostmortem.bind(this);
  public deletePostmortem = helpers.deletePostmortem.bind(this);
  render() {
    const isUpdate = !!this.props.postmortem;
    const {
      postmortemTemplates,
      selectedPostmortemTemplate,
      loading,
      PTSearch,
      postmortem,
      errors,
    } = this.state;
    const { isModalDirty } = this.props;
    if (postmortemTemplates.length === 0 && loading === 'get_templates') {
      return (
        <Grid>
          <SpinLoader />
        </Grid>
      );
    }
    const userAccess = this.props.userAccess;
    const canCreate = userAccess.hasCreateAccess('postmortems');
    const canUpdate = userAccess.hasUpdateAccess('postmortems');
    const canDelete = userAccess.hasDeleteAccess('postmortems');

    const postmortemStatus = {
      label: this.state.postmortem.status
        ? constant.POSTMORTEM_STATUS_OPTIONS[this.state.postmortem.status].label
        : constant.POSTMORTEM_STATUS_OPTIONS[IPostmortemStatus.InProgress].label,
      Icon: this.state.postmortem.status
        ? constant.POSTMORTEM_STATUS_OPTIONS[this.state.postmortem.status].Icon
        : constant.POSTMORTEM_STATUS_OPTIONS[IPostmortemStatus.InProgress].Icon,
    };

    const handleLinkClick = (href: string) => {
      this.setState({
        showPreviewModal: true,
        squadcastLink: href,
      });
    };
    const closePreviewModal = () => {
      this.setState({
        showPreviewModal: false,
        squadcastLink: '',
      });
    };

    const renderMdLink = (props: any) => {
      if (checkIfStorageHost(props.href)) {
        return (
          <span
            onClick={() => handleLinkClick(props.href)}
            style={{ textDecoration: 'none', cursor: 'pointer', color: '#1b7ff1' }}
          >
            {props.children} <ExternalLinkIcon w={2.5} h={2.5} position={'relative'} top={-1.5} />
          </span>
        );
      }
      return (
        <a href={props.href} target="_blank" rel="noopener noreferrer">
          {props.children}
        </a>
      );
    };
    return (
      <ChakraProvider theme={libraryTheme}>
        <Grid type="column" style={{ position: 'relative' }}>
          <Para fontSize={24} fontWeight={500}>
            {isUpdate ? this.modalTitle : 'Create '}
            Postmortem
          </Para>

          <Grid type="column" className="mt-20">
            <SelectPostMortemTemplate
              loading={loading}
              show={!isUpdate}
              searchValue={PTSearch}
              selectedPostmortemTemplate={selectedPostmortemTemplate}
              handleSelectPostmortemTemplate={this.handleSelectPostmortemTemplate}
              postmortemTemplates={postmortemTemplates}
              onSearch={value =>
                this.setState({
                  PTSearch: value,
                })
              }
            />

            <Grid flexWidth={9} type="column" width="100%" className="mb-20">
              <Grid flexWidth={4} alignItems="center" className="mt-10">
                <Label htmlFor="postmortem_title" className="mb-10">
                  Postmortem Title
                </Label>
              </Grid>
              <InputBlock
                id="postmortem_title"
                placeholder="Postmortem Title"
                onChange={this.onTextChange}
                value={this.state.postmortem.title}
              />
            </Grid>

            <Grid className="mb-10" justifyContent="space-between">
              <Label
                fontWeight={400}
                fontSize={14}
                style={{ lineHeight: '20px', marginTop: 'auto' }}
              >
                Postmortem Details
              </Label>
              <Grid>
                <Grid>
                  <Label
                    fontWeight={400}
                    fontSize={14}
                    style={{ margin: 'auto', marginRight: '8px' }}
                  >
                    Status
                  </Label>
                </Grid>
                <Grid style={{ marginRight: '-10px' }}>
                  <SelectBox
                    hook={
                      <Para style={{ display: 'flex' }}>
                        <postmortemStatus.Icon style={{ marginRight: '10px' }} />
                        <Para fontSize={14}>{postmortemStatus.label}</Para>
                      </Para>
                    }
                    onValueChange={(_, status) => {
                      this.handlePostmortemStatusChange(status);
                    }}
                    height="auto"
                    maxHeight="200px"
                    buttonHeight="36px"
                    width="180px"
                  >
                    {Object.entries(constant.POSTMORTEM_STATUS_OPTIONS).map(([key, val], i) => {
                      return (
                        <FocusBlock
                          key={i}
                          value={key}
                          // isSelected={v === selectBoxValue.i}
                        >
                          <Para style={{ display: 'flex' }}>
                            <val.Icon style={{ marginRight: '10px' }} />
                            <Para fontSize={14}>{val.label}</Para>
                          </Para>
                        </FocusBlock>
                      );
                    })}
                  </SelectBox>
                </Grid>
                <Grid>
                  <SwitchBlock
                    options={['View', 'Edit']}
                    selected={[this.state.viewMode]}
                    className="ml-20"
                    onSelectValue={this.handleViewModeChange}
                  />
                </Grid>
              </Grid>
            </Grid>

            {this.state.viewMode === 'Edit' ? (
              <MdBlock
                value={postmortem.postmortem}
                onChange={this.handlePostmortemContentChange}
                options={{
                  spellChecker: false,
                  placeholder: 'Postmortem',
                  imageUploadFunction: this.fileUploadService.getUploadFunctionForFeature(
                    FileUploadFeature.POSTMORTEM,
                    isUpdate ? OperationType.UPDATE : OperationType.CREATE,
                    isUpdate ? this.state.postmortem.id : '',
                  ),
                  imageTexts: {
                    ...imageTexts,
                    sbInit: '',
                  },
                  ...fileUploadConfig,
                  renderImageUploadsAsLinks: false,
                }}
                maxHeight="400px"
                minHeight="200px"
                className="incidentDetails_postmortem__mdBlock"
              />
            ) : (
              <ReactMarkdown
                className="incidentDetails_postmortem__reactMD incident_details__mde preview_mde"
                plugins={[breaks, gfm]}
                renderers={{ link: renderMdLink, code: CustomCodeRenderer }}
                source={this.state.postmortem.postmortem}
              />
            )}

            <DialogModalFrame
              root="unsaved-changes-confirm"
              id="modalFrame"
              onClose={closePreviewModal}
              padding="20px"
              width="520px"
            >
              {this.state.showPreviewModal && (
                <>
                  <Text style={{ fontWeight: 'bold' }}>Caution !</Text>

                  <Text>Attachments are not scanned for virus or malware</Text>
                  <Grid
                    style={{ marginTop: 20, gap: 10, justifyContent: 'flex-end' }}
                    className="mr-20"
                  >
                    <FormButton
                      variant="secondary"
                      borderWidth={'1.5px'}
                      title="Cancel"
                      onClick={() => {
                        closePreviewModal();
                      }}
                    />
                    <FormButton
                      title="Download"
                      onClick={() => {
                        window.open(this.state.squadcastLink, '_blank');
                        closePreviewModal();
                      }}
                    />
                  </Grid>
                </>
              )}
            </DialogModalFrame>

            <Grid type="column" className="mt-20 mb-10">
              {postmortem?.follow_ups
                ? postmortem?.follow_ups.length > 0 && (
                    <Label fontWeight={400} fontSize={14} className="mb-10">
                      Follow Ups
                    </Label>
                  )
                : null}

              {postmortem?.follow_ups?.map((followUp, idx) => (
                <Grid key={idx} className="mb-20">
                  <Checkbox
                    className="mr-10"
                    style={{ marginTop: '6px' }}
                    onChange={this.updateFollowUp(idx, 'is_done')}
                    disabled={!(canCreate || canUpdate)}
                    checked={followUp.is_done}
                  />
                  <InputBlock
                    className="mr-10 ml-10"
                    height="24px"
                    placeholder="Follow Up Task"
                    error={!!this.state.errors[`e_${idx}_follow_up`]}
                    onChange={this.updateFollowUp(idx, 'task')}
                    disabled={!(canCreate || canUpdate)}
                    value={followUp.task}
                  />
                  <IconButton
                    className="ml-10"
                    disabled={!(canCreate || canUpdate)}
                    color={theme.danger.light}
                    onClick={this.deleteFollowUp(idx)}
                  >
                    <DeleteIcon stroke={theme.danger.default} style={{ width: '18px' }} />
                  </IconButton>
                </Grid>
              ))}

              <TextButton buttonType="ghost" onClick={this.addFollowUp}>
                <Para fontWeight={500} color={theme.primary.default}>
                  &#43; Add Follow Up
                </Para>
              </TextButton>
            </Grid>

            {errors.message && <ErrorBlock fontWeight={500}>{errors.message}</ErrorBlock>}

            {!isUpdate ? (
              <TextButton
                onClick={this.createPostmortem}
                style={{ padding: '8px 20px' }}
                className="mt-20"
                disabled={this.state.loading === 'creating_postmortem'}
              >
                <Para fontWeight={500} fontSize={18} color={theme.shades.white}>
                  Create
                </Para>
              </TextButton>
            ) : (
              <Grid justifyContent="space-between" className="mt-20">
                <Grid>
                  <NoPermissionTooltip isDisabled={canDelete}>
                    <TextButton
                      style={{ padding: '8px 20px' }}
                      buttonType="inverted"
                      color={theme.danger.default}
                      disabled={loading === 'deleting' || !canDelete}
                      onClick={this.deletePostmortem}
                    >
                      <Para fontWeight={500} fontSize={18} color={theme.danger.default}>
                        Delete
                      </Para>
                    </TextButton>
                  </NoPermissionTooltip>
                  {isModalDirty && (
                    <NoPermissionTooltip isDisabled={isUpdate ? canUpdate : canCreate}>
                      <TextButton
                        style={{ padding: '8px 20px' }}
                        className="ml-20"
                        disabled={loading === 'updating' || (isUpdate ? !canUpdate : !canCreate)}
                        onClick={this.updatePostmortem}
                      >
                        <Para fontWeight={500} fontSize={18} color={theme.shades.white}>
                          Save
                        </Para>
                      </TextButton>
                    </NoPermissionTooltip>
                  )}
                </Grid>
                <Grid>
                  <TextButton
                    style={{ padding: '8px 20px' }}
                    buttonType="inverted"
                    onClick={this.downloadPostmortem('md')}
                    disabled={loading === 'downloading_md'}
                    className="mr-20"
                  >
                    <Para fontWeight={500} fontSize={18} color={theme.primary.default}>
                      Download as MD
                    </Para>
                  </TextButton>
                  <TextButton
                    style={{ padding: '8px 20px' }}
                    onClick={this.downloadPostmortem('pdf')}
                    disabled={loading === 'downloading_pdf'}
                    buttonType="inverted"
                  >
                    <Para fontWeight={500} fontSize={18} color={theme.primary.default}>
                      Download as PDF
                    </Para>
                  </TextButton>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </ChakraProvider>
    );
  }
}

export default connect((state: IAppState) => ({
  organization: state.organization,
  userInfo: state.userInfo,
  integrations: state.integrations,
  selectedOrg: state.userOrganizations.o.find(
    o => o.organizationId === state.INIT_ORG.orgId,
  ) as IOrganization,
}))(withUserAccess(PostmortemModal));
