import { Divider, HStack } from '@chakra-ui/react';
import { IAppState } from 'core/interfaces/IAppState';
import { IPatchReqRunbook, IRunbookListItem } from 'core/interfaces/ITask';
import { THEME_COLORS } from 'library/theme/colors';
import React, { ForwardedRef, MutableRefObject } from 'react';
import { useSelector } from 'react-redux';
import { Accordion, Checkbox } from 'uie/components';

import { RunbookAccordinBody } from '../accordin/Accordin';
import { EditOrDeleteRunbook } from '../editAndDelete';
import { RunbookSteps } from './RunbookList';

interface IIncidentRunbookProps {
  ref?: (element: HTMLDivElement | null) => void;
  title: string;
  collapse?: boolean;
  uncontrolledCollapse?: boolean;
  steps?: Array<any>;
  onTitleClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  enableDelete?: number;
  hideDelete?: boolean;
  setEnableDeleteModal?: (value: boolean) => void;
  setDeleteRunbookId?: (value: string) => void;
  isSuppressedIncident?: boolean;
  index: number;
  runbook: IRunbookListItem;
  updateRunbook?: (runbookId: string, step: IPatchReqRunbook) => void;
  sidebarState: IAppState['APP_CONFIG']['sidebarState'];
  mode?: 'attach' | 'suggest';
  withCheckbox?: boolean;
  checked?: boolean;
  onChecked?: (checked: boolean) => void;
  withoutStepCheckboxes?: boolean;
  withoutRunbookIcon?: boolean;
  description?: string;
  withBottomDivider?: boolean;
}

const IncidentRunbook: React.FC<IIncidentRunbookProps> = ({
  ref,
  title,
  collapse,
  uncontrolledCollapse,
  steps = [],
  onTitleClick,
  onMouseEnter,
  onMouseLeave,
  enableDelete = 0,
  hideDelete = false,
  setEnableDeleteModal,
  setDeleteRunbookId,
  isSuppressedIncident,
  index,
  runbook,
  updateRunbook,
  mode = 'attach',
  withCheckbox = false,
  checked = false,
  onChecked = () => {},
  withoutRunbookIcon = false,
  description,
  withBottomDivider = false,
}) => {
  const [collapsed, setCollapsed] = React.useState(collapse || false);
  const appState = useSelector((state: IAppState) => state);

  const onClick = () => {
    if (uncontrolledCollapse) {
      setCollapsed(!collapsed);
    }

    onTitleClick?.();
  };

  return (
    <div className="incident_details_task_or_runbook mt-10 mb-10" ref={ref}>
      <Accordion
        title={
          <div
            className="incident_details__runbook__addTask_accordian-steps"
            onClick={e => {
              e.stopPropagation();
              onClick();
            }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <div>
              {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            @ts-ignore */}
              <HStack onClick={e => e.stopPropagation()}>
                {withCheckbox && (
                  <Checkbox
                    className="mr-20"
                    checked={checked}
                    onChange={e => {
                      e.stopPropagation();
                      if (onChecked) {
                        onChecked(e.target.checked);
                      }
                    }}
                    style={{ border: `2px solid ${THEME_COLORS.brand.blue}` }}
                  />
                )}

                <RunbookAccordinBody
                  collapse={uncontrolledCollapse ? collapsed : !collapse}
                  title={title}
                  withoutIcon={withoutRunbookIcon}
                  onClick={onClick}
                />
              </HStack>
            </div>
            {hideDelete ? (
              ''
            ) : (
              <EditOrDeleteRunbook
                enableDelete={enableDelete || 0}
                setEnableDeleteModal={setEnableDeleteModal || (() => {})}
                id={runbook.id}
                setDeleteRunbookId={setDeleteRunbookId || (() => {})}
                index={index}
                isSuppressedIncident={isSuppressedIncident || false}
              />
            )}
          </div>
        }
        tabProps={{
          style:
            !(uncontrolledCollapse ? collapsed : !collapse) && mode === 'suggest'
              ? {
                  backgroundColor: THEME_COLORS.brand.white,
                }
              : {},
        }}
      >
        {(uncontrolledCollapse ? collapsed : !collapse) ? (
          <div>
            {steps.map((step, index_) => (
              <div key={index_} className="incident_details_step">
                <RunbookSteps
                  index={index_}
                  element={step}
                  runbook={runbook}
                  updateRunbook={updateRunbook || (() => {})}
                  sidebarState={appState.APP_CONFIG.sidebarState}
                  isSuppressedIncident={isSuppressedIncident || false}
                  withoutCheckbox={mode === 'suggest' ? true : false}
                />
              </div>
            ))}
          </div>
        ) : null}
      </Accordion>
      {description && (
        <div
          style={{
            color: THEME_COLORS.secondary[700],
            fontSize: '12.5px',
            marginLeft: '16px',
            marginTop: (uncontrolledCollapse ? collapsed : !collapse) ? '8px' : '0px',
            transitionDuration: '300ms',
          }}
        >
          {description}
        </div>
      )}
      {withBottomDivider && (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Divider
          marginTop="24px"
          marginLeft="16px"
          width="calc(100% - 32px)"
          height="0px"
          color={THEME_COLORS.secondary[200]}
        />
      )}
    </div>
  );
};

export default IncidentRunbook;
