import React, { useState } from 'react';
import {
  AddRunbookIcon,
  AddTaskIcon,
  SuggestRunbookIcon,
  CrownIcon,
} from '../../../../../../icons';
import { IRunbook } from '../../../../../../core/interfaces/IRunBook';

import { Label, Grid } from 'uie/components';
import { IPatchReq } from '../../../../../../core/interfaces/ITask';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';
import { Button, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';
import { KebabIcon } from 'library/icons';
import { THEME_COLORS } from 'library/theme/colors';
import { RunbookAttachMode } from './runbook';
import { BillingService } from 'core';
import { useSelector } from 'react-redux';
import { IAppState } from 'core/interfaces/IAppState';
import UpgradePlanModal from 'components/upgradeplan.modal';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_GS_INCIDENT_SUGGESTIONS_SUGGEST_RUNBOOK_ATTEMPTED } from 'core/const/tracker';

interface IProps {
  setSelectedRunbooksList: (list: Array<IRunbook>) => void;
  setEnableRunbookModal: (enable: boolean) => void;
  isSuppressedIncident: boolean;
  setRunbookAttachMode: React.Dispatch<React.SetStateAction<RunbookAttachMode>>;
  hasRunbooksToSuggest: boolean;
  incidentId: string;
}
interface ITextButtonProps {
  setSelectedTask: (task: IPatchReq | null) => void;
  setEnableTaskModal: (enable: boolean) => void;
  isSuppressedIncident: boolean;
}
const RunbookButtons = ({
  setSelectedRunbooksList,
  setEnableRunbookModal,
  isSuppressedIncident,
  setRunbookAttachMode,
  hasRunbooksToSuggest,
  incidentId,
}: IProps) => {
  const state = useSelector((state: IAppState) => state);
  const hasUpdatePermission = useUserAccess().hasUpdateAccess('incidents');
  const canUseSuggestions = BillingService.getLimit(state, 'suggest-runbooks') === 'unlimited';
  const [showUpgradePlanModal, setShowUpgradePlanModal] = useState(false);

  const RunbookActionsMenuItem = (props: {
    icon?: React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
    onClick?: () => void;
    children?: React.ReactNode;
  }) => {
    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <MenuItem
        icon={props.icon}
        iconSpacing="16px"
        style={
          !hasUpdatePermission
            ? {
                opacity: 0.6,
                cursor: 'not-allowed',
                position: 'relative',
              }
            : { position: 'relative' }
        }
        onClick={() => {
          props.onClick?.();
        }}
      >
        <Text>{props.children}</Text>
      </MenuItem>
    );
  };

  const RunbookActionsMenuButton = (props: { onClick?: () => void }) => {
    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <Menu placement="bottom-end">
        {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore */}
        <MenuButton>
          <Button
            rightIcon={<KebabIcon />}
            onClick={() => {
              if (!hasUpdatePermission) return;
              props.onClick?.();
            }}
            variant="outline"
            color={THEME_COLORS.brand.blue}
            borderColor={THEME_COLORS.primary[500]}
            borderRadius="3px"
          >
            Add Runbook(s)
          </Button>
        </MenuButton>
        <MenuList borderRadius="3px">
          <RunbookActionsMenuItem
            icon={<AddRunbookIcon />}
            onClick={() => {
              setRunbookAttachMode('all');
              setSelectedRunbooksList([]);
              setEnableRunbookModal(true);
            }}
          >
            Attach Runbook
          </RunbookActionsMenuItem>
          <RunbookActionsMenuItem
            icon={<SuggestRunbookIcon style={{ marginRight: '-7px' }} />}
            onClick={() => {
              if (canUseSuggestions) {
                setRunbookAttachMode('suggested');
                setSelectedRunbooksList([]);
                setEnableRunbookModal(true);
                AppTracker.track(
                  T_WA_GS_INCIDENT_SUGGESTIONS_SUGGEST_RUNBOOK_ATTEMPTED,
                  {
                    'Incident ID': incidentId,
                    'Is Runbook Suggested': hasRunbooksToSuggest,
                  },
                  true,
                );
              } else {
                setShowUpgradePlanModal(true);
              }
            }}
          >
            <>Suggest Runbook</>
            {!canUseSuggestions && (
              <CrownIcon
                height={18}
                width={33}
                style={{ position: 'absolute', top: -9, right: -16 }}
              />
            )}
          </RunbookActionsMenuItem>
        </MenuList>
      </Menu>
    );
  };

  return (
    <Grid flexWidth={12} justifyContent="space-between" className="mb-20 ml-20 mt-20 mr-25">
      <Label fontWeight={500}>Runbooks </Label>
      <div className="incident_details__runbook_icons">
        {!isSuppressedIncident && (
          <NoPermissionTooltip isDisabled={hasUpdatePermission}>
            <div className="mr-20">
              <RunbookActionsMenuButton />
            </div>
          </NoPermissionTooltip>
        )}
      </div>
      <UpgradePlanModal
        hasBillingPermission={BillingService.hasManageBillingPermission(state)}
        showModal={showUpgradePlanModal}
        onCancel={() => setShowUpgradePlanModal(false)}
        message={BillingService.getMessage(0, 'suggest-runbooks', state)}
        header={BillingService.getHeader(0, 'suggest-runbooks', state)}
        onUpgrade={() => setShowUpgradePlanModal(true)}
      />
    </Grid>
  );
};

const TaskButton = ({
  setSelectedTask,
  setEnableTaskModal,
  isSuppressedIncident,
}: ITextButtonProps) => {
  const hasUpdatePermission = useUserAccess().hasUpdateAccess('incidents');
  return (
    <Grid flexWidth={12} justifyContent="space-between" className="mb-20 ml-20 mt-20 mr-25">
      <Label fontWeight={500}>Task </Label>
      <div className="incident_details__runbook_icons">
        {!isSuppressedIncident && (
          <NoPermissionTooltip isDisabled={hasUpdatePermission}>
            <div
              className="incident_details__runbook_icons mr-20"
              onClick={() => {
                if (!hasUpdatePermission) return;
                setSelectedTask(null);
                setEnableTaskModal(true);
              }}
              style={
                !hasUpdatePermission
                  ? {
                      opacity: 0.6,
                      cursor: 'not-allowed',
                    }
                  : {}
              }
            >
              <AddTaskIcon className="mr-5" width={22} height={24} />
              <Label fontWeight={400} fontSize={15}>
                Add Task
              </Label>
            </div>
          </NoPermissionTooltip>
        )}
      </div>
    </Grid>
  );
};

export { TaskButton, RunbookButtons };
