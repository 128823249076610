import { IAppState } from 'core/interfaces/IAppState';
import { IRunbook, ISuggestedRunbook } from 'core/interfaces/IRunBook';
import { IRunbookListItem } from 'core/interfaces/ITask';
import { useSelector } from 'react-redux';

import IncidentRunbook from './Runbook';

interface IProps {
  incidentId: string;
  suggestedRunbookList: ISuggestedRunbook[];
  selectedRunbooksList: IRunbook[];
  setSelectedRunbooksList: (runbook: IRunbook[]) => void;
}

const SuggestedRunbookTable = (props: IProps) => {
  const appState = useSelector((state: IAppState) => state);

  return (
    <>
      <div className="mt-10 mb-10 ml-10" style={{ width: '100%' }}>
        {props.suggestedRunbookList.map((runbook, idx) => {
          const onChecked = (checked: boolean) => {
            if (checked) {
              props.setSelectedRunbooksList([...props.selectedRunbooksList, runbook]);
            } else {
              props.setSelectedRunbooksList(
                props.selectedRunbooksList.filter(
                  selectedRunbook => selectedRunbook.id !== runbook.id,
                ),
              );
            }
          };

          const runbookListItem: IRunbookListItem = {
            incident_id: props.incidentId,
            steps: runbook.steps.map(step => {
              return {
                id: '',
                content: step.content,
                completed: false,
              };
            }),
            id: runbook.id || '',
            runbook_id: runbook.id,
            name: runbook.name || 'Deleted Runbook',
          };

          return (
            <IncidentRunbook
              key={`runbook-${runbook.id}`}
              title={runbook.name || 'Deleted Runbook'}
              index={idx}
              runbook={runbookListItem}
              steps={runbook.steps}
              uncontrolledCollapse
              hideDelete
              sidebarState={appState.APP_CONFIG.sidebarState}
              mode="suggest"
              withCheckbox
              checked={props.selectedRunbooksList.some(
                selectedRunbook => selectedRunbook.id === runbook.id,
              )}
              onChecked={onChecked}
              withoutRunbookIcon
              description={`This runbook was attached to ${
                runbook.similar_incident_count
              } similar incident${runbook.similar_incident_count > 1 ? 's' : ''}.`}
              withBottomDivider
            />
          );
        })}
      </div>
    </>
  );
};

export default SuggestedRunbookTable;
