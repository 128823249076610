import { PostmortemModal } from './postmortem.modal';
import moment from 'moment';
import { getProperty } from 'core/helpers';
import { AppTracker } from '../../../../../../shared/analytics/tracker';
import {
  T_WA_GS_POSTMORTEM_DELETED_WEB_V1,
  T_WA_GS_POSTMORTEM_UPDATED_WEB_V1,
} from '../../../../../../core/const/tracker';
import { formatLog } from './timelineHelper';
import { EntityName, EntityType } from 'views/main/organization/navigation-flows/helpers';
import { IUsersOfOrganization } from 'core/interfaces/IUserData';
import { ISquad } from 'core/interfaces/ISquad';
import { IEscalationPolicy } from 'core/interfaces/IEscalationPolicy';
import { ITeam } from 'core/interfaces/ITeams';
import { IService } from 'core/interfaces/IService';
import { DateTime } from 'luxon';
import { formatDuration } from 'core/helpers/dateUtils';

export async function updatePostmortem(this: PostmortemModal) {
  if (!this.validateFollowUpTasks()) return;

  try {
    const updatedPostmortem = this.state.postmortem;
    this.setState({ loading: 'updating' });
    updatedPostmortem.attachments = this.fileUploadService.accessAttachmentArrayForPostmortem();
    await this._PTMService.update(updatedPostmortem);
    this.fileUploadService.emptyAttachmentArrayForPostmortem();
    this.props.onSaveUpdate();
    this.props.onRefreshTable?.();
    this.props.getPostmortem();
  } catch ({ response }: any) {
    this.setState({
      loading: '',
      errors: { message: getProperty(response, 'data.meta.error_message', 'Network Error') },
    });
  }
  AppTracker.track(T_WA_GS_POSTMORTEM_UPDATED_WEB_V1, {
    'Follow Ups': this.state.postmortem.follow_ups
      ? this.state.postmortem.follow_ups.length > 0
      : false,
  });
}

export async function deletePostmortem(this: PostmortemModal) {
  try {
    this.setState({ loading: 'deleting' });
    this.props.setModalIsDirty(false);
    await this._PTMService.delete();
    this.props.hideModal();
    this.props.onRefreshTable?.();
    this.props.getPostmortem();
  } catch ({ response }: any) {
    this.setState({
      loading: '',
      errors: { message: getProperty(response, 'data.meta.error_message', 'Network Error') },
    });
  }
  AppTracker.track(T_WA_GS_POSTMORTEM_DELETED_WEB_V1);
}

export function defineVariables(this: PostmortemModal) {
  const { incident } = this.props;
  const userTimezone =
    this.props.organization.currentUser.u?.time_zone || DateTime.local().zoneName;

  const tags = [];
  for (const key in incident.tags) {
    if (incident.tags.hasOwnProperty(key)) {
      tags.push({ key, value: incident.tags[key].value });
    }
  }

  const getName = (type: EntityType, entityId?: string) => {
    const entityObjectMap = {
      user: this.props.organization.users.u.find(user => user.id === entityId),
      squad: this.props.organization.squads.s.find(squad => squad.id === entityId),
      escalationpolicy: this.props.organization.escalations.e.find(ep => ep.id === entityId),
      team: this.props.organization.teams.t.find(team => team.id === entityId),
      service: this.props.organization.services.s.find(s => s.id === entityId),
    };

    const entityObject = entityObjectMap[type as EntityName];
    if (!entityObject) {
      return `Deleted ${
        (type as EntityName).charAt(0).toUpperCase() + (type as EntityName).slice(1)
      }`;
    }
    const entityNameMap = {
      user: `${(entityObject as IUsersOfOrganization).first_name} ${
        (entityObject as IUsersOfOrganization).last_name
      }`,
      squad: `${(entityObject as ISquad).name}`,
      escalationpolicy: `${(entityObject as IEscalationPolicy).name}`,
      team: `${(entityObject as ITeam).name}`,
      service: `${(entityObject as IService).name}`,
    };

    return entityNameMap[type as EntityName] || 'Deleted Entity';
  };

  const formatTimestamp = (dateInput: string | Date) => {
    const date =
      typeof dateInput === 'string' ? DateTime.fromISO(dateInput) : DateTime.fromJSDate(dateInput);

    if (!date.isValid) {
      return dateInput instanceof Date ? dateInput.toDateString() : dateInput;
    }

    return date.setZone(userTimezone).toFormat('ccc dd/LL/yyyy, hh:mm a');
  };

  // 1. Filter out the note_created logs
  // 2. Replace note_starred log with note_created log timestamp
  // 3. Format the log and return the template variables associated w/ timeline
  let timelines = incident.logs
    .filter(log => log.action !== 'note_created')
    .map(log => {
      if (log.action === 'note_starred') {
        const noteCreatedLog = incident.logs.find(
          el =>
            el.action === 'note_created' &&
            el.additionalInfo?.note_id === log.additionalInfo?.note_id,
        );
        if (noteCreatedLog) log = noteCreatedLog;
      }
      return {
        ...formatLog(log, getName, formatTimestamp),
        timestamp: formatTimestamp(new Date(log.time)),
      };
    });

  if (incident.pinned_messages) {
    timelines = [
      ...timelines,
      ...incident.pinned_messages.map(pinMessage => {
        return {
          timeline_event_title: `Message Sent By: ${pinMessage.message_sender_name}`,
          timestamp: formatTimestamp(new Date(pinMessage.time)),
          timeline_event_detail: pinMessage.message,
          timeline_event_action_type: 'pinned_messsage_sent',
        };
      }),
    ];
  }

  timelines = timelines.sort(
    (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime(),
  ) as any;

  const triggerTime: Date = incident.logs[0].time;
  const resolveTime: Date = incident.logs[incident.logs.length - 1].time;

  const duration = () => formatDuration(triggerTime, resolveTime);

  const postmortemVariables = {
    tags,
    timelines,
    triggerTime,
    resolveTime,
    duration: duration(),
    id: incident.id,
    message: incident.message,
    description: incident.description,
    priority: incident.priority,
    currentUser: `${this.props.userInfo.d!.first_name} ${this.props.userInfo.d!.last_name}`,
    serviceName: this._serviceFind(incident.service_id, 'name'),
    serviceType: this.props.integrations.i.find(x => x._id === incident.alert_source_id)!.type,
    now: () => new Date(),
  };

  this.setState({ variables: postmortemVariables });
}
