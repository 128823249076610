import Axios from 'axios';
import { API } from 'core';
import { useQuery } from 'react-query';
import {
  IGetAssignableAndSuggestedRespondersOptions,
  IGetAssignableAndSuggestedRespondersApiResponse,
} from '../types';

const getAssignableAndSuggestedResponders = (
  options: IGetAssignableAndSuggestedRespondersOptions,
) => {
  const url = `${API.config.batman}/organizations/${API.config.organizationId}/incidents/${options.incidentId}/suggest/additional-responders`;
  return Axios.get<IGetAssignableAndSuggestedRespondersApiResponse>(url);
};

export const useGetAssignableAndSuggestedResponders = (
  options: IGetAssignableAndSuggestedRespondersOptions,
) => {
  return useQuery({
    queryKey: ['incidents', options.incidentId, 'assignableAndSuggestedResponders', options],
    queryFn: async () => {
      if (!options.incidentId || options.incidentId === '') {
        console.error('Incident ID is required in useGetAssignableAndSuggestedResponders');
        return {
          data: { users: [], squads: [] },
        } as IGetAssignableAndSuggestedRespondersApiResponse;
      }

      const res = await getAssignableAndSuggestedResponders(options);
      return res.data;
    },
  });
};
