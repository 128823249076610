export const env = {
  VITE_INTERCOM_KEY: 'lqtvxnd7',
  VITE_LOGROCKET_KEY: '9k2x4d/squadcasthq',
  VITE_MS_TEAMS_KEY: '11cdec78-0afe-453e-ad3e-d8dcc2c0ef98',
  VITE_ONESIGNAL_KEY: 'fb5ce362-0cd6-4178-a561-67e42b5ab87f',
  VITE_SENTRY_KEY: 'https://9e94633fb59d41b2892231bd33622887@sentry.io/1730378',
  VITE_USERFLOW_KEY: 'ct_ppy7ypd6vfbxtfto2bauedfihe',
  VITE_PUSHER_KEY: '3f73ce9e66324c6a3a64',

  VITE_MIXPANEL_PROXY_URL: 'https://mixpanel.squadcast.com',

  VITE_INCIDENTS_URL: 'incidents.squadcast.com',
  VITE_GER_URL: 'global-events.squadcast.com',

  VITE_STATUS_PAGE_URL: 'status.squadcast.io',

  VITE_BILLING_URL: 'https://squadcast.chargebeeportal.com/',
  VITE_MS_TEAMS_BOT_REAUTHORIZATION_URL:
    'https://login.microsoftonline.com/common/oauth2/authorize?response_type=code&client_id=7f900f38-8dc4-42c9-a341-7240939bcab7&redirect_uri=https://app.squadcast.com/settings/extension',

  VITE_US_URL: 'https://app.squadcast.com',
  VITE_EU_URL: 'https://app.eu.squadcast.com',

  // overrides
  VITE_MIXPANEL_KEY: 'f425454fe300707002f99764bc4b3291',
  VITE_SLACK_KEY: '195334081766.283027033104',
  VITE_SLACK_KEY_V2: '195334081766.283027033104',
  // VITE_SLACK_KEY_V2: '4364456769781.4799318466064',

  VITE_CUBE_URL: 'https://analytics.squadcast.com',
  VITE_URL: 'https://app.squadcast.com',
  VITE_API_URL: 'https://api.squadcast.com',
  VITE_INTERNAL_API_URL: 'https://internal-api.squadcast.com',
  VITE_PLATFORM_BACKEND_URL: 'https://platform-backend.squadcast.com',
  VITE_SSO_AUTH_URL: 'https://auth.squadcast.com',
  VITE_WEBFORM_URL: 'webforms.squadcast.com',
  VITE_WEBFORM_CNAME: 'webforms.squadcast.io',
  VITE_PUSHER_HOST: 'websocket.squadcast.com',
  VITE_STATUS_PAGE_HOST: 'https://statuspage.squadcast.com',
  VITE_UNLEASH_URL: 'https://unleash.squadcast.com',
  VITE_UNLEASH_TOKEN: '*:production.ad5e3e4c1d61654950f01e55d9020460b9cfd8835358e28d42625f5f',
  VITE_ENV: 'prod',
};
