import { env as stageConfig } from '../deployment/stage-env-config.js';
import { env as devConfig } from '../deployment/dev-env-config.js';
import { env as usProdConfig } from '../deployment/us-prod-env-config.js';
import { env as euProdConfig } from '../deployment/eu-prod-env-config.js';
import { env as internalConfig } from '../deployment/internal-env-config.js';
// import { env as uat1Config } from '../deployment/uat1-env-config.js';
// import { env as uat2Config } from '../deployment/uat2-env-config.js';
// import { env as uat3Config } from '../deployment/uat3-env-config.js';
// import { env as uat4Config } from '../deployment/uat4-env-config.js';
// import { env as uat5Config } from '../deployment/uat5-env-config.js';

declare global {
  interface Window {
    _sq_env?: string;
  }
}

const allConfigs: Record<string, Record<string, any>> = {
  stage: stageConfig,
  dev: devConfig,
  'us-prod': usProdConfig,
  'eu-prod': euProdConfig,
  internal: internalConfig,
};

const env = window._sq_env || 'stage';
const AppConfig = allConfigs[env] ?? allConfigs['stage'];

export default AppConfig;
