import './index.css';
import './wdyr';
import 'moment-timezone';

import React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { API } from './core/api';
import { configureStore } from './core/store';
import * as Sentry from '@sentry/browser';
import { Theme } from 'uie/components';
import './index.css';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { AppTracker } from './shared/analytics/tracker';
import { LocalStore } from './store/local';
import { PersistGate } from 'redux-persist/integration/react';
import { ABTest } from './core/abTests';
import 'moment-timezone';
import { AppPreview } from './core/appPreview';
import { exception } from './core/exception';
import Views from './views';
import Pusher from 'pusher-js';
import reportWebVitals from './reportWebVitals';
import { AppConfig } from 'shared/app.config';
import { initAuthBroadcastListener } from 'core/broadcast/authBroadcastChannel';
import { tzAuthBroadcastListener } from 'core/broadcast/timezoneBroadcastChannel';
import { FlagProvider, IConfig } from '@unleash/proxy-client-react';
import { initializedUnleash } from 'core';
import _ from 'lodash';
import envVar from './shared/config';

/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-debugger */
/**
 * @deprecated don't use lodash. Here to support old code
 */
window._ = _;

/**
 * app envs
 */
const [DEV, STAGE, INTERNAL, PROD, EU_PROD] = ['dev', 'stage', 'internal', 'prod', 'eu-prod'];

const appVersion = import.meta.env.VITE_VERSION || '0.0.0';

API.env = envVar.VITE_ENV || STAGE;

LocalStore.updateAPPVersion(appVersion);

exception.verbose();

/**
 * app inits
 */
const lr = LogRocket.reduxMiddleware({
  actionSanitizer: function (action) {
    if (action.type === 'REQUEST_LOGIN') {
      return {
        ...action,
        payload: {
          ...action.payload,
          password: undefined,
        },
      };
    }
    return action;
  },
});
const { store, persistor } = configureStore([lr], appVersion);

// @ts-ignore
window._store = store;
exception.capture = Sentry.captureMessage;
setupLogRocketReact(LogRocket);
if (import.meta.env.PROD) {
  LogRocket.init(AppConfig.log_rocket, {
    network: {
      requestSanitizer: request => {
        if (request.headers['Authorization']) {
          request.headers['Authorization'] = '';
        }
        if (request.url.toLowerCase().indexOf('v2/accounts/login') !== -1) {
          if (request.body) {
            try {
              const body = JSON.parse(request.body);
              body.password = undefined;
              request.body = JSON.stringify(body);
            } catch (e) {
              request.body = undefined;
            }
          }
        }
        return request;
      },
    },
  });
}
API.appVersion = appVersion;
API.initPusher(AppConfig.pusher, Pusher);
Theme.init();
ABTest.init(store.dispatch);
AppPreview.init(store.dispatch);
initAuthBroadcastListener(store.dispatch);
tzAuthBroadcastListener();
Sentry.init({
  enabled: import.meta.env.PROD,
  dsn: AppConfig.sentry.dns.web,
  release: import.meta.env.VITE_GIT_SHA,
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
  attachStacktrace: true,
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications',
    'Network Error',
    'Request aborted',
    'Request failed with status code 401',
    'Request failed with status code 403',
    'Could not load Userflow.js',
  ],
});

try {
  AppTracker.init(AppConfig.mixpanel, AppConfig.mixpanel_proxy_url);
} catch (e: any) {
  exception.handle('E_DISABLED_APP_ANALYTICS', e);
}

initializedUnleash();

const config: IConfig = {
  url: `${envVar.VITE_UNLEASH_URL}/api/frontend`,
  clientKey: `${envVar.VITE_UNLEASH_TOKEN}`,
  appName: 'squadcast-repo',
  disableMetrics: import.meta.env.DEV,
  disableRefresh: import.meta.env.DEV,
};

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <FlagProvider config={config}>
        <Views />
      </FlagProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root') as HTMLElement,
);

if (API.env === DEV) {
  reportWebVitals(console.log);
}
