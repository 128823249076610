export const env = {
  VITE_INTERCOM_KEY: 'lqtvxnd7',
  VITE_LOGROCKET_KEY: '9k2x4d/squadcasthq',
  VITE_MIXPANEL_KEY: 'eb1910ac575afaa4c398911e7f1241b0',
  VITE_MS_TEAMS_KEY: '11cdec78-0afe-453e-ad3e-d8dcc2c0ef98',
  VITE_ONESIGNAL_KEY: 'fb5ce362-0cd6-4178-a561-67e42b5ab87f',
  VITE_SLACK_KEY: '195334081766.687457268359',
  VITE_SLACK_KEY_V2: '4364456769781.4360899606214',
  VITE_USERFLOW_KEY: 'ct_ppy7ypd6vfbxtfto2bauedfihe',
  VITE_PUSHER_KEY: '3f73ce9e66324c6a3a64',

  VITE_MIXPANEL_PROXY_URL: '',

  VITE_INCIDENTS_URL: 'incidents.squadcast.com',
  VITE_GER_URL: 'global-events.squadcast.tech',
  VITE_STATUS_PAGE_URL: 'status.squadcast.io',

  VITE_CUBE_URL: 'https://random-lacygne.gcp-us-central1.cubecloudapp.dev',
  VITE_BILLING_URL: 'https://squadcast.chargebeeportal.com/',
  VITE_MS_TEAMS_BOT_REAUTHORIZATION_URL:
    'https://login.microsoftonline.com/common/oauth2/authorize?response_type=code&client_id=5982918f-c6ae-479c-86b7-0886d5c71fda&redirect_uri=http://localhost:3000/settings/extension',

  VITE_SSO_AUTH_URL: 'https://auth.squadcast.tech',
  VITE_URL: 'https://app.squadcast.tech',
  VITE_API_URL: 'https://api.squadcast.tech',
  VITE_INTERNAL_API_URL: 'https://internal-api.squadcast.tech',
  VITE_PLATFORM_BACKEND_URL: 'https://platform-backend.squadcast.tech',
  VITE_US_URL: 'https://app.squadcast.com',
  VITE_EU_URL: 'https://app.eu.squadcast.com',
  VITE_WEBFORM_URL: 'webforms.squadcast.tech',
  VITE_WEBFORM_CNAME: 'webforms.squadcast.tech',
  VITE_STATUS_PAGE_HOST: 'https://statuspage.squadcast.tech',

  // overrides
  VITE_SENTRY_KEY: '',
  VITE_ENV: 'dev',
  VITE_PUSHER_HOST: 'websocket.squadcast.tech',
  VITE_UNLEASH_URL: 'https://unleash.squadcast.tech',
  VITE_UNLEASH_TOKEN: '*:development.b2a382e882fcb29d88906b029fbf1dc24cfd86494375093b5e5a7b18',
};
