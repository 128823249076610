export const checkIfStorageHost = (url: string) => {
  try {
    const urlObj = new URL(url);
    const allowedHosts = [
      'storage.squadcast.tech',
      'storage.squadcast.com',
      'storage.eu.squadcast.com',
      'storage.squadcast.xyz',
    ];
    return allowedHosts.includes(urlObj.host);
  } catch (err) {
    console.error(`Error checking storage host: `, { err, url });
    return false;
  }
};
