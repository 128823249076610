export const env = {
  VITE_INTERCOM_KEY: 'lqtvxnd7',
  VITE_LOGROCKET_KEY: '9k2x4d/squadcasthq',
  VITE_MS_TEAMS_KEY: '11cdec78-0afe-453e-ad3e-d8dcc2c0ef98',
  VITE_ONESIGNAL_KEY: 'fb5ce362-0cd6-4178-a561-67e42b5ab87f',
  VITE_SENTRY_KEY: 'https://9e94633fb59d41b2892231bd33622887@sentry.io/1730378',
  VITE_USERFLOW_KEY: 'ct_ppy7ypd6vfbxtfto2bauedfihe',

  VITE_MIXPANEL_PROXY_URL: 'https://mixpanel.squadcast.com',

  VITE_INCIDENTS_URL: 'incidents.squadcast.com',
  VITE_GER_URL: 'global-events.eu.squadcast.com',

  VITE_BILLING_URL: 'https://squadcast.chargebeeportal.com/',
  VITE_MS_TEAMS_BOT_REAUTHORIZATION_URL:
    'https://login.microsoftonline.com/common/oauth2/authorize?response_type=code&client_id=8326bfd3-3fb7-4c3a-8d18-181a46b3a537&redirect_uri=https://app.eu.squadcast.com/settings/extension',

  VITE_US_URL: 'https://app.squadcast.com',
  VITE_EU_URL: 'https://app.eu.squadcast.com',

  // overrides
  VITE_MIXPANEL_KEY: 'f425454fe300707002f99764bc4b3291',
  VITE_SLACK_KEY: '195334081766.3063049676551',
  VITE_SLACK_KEY_V2: '195334081766.3063049676551',

  // VITE_SLACK_KEY_V2: '4364456769781.4769043616486',

  VITE_STATUS_PAGE_URL: 'status.eu.squadcast.io',
  VITE_SSO_AUTH_URL: 'https://auth.eu.squadcast.com',

  VITE_CUBE_URL: 'https://analytics.eu.squadcast.com',
  VITE_URL: 'https://app.eu.squadcast.com',
  VITE_API_URL: 'https://api.eu.squadcast.com',
  VITE_INTERNAL_API_URL: 'https://internal-api.eu.squadcast.com',
  VITE_PLATFORM_BACKEND_URL: 'https://platform-backend.eu.squadcast.com',
  VITE_WEBFORM_URL: 'webforms.eu.squadcast.com',
  VITE_WEBFORM_CNAME: 'webforms.eu.squadcast.io',
  VITE_PUSHER_KEY: 'fcd66850b51c8fcf0edc',
  VITE_PUSHER_HOST: 'websocket.eu.squadcast.com',
  VITE_STATUS_PAGE_HOST: 'https://statuspage.eu.squadcast.com',
  VITE_UNLEASH_URL: 'https://unleash.eu.squadcast.com',
  VITE_UNLEASH_TOKEN: '*:production.879e17f3fa9d02a5999f51248607f9de5403d1d54f4eb7a407120bd3',

  VITE_ENV: 'eu-prod',
};
